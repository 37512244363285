import { WELD_INTRODUCTORY_CALL_URL } from "constants/external-urls/hubspot";
import { atom, useAtom, useAtomValue } from "jotai";
import { useCallback } from "react";

import { PlanName, RecurrenceInterval, WeldPlan } from "@/apollo/types";
import { ReactComponent as PlugIcon } from "@/assets/icons/plug.svg";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { ActiveRowTooltipTrigger } from "@/components/modules/ActiveRow";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { useSubscriptionStatus, useWeldPlan } from "@/features/subscription";
import cn from "@/helpers/classNames";
import { InformationCircleIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

import { usePriceByLookupKey } from "../../pricing";
import { useLocaleContext } from "../../shared/providers";
import { Badge } from "../../shared/ui";
import { FormattedPrice } from "./FormattedPrice";
import { PriceTiersPopover } from "./PriceTiersPopover";

const formatLimit = (limit: number) => (limit === -1 ? "Unlimited" : limit);

const formatInterval = (minutes: number) => {
  if (minutes < 60) {
    return `${minutes}-minute`;
  }
  return `${minutes / 60}-hour`;
};

const billingPeriodAtom = atom<
  RecurrenceInterval.Month | RecurrenceInterval.Year
>(RecurrenceInterval.Year);

export function useBillingPeriodToggle() {
  const [billingPeriod, setBillingPeriod] = useAtom(billingPeriodAtom);

  const toggleBillingPeriod = () => {
    setBillingPeriod((prev) =>
      prev === RecurrenceInterval.Year
        ? RecurrenceInterval.Month
        : RecurrenceInterval.Year,
    );
  };
  return { billingPeriod, setBillingPeriod, toggleBillingPeriod };
}

export function PlanUpgradePillars(props: {
  onSelectPlan: (plan: WeldPlan) => void;
}) {
  const { name: currentPlanName, allWeldPlans } = useWeldPlan();
  const { subscriptionId } = useSubscriptionStatus();
  const currentPlan =
    subscriptionId == null
      ? null
      : allWeldPlans.find((plan) => plan.name === currentPlanName);

  const upgradePlans = allWeldPlans
    .filter((plan) => {
      if (plan.name === currentPlan?.name && plan.name !== PlanName.Essential) {
        return true;
      }
      if (plan.isLegacyPlan) {
        return false;
      }
      if (currentPlan && currentPlan.name !== PlanName.Essential) {
        return (
          plan.features.minSyncIntervalInMinutes <=
          currentPlan.features.minSyncIntervalInMinutes
        );
      }
      return true;
    })
    .sort((a, b) => {
      if (a.name === currentPlan?.name) {
        return -1;
      }
      if (b.name === currentPlan?.name) {
        return 1;
      }
      return (
        b.features.minSyncIntervalInMinutes -
        a.features.minSyncIntervalInMinutes
      );
    });

  const onRefChange = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setTimeout(() => {
        node.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      });
    }
  }, []);

  return (
    <div className="flex gap-5 overflow-x-auto py-4">
      {upgradePlans.map((plan, idx) => {
        if (plan.name === PlanName.Business) {
          return (
            <div
              key={plan.name}
              className="flex w-fit shrink-0 first:ml-auto last:mr-auto"
              ref={idx === 1 ? onRefChange : undefined}
            >
              <BusinessPlanPillar
                plan={plan}
                isCurrentPlan={plan.name === currentPlan?.name}
              />
            </div>
          );
        }
        return (
          <div
            key={plan.name}
            className="flex w-fit shrink-0 first:ml-auto last:mr-auto"
            ref={idx === 1 ? onRefChange : undefined}
          >
            <PlanPillar
              plan={plan}
              isCurrentPlan={plan.name === currentPlan?.name}
              onSelectPlan={() => props.onSelectPlan(plan)}
            />
          </div>
        );
      })}
    </div>
  );
}

function PlanPillar(props: {
  plan: WeldPlan;
  isCurrentPlan?: boolean;
  onSelectPlan: () => void;
}) {
  return (
    <div
      className={cn("flex w-64 flex-col gap-6 rounded border p-5 shadow-lg")}
    >
      <Heading className="text-xl font-medium">{props.plan.name}</Heading>
      <div className="grow">
        {props.plan.name === PlanName.Business ? (
          <div className="text-center">
            Get a custom plan tailored
            <br />
            to your business
          </div>
        ) : (
          <PlanPriceInfo plan={props.plan} />
        )}
      </div>
      <div className="text-sm">
        <ul className="flex w-fit list-inside flex-col gap-1">
          <li className="flex items-center gap-4">
            <PlugIcon className="h-4 w-4" />
            {formatLimit(props.plan.features.limitConnectors)} connectors
          </li>
          <li className="flex items-center gap-4">
            <UsersIcon className="h-4 w-4" />
            {formatLimit(props.plan.features.limitUsers)}{" "}
            {props.plan.features.limitUsers === 1 ? "user" : "users"}
          </li>
          <li className="flex items-center gap-4">
            <ArrowPathIcon className="h-4 w-4" />
            {formatInterval(props.plan.features.minSyncIntervalInMinutes)} sync
            frequency
          </li>
        </ul>
      </div>
      <div className="flex justify-center">
        {props.isCurrentPlan ? (
          <Button isFullWidth isDisabled className="opacity-50">
            Your current plan
          </Button>
        ) : props.plan.name === PlanName.Business ? (
          <Button
            variant="solid"
            colorScheme="primary"
            isFullWidth
            as="a"
            href={WELD_INTRODUCTORY_CALL_URL}
            target="_blank"
            rel="noreferrer"
          >
            Talk to us
          </Button>
        ) : (
          <UpgradeNowButton
            planName={props.plan.name}
            isFullWidth
            source="plans overview"
            onClick={(e) => {
              e.preventDefault();
              props.onSelectPlan();
            }}
            size="md"
          >
            Upgrade to {props.plan.name}
          </UpgradeNowButton>
        )}
      </div>
    </div>
  );
}

function BusinessPlanPillar(props: {
  plan: WeldPlan;
  isCurrentPlan?: boolean;
}) {
  return (
    <div
      className={cn("flex w-64 flex-col gap-6 rounded border p-5 shadow-lg")}
    >
      <Heading className="text-xl font-medium">{props.plan.name}</Heading>
      <div className="flex grow items-center justify-center text-center">
        Get a custom plan tailored
        <br />
        to your business
      </div>
      <div className="text-sm">
        <ul className="flex w-fit list-inside flex-col gap-1">
          <li className="flex items-center gap-4">
            <PlugIcon className="h-4 w-4" />
            {formatLimit(props.plan.features.limitConnectors)} connectors
          </li>
          <li className="flex items-center gap-4">
            <UsersIcon className="h-4 w-4" />
            {formatLimit(props.plan.features.limitUsers)}{" "}
            {props.plan.features.limitUsers === 1 ? "user" : "users"}
          </li>
          <li className="flex items-center gap-4">
            <ArrowPathIcon className="h-4 w-4" />
            {formatInterval(props.plan.features.minSyncIntervalInMinutes)} sync
            frequency
          </li>
        </ul>
      </div>
      <div className="flex justify-center">
        <Button
          variant="outline"
          colorScheme="primary"
          isFullWidth
          as="a"
          href={WELD_INTRODUCTORY_CALL_URL}
          target="_blank"
          rel="noreferrer"
          size="md"
        >
          Talk to us
        </Button>
      </div>
    </div>
  );
}

function PlanPriceInfo(props: { plan: WeldPlan }) {
  const billingPeriod = useAtomValue(billingPeriodAtom);

  const { currency } = useLocaleContext();

  const priceLookupKey =
    (billingPeriod === RecurrenceInterval.Year
      ? props.plan.priceLookupKeys?.yearly
      : props.plan.priceLookupKeys?.monthly) ?? "";

  const { price, loading } = usePriceByLookupKey({
    lookupKey: priceLookupKey,
    currency,
  });

  if (loading || !price) {
    return (
      <div className="flex h-20 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const baseTier = price.tiers?.[0];
  return (
    <div className="flex flex-col text-sm">
      <div>
        <FormattedPrice price={price} convertBillingInterval="Month">
          <div className="">
            <span className="relative text-3xl font-semibold">
              <FormattedPrice.Price options={{ maximumFractionDigits: 0 }} />
              {billingPeriod === RecurrenceInterval.Year &&
                !props.plan.isLegacyPlan && (
                  <Badge
                    variant="success"
                    className="absolute left-full top-0 ml-2 whitespace-nowrap px-1.5 text-xs"
                  >
                    -20% off
                  </Badge>
                )}
            </span>
          </div>
          <TextMuted as="div" className="text-xs font-normal">
            <FormattedPrice.Period includeBillingInterval />
          </TextMuted>
        </FormattedPrice>
      </div>
      {baseTier && baseTier.upTo && (
        <div className="mt-2">
          <TextMuted className="text-xs">with</TextMuted>{" "}
          <span className="inline-flex items-center font-semibold">
            <PriceTiersPopover
              planName={props.plan.name}
              price={price}
              currency={currency}
            >
              <span className="rounded underline decoration-dashed decoration-1 underline-offset-2 outline-gray-100 hover:bg-gray-100 hover:outline hover:outline-2 dark:outline-gray-700 dark:hover:bg-gray-700">
                {baseTier.upTo / 1e6} million active rows
              </span>
            </PriceTiersPopover>
            <ActiveRowTooltipTrigger>
              <InformationCircleIcon className="ml-1 h-4" />
            </ActiveRowTooltipTrigger>
          </span>
        </div>
      )}
    </div>
  );
}
