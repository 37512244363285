import dayjs from "dayjs";
import { snakeCase } from "lodash";
import { useState } from "react";

import { UtilityButton } from "@/components/elements/Button";
import classNames from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import useWeldAPI from "@/hooks/useWeldAPI";
import { IntegrationLogo } from "@/integrations";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";

import { BACKEND_URL } from "../../../constants";
import { useCurrentPreview, usePreviewCompiledSqlAtom } from "./Preview";
import PreviewHistorySelector from "./PreviewHistorySelector";

export default function PreviewHeader() {
  const preview = useCurrentPreview();
  const account = useCurrentAccount();
  const toast = useToast();

  const fetchAPI = useWeldAPI();

  const openPreview = useCurrentPreview();

  const rowLengths = openPreview?.rows?.length;
  const dwh = useDataWarehouseContext();

  const [, setPreviewCompiled] = usePreviewCompiledSqlAtom();

  const [isLoadingFile, setIsLoadingFile] = useState(false);

  async function downloadCsvFile() {
    if (isLoadingFile) return;
    if (!account.dataWarehouseConnectionId || !preview?.dwSql) return;
    try {
      setIsLoadingFile(true);

      const response = await fetchAPI(`/download/csv/sql-query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sourceConnectionId: account.dataWarehouseConnectionId,
          sqlQuery: preview.dwSql,
          filename: snakeCase(
            `${preview.displayName} ${dayjs().format("DD-MM-YYYY HH-mm")})`,
          ),
        }),
      });

      if (response.status !== 200) {
        const res = await response.json();
        throw new Error(res.error ?? "Error downloading csv file");
      }

      const { uuid } = await response.json();

      const link = document.createElement("a");
      link.href = `${BACKEND_URL}/download/csv/${uuid}`;
      link.setAttribute("download", "");

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      setIsLoadingFile(false);
    } catch (error: any) {
      toast("Error while downloading CSV", error.message, "error");
      setIsLoadingFile(false);
      return;
    }
  }

  return (
    <>
      <div className="flex h-full w-full items-center justify-between py-1 pl-5 pr-3 dark:border-gray-700">
        {openPreview ? (
          <>
            <div className="flex items-center justify-center space-x-2 text-xs text-gray-600 dark:text-gray-400">
              <span className="font-bold ">
                {openPreview?.displayName ?? "untitled"}
              </span>
              {openPreview.historyDate && (
                <span>
                  ({dayjs(openPreview.historyDate).format(dateTimeFormat)})
                </span>
              )}
              {rowLengths !== undefined && (
                <span className="space-x-1">
                  <span>
                    {rowLengths} row{rowLengths > 1 && "s"}
                  </span>
                  {typeof openPreview?.totalRows === "number" &&
                    openPreview.totalRows !== rowLengths && (
                      <span className="">({openPreview.totalRows} total)</span>
                    )}
                  {openPreview?.endTimestamp != null && (
                    <span>
                      returned in{" "}
                      {getDifference(
                        openPreview.startTimestamp,
                        openPreview.endTimestamp,
                      )}
                      s
                    </span>
                  )}
                </span>
              )}
              <button
                disabled={!openPreview.dwSql}
                onClick={() => {
                  if (openPreview.dwSql) setPreviewCompiled(true);
                }}
                className={classNames(
                  openPreview.dwSql ? "underline" : "cursor-default",
                )}
              >
                <IntegrationLogo className="h-4 w-4" id={dwh.integration.id} />
              </button>
            </div>
          </>
        ) : (
          <div className="text-center text-xs">Preview</div>
        )}
        <div className="flex space-x-2">
          {preview?.dwSql && (
            <UtilityButton
              onClick={downloadCsvFile}
              isLoading={isLoadingFile}
              loadingText={"export table (.csv)"}
            >
              export table (.csv)
            </UtilityButton>
          )}
          {openPreview && <PreviewHistorySelector />}
        </div>
      </div>
    </>
  );
}

const getDifference = (startTimestamp: number, endTimestamp: number) => {
  return parseFloat(
    dayjs(endTimestamp).diff(startTimestamp, "seconds", true).toFixed(1),
  );
};
