import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import cn from "@/helpers/classNames";
import { Ranger, RangerInterpolator, useRanger } from "@/hooks/useRanger";
import { keyBy } from "lodash";
import { ComponentProps, forwardRef, useMemo, useRef } from "react";
import { formatRowCountCompact } from "@/shared/formatters";

function clampValue(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

function useInterpolator(steps: number[]): RangerInterpolator {
  return useMemo(() => {
    const scaledSteps = steps.reduce<number[]>((acc, step, i, arr) => {
      if (i === 0) {
        return [step];
      }
      const gap = step - arr[i - 1];
      const scaledDistance = Math.sqrt(gap);
      const prevStep = acc[i - 1];
      acc.push(prevStep + scaledDistance);
      return acc;
    }, []);

    const min = scaledSteps[0];
    const max = scaledSteps[scaledSteps.length - 1];

    const _ranger = new Ranger<HTMLDivElement>({
      values: [],
      min: min,
      max: scaledSteps[scaledSteps.length - 1],
      steps: scaledSteps,
      getRangerElement: () => null,
      rerender: () => {},
    });

    return {
      getPercentageForValue: (_val) => {
        const index = steps.indexOf(_val);
        const val = scaledSteps[index] ?? 0;
        if (val <= 0) return 0;
        if (val >= max) return 100;
        const scaledValue = (val - min) / (max - min);
        return scaledValue * 100;
      },

      getValueForClientX: (clientX, trackDims) => {
        const { width, left } = trackDims;
        const percentage = (clientX - left) / width;
        const clampedPercentage = clampValue(percentage, 0, 1);
        const value = min + clampedPercentage * (max - min);
        const stepValue = _ranger.roundToStep(value);
        const index = scaledSteps.findIndex((x) => stepValue <= x);
        return steps[index];
      },
    };
  }, [steps]);
}

function getOrderOfMagnitude(value: number) {
  if (value <= 0) {
    return 0;
  }
  return Math.pow(10, Math.floor(Math.log10(value)));
}

export function RowVolumeSlider(props: {
  steps: number[];
  value: number | undefined;
  onChange: (value: number) => void;
  labelAllTicks?: boolean;
}) {
  const rangerRef = useRef<HTMLDivElement>(null);

  const steps = useMemo(() => {
    if (props.value === undefined || props.steps.includes(props.value)) {
      return props.steps;
    }
    return [...props.steps, props.value].sort((a, b) => a - b);
  }, [props.steps, props.value]);

  const minValue = steps[0];
  const maxValue = steps[steps.length - 1];

  const interpolator = useInterpolator(steps);

  const rangerInstance = useRanger<HTMLDivElement>({
    getRangerElement: () => rangerRef.current,
    values: [props.value ?? minValue],
    min: minValue,
    max: maxValue,
    steps: steps,
    ticks: steps,
    onDrag: (instance: Ranger<HTMLDivElement>) => {
      const value = instance.sortedValues[0];
      if (value !== props.value) {
        props.onChange(instance.sortedValues[0]);
      }
    },
    interpolator,
  });

  const orderOfMagnitude = getOrderOfMagnitude(maxValue - 1);

  const ticksWithLabels = useMemo(() => {
    if (props.labelAllTicks) {
      return {};
    }
    return keyBy(
      steps.reduce<{ i: number; value: number }[]>((acc, step, i) => {
        if (i === 0) {
          acc.push({ i, value: step });
          return acc;
        }
        const lastTick = acc[acc.length - 1];
        if (
          (i - lastTick.i) % 4 === 0 ||
          step - lastTick.value >= orderOfMagnitude
        ) {
          acc.push({ i, value: step });
        }
        return acc;
      }, []),
      "value",
    );
  }, [steps, orderOfMagnitude, props.labelAllTicks]);

  return (
    <div className="py-8">
      <div className="flex flex-row-reverse">
        <ArrowSegment
          className="h-2 w-8"
          onClick={() => props.onChange(steps[steps.length - 1])}
        />
        <Track
          ref={rangerRef}
          className="h-2 grow"
          onClick={(e) => {
            const value = rangerInstance.getValueForClientX(e.clientX);
            const roundedValue = rangerInstance.roundToStep(value);
            props.onChange(clampValue(roundedValue, minValue, maxValue));
          }}
        >
          {rangerInstance
            .getTicks()
            .map(({ value: tickValue, key, percentage }, i) => (
              <Tick
                value={tickValue}
                key={key}
                percentage={percentage}
                isHighlighted={
                  props.value !== undefined && tickValue <= props.value
                }
              >
                {(props.labelAllTicks || ticksWithLabels[tickValue]) && (
                  <TickLabel>{formatRowCountCompact(tickValue)}</TickLabel>
                )}
              </Tick>
            ))}
          {rangerInstance.getSteps().map(({ left, width }, i) => (
            <Segment key={i} x={left} width={width} index={i} />
          ))}
          {props.value !== undefined &&
            rangerInstance
              .handles()
              .map(
                (
                  {
                    value,
                    onKeyDownHandler,
                    onMouseDownHandler,
                    onTouchStart,
                    isActive,
                  },
                  i,
                ) => (
                  <Handle
                    key={i}
                    onKeyDown={onKeyDownHandler}
                    onMouseDown={onMouseDownHandler}
                    onTouchStart={onTouchStart}
                    role="slider"
                    aria-valuemin={rangerInstance.options.min}
                    aria-valuemax={rangerInstance.options.max}
                    aria-valuenow={value}
                    isActive={isActive}
                    x={rangerInstance.getPercentageForValue(value)}
                  >
                    {formatRowCountCompact(value)}
                  </Handle>
                ),
              )}
        </Track>
      </div>
    </div>
  );
}

function Handle({
  x,
  isActive,
  ...props
}: ComponentProps<"button"> & {
  isActive: boolean;
  x: number;
}) {
  return (
    <button
      style={{
        left: `${x}%`,
      }}
      className={cn(
        "absolute top-1/2 box-content h-6 w-6 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-blue-600 bg-white text-blue-600 shadow outline-none",
        {
          "z-0": !isActive,
          "z-10": isActive,
        },
      )}
      {...props}
    >
      <div className="relative flex h-full ">
        <ChevronLeftIcon className="absolute right-1/3 top-1/2 h-4 -translate-y-1/2" />
        <ChevronRightIcon className="absolute left-1/3 top-1/2 h-4 -translate-y-1/2" />
      </div>
      <span className="absolute -top-full left-1/2 -translate-x-1/2">
        {props.children}
      </span>
    </button>
  );
}

const Track = forwardRef<HTMLDivElement, ComponentProps<"div">>(
  (props, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn(
        "user-select-none relative h-2 bg-gray-600",
        props.className,
      )}
    />
  ),
);

const Tick = ({
  percentage,
  value,
  isHighlighted,
  ...props
}: ComponentProps<"div"> & {
  value: number;
  percentage: number;
  isHighlighted: boolean;
}) => (
  <div
    style={{
      left: percentage === 100 ? undefined : `${percentage}%`,
      right: percentage === 100 ? 0 : undefined,
    }}
    className={cn("absolute top-2 h-2 w-[2px]", {
      "bg-gray-600": !isHighlighted,
      "bg-blue-500": isHighlighted,
    })}
  >
    {props.children}
  </div>
);

const TickLabel = (props: ComponentProps<"div">) => (
  <div
    {...props}
    className={cn(
      "absolute top-full -translate-x-1/2 translate-y-1 text-sm font-light",
      props.className,
    )}
  />
);

const Segment = (
  props: ComponentProps<"div"> & {
    x: number;
    width: number;
    index: number;
  },
) => (
  <div
    style={{
      position: "absolute",
      height: "100%",
      left: `${props.x}%`,
      width: `${props.width}%`,
    }}
    className={cn({
      "bg-blue-500": props.index === 0,
    })}
  />
);

function ArrowSegment(props: ComponentProps<"div">) {
  return (
    <div
      {...props}
      className={cn("relative flex h-2 w-8 flex-none", props.className)}
    >
      <div className="absolute left-0 right-1 top-0 h-full bg-gray-600" />
      <Arrow />
    </div>
  );
}

const Arrow = (props: ComponentProps<"div">) => (
  <div
    {...props}
    className="absolute right-1 top-1/2 h-4 w-4 -translate-y-1/2 -rotate-45 border-b-4 border-r-4 border-gray-600"
  />
);
