import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Button } from "@/components/elements/Button";
import classNames from "@/helpers/classNames";
import { Fragment } from "react";

export default function OpenButton(props: {
  onClickMain: () => void;
  text: string;
  options: { text: string; onClick: () => void }[];
}) {
  return (
    <span className="relative z-0 inline-flex">
      <Button
        onClick={props.onClickMain}
        variant="outline"
        colorScheme="primary"
        size="sm"
        type="button"
        className={classNames(
          "relative inline-flex items-center focus:z-10",
          props.options.length > 0 ? "rounded-l-sm" : "rounded-sm",
        )}
      >
        {props.text}
      </Button>
      {props.options.length > 0 && (
        <Menu as="span" className="relative -ml-px block">
          <Menu.Button className="relative inline-flex h-8 items-center rounded-r-sm border border-primary px-2 text-sm font-medium text-primary focus:z-10 focus:outline-none focus:ring-1">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-4 w-4 " aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 -mr-1 mt-2 w-40 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:text-white">
              <div className="">
                {props.options.map((option) => {
                  return (
                    <Menu.Item key={option.text}>
                      {({ active }) => (
                        <button
                          onClick={option.onClick}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-xs dark:text-white",
                          )}
                        >
                          {option.text}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </span>
  );
}
