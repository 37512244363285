import { AnimatePresence, motion } from "framer-motion";
import { groupBy } from "lodash";
import { useState } from "react";

import { NotificationType } from "@/apollo/types";
import {
  Banner,
  BannerCloseButton,
  BannerDescription,
  BannerIcon,
  BannerTitle,
} from "@/components/elements/Banner";

import { useAccountLevelNotificationsResolver } from "./resolvers/useAccountLevelNotificationsResolver";
import { useFreemiumResolver } from "./resolvers/useFreemiumResolver";
import { useSyncsBlockedResolver } from "./resolvers/useSyncsBlockedResolver";
import { WorkspaceAlert, useWorkspaceAlerts } from "./useWorkspaceAlerts";

const orderedStatuses: Record<WorkspaceAlert["status"], number> = {
  info: 0,
  warning: 1,
  error: 2,
};

const dedupeTypeGroups = [
  [
    NotificationType.OverdueInvoiceReminder,
    NotificationType.OverdueInvoiceServiceSuspension,
  ],
];

const generateDedupeKey = (alert: WorkspaceAlert) => {
  if (alert.type) {
    const typeGroup = dedupeTypeGroups.find((group) => {
      if (!alert.type) return false;
      return group.includes(alert.type);
    });
    if (typeGroup) {
      return typeGroup.join(",");
    }
    return alert.type;
  }
  return alert.id;
};

function useDedupeAlerts(alerts: WorkspaceAlert[]) {
  const groups = groupBy(alerts, generateDedupeKey);
  return Object.values(groups).flatMap((group) => {
    if (group.length === 1) {
      return group;
    }
    // select the most recent alert
    const mostRecent = group.reduce((a, b) => {
      if (!a.updatedAt && !b.updatedAt) {
        // select based on status
        return orderedStatuses[a.status] >= orderedStatuses[b.status] ? a : b;
      }
      if (!a.updatedAt) {
        return b;
      }
      if (!b.updatedAt) {
        return a;
      }
      return a.updatedAt > b.updatedAt ? a : b;
    }, group[0]);
    return mostRecent;
  });
}

function useAlerts() {
  const alerts = useWorkspaceAlerts(
    useAccountLevelNotificationsResolver,
    useSyncsBlockedResolver,
    useFreemiumResolver,
  );

  const dedupedAlerts = useDedupeAlerts(alerts);
  const sortedAlerts = dedupedAlerts
    .slice()
    .sort((a, b) => orderedStatuses[b.status] - orderedStatuses[a.status]);

  return sortedAlerts;
}

export function WorkspaceBanners() {
  const alerts = useAlerts();

  const [dismissedIds, setDismissedIds] = useState<string[]>([]);

  // Only show one banner at a time, unless there are multiple error alerts
  const alertsToShow =
    alerts.length === 0
      ? []
      : alerts[0].status === "error"
        ? alerts.filter((x) => x.status === "error")
        : [alerts[0]];

  if (alertsToShow.length === 0) {
    return null;
  }
  return (
    <div className="shrink-0">
      <AnimatePresence initial={false}>
        {alertsToShow
          .filter((alert) => !dismissedIds.includes(alert.id))
          .map((alert) => (
            <motion.div
              key={alert.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.9,
                transition: { duration: 0.15 },
              }}
            >
              <Banner status={alert.status} size="sm" action={alert.action}>
                <BannerIcon />
                <BannerTitle>{alert.title}</BannerTitle>
                <BannerDescription>{alert.message}</BannerDescription>
                {alert.isDismissable && (
                  <BannerCloseButton
                    onClick={() => {
                      setDismissedIds((ids) => ids.concat([alert.id]));
                      alert.onDismiss?.();
                    }}
                  />
                )}
              </Banner>
            </motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
}
