import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "@tanstack/react-location";
import {
  OrchestrationSchedulerType,
  OrchestrationWorkflowQuery,
} from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import { OrchestrationTypeIcon } from "@/components/icons/OrchestrateIcon";
import classNames from "@/helpers/classNames";

export default function OrchestrationSchedulerTypeInfo(props: {
  orchestrationScheduler?: OrchestrationSchedulerType | null;
  workflow?: OrchestrationWorkflowQuery["orchestrationWorkflow"];
}) {
  if (props.orchestrationScheduler) {
    return (
      <div className={classNames("flex items-center space-x-2")}>
        <OrchestrationTypeIcon
          schedulerType={props.orchestrationScheduler}
          className="h-4 w-4"
        />
        <span className="">
          {props.orchestrationScheduler === OrchestrationSchedulerType.Local
            ? "Independent"
            : props.workflow?.name ?? "Missing orchestration"}
        </span>
        {props.orchestrationScheduler === OrchestrationSchedulerType.Global &&
          props.workflow && (
            <>
              <Link
                className="underline"
                to={`../../orchestration/${props.workflow.id}`}
              >
                view orchestration
              </Link>
            </>
          )}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-1">
      <span className="italic">No scheduled updates.</span>
      <Tooltip
        content={`Views only update when changed, or when configured to run tests.`}
      >
        <div>
          <InformationCircleIcon className="h-4 w-4 dark:text-white" />
        </div>
      </Tooltip>
    </div>
  );
}
