import { useMemo, useState } from "react";

import { ButtonCard } from "@/components/elements/ButtonCard";
import { BetaFeatureText } from "@/components/elements/FeatureStateText";
import { Modal, ModalBody, ModalHeader } from "@/components/elements/Modal";
import FieldLabel from "@/components/primitives/InputLabel";
import { useSetupRevELTSimple } from "@/features/reverse-elt/useSetupRevELTSimple";
import classNames from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { ModelDisplayName } from "@/pages/ModelTool/hooks/useFullPathNames";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useToast } from "@/providers/ToastProvider";

import { useTemplateConfig } from "./useTemplateConfig";

export const SetupHandoff = () => {
  return (
    <div className="pt-8">
      <FieldLabel>Export data</FieldLabel>
      <div className="flex items-center gap-4">
        <GoogleSheetsHandoff />
        <ButtonCard
          disabled
          className={classNames(
            "group w-64 items-center justify-start gap-3 rounded-sm p-5 text-left",
          )}
        >
          <IntegrationLogoBox id={"email"} />
          <div className="flex min-w-0 flex-1 flex-col gap-1">
            <h3 className="w-full truncate font-medium leading-5 dark:text-white">
              Email report
            </h3>
            <div className="text-xs">Click to set up</div>
            <BetaFeatureText className="absolute right-0 top-0 mr-4 mt-4" />
          </div>
        </ButtonCard>
        <ButtonCard
          disabled
          className={classNames(
            "group w-64 items-center justify-start gap-3 rounded-sm p-5 text-left",
          )}
        >
          <IntegrationLogoBox id={"slack"} />
          <div className="flex min-w-0 flex-1 flex-col gap-1">
            <h3 className="w-full truncate font-medium leading-5 dark:text-white">
              Slack report
            </h3>
            <div className="text-xs">Click to connect</div>
            <BetaFeatureText className="absolute right-0 top-0 mr-4 mt-4" />
          </div>
        </ButtonCard>
      </div>
    </div>
  );
};

const GoogleSheetsHandoff = () => {
  const [open, setOpen] = useState(false);
  const coreMetrics = useMetricModels();
  const { open: openRevELTSidebar } = useSetupRevELTSimple();

  const toast = useToast();
  return (
    <>
      <ButtonCard
        onClick={() => {
          if (coreMetrics.length === 0) {
            toast(
              "No metrics to export",
              "Set up a metric before exporting to Google Sheets",
              "info",
            );
            return;
          }
          setOpen(true);
        }}
        className={classNames(
          "group w-64 items-center justify-start gap-3 rounded-sm p-5 text-left",
        )}
      >
        <IntegrationLogoBox id={"google-sheets"} />
        <div className="flex min-w-0 flex-1 flex-col gap-1">
          <h3 className="w-full truncate font-medium leading-5 dark:text-white">
            Google sheets
          </h3>
          <div className="text-xs">Click to set up</div>
        </div>
      </ButtonCard>
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalHeader className="flex items-center justify-between">
          <h1 className="text-lg font-medium">Select Data Model</h1>
          <div className="flex items-center space-x-1 text-xs">
            <IntegrationLogoBox id={"google-sheets"} size="xs" />
            <span>Export to Google Sheets</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className=" space-y-4">
            {coreMetrics.map((model) => {
              return (
                <ExportModelButton
                  key={model.id}
                  model={model}
                  onClick={() => {
                    openRevELTSidebar(model, "google-sheets");
                    setOpen(false);
                  }}
                />
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const ExportModelButton = (props: {
  model: ReturnType<typeof useMetricModels>[number];
  onClick: () => void;
}) => {
  return (
    <ButtonCard
      key={props.model.id}
      className="flex w-full items-center justify-start space-x-2 p-4 text-start"
      onClick={() => {
        props.onClick();
      }}
    >
      <div className="flex h-8 w-8 flex-none items-center justify-center rounded-sm bg-blue-500">
        <ModelIcon model={props.model} className="h-4 w-4 text-white" />
      </div>
      <ModelDisplayName modelId={props.model.id} />
    </ButtonCard>
  );
};

const useMetricModels = () => {
  const { models } = useListModels();
  const { config } = useTemplateConfig();

  return useMemo(() => {
    return models.filter((model) => {
      //Only include models that are part of a metric:
      const modelMetric = config?.metrics.find(
        (metric) => metric.config.templateItemId === model.templateItemId,
      );
      return !!modelMetric;
    });
  }, [config?.metrics, models]);
};
