import {
  FeatureSet,
  PlanName,
  Product,
  useAllWeldPlansQuery,
  useCurrentWeldPlanQuery,
  WeldAddOnType,
  WeldPlan,
} from "@/apollo/types";
import { maxBy, minBy } from "lodash";
import { useCallback } from "react";

import { useAvailablePlanAddOns } from "./usePlanAddOns";

const DefaultFeatureSet: FeatureSet = {
  limitConnectors: 2,
  limitUsers: 1,
  limitRows: 2_000_000,
  limitWorkspaces: 1,
  minSyncIntervalInMinutes: 24 * 60,
  lineageEnabled: true,
  orchestrationEnabled: false,
  modelTestingEnabled: false,
  customConnectorEnabled: false,
  columnHashingEnabled: false,
  aiAssistantHistoryEnabled: false,
  auditLogsEnabled: false,
};

export function useWeldPlan() {
  const { data: currentPlanData, loading: isLoadingCurrentPlan } =
    useCurrentWeldPlanQuery();
  const { data: allWeldPlansData } = useAllWeldPlansQuery();

  const currentPlan = currentPlanData?.currentPlan;
  const planName = currentPlan?.name ?? PlanName.Core;
  const features = currentPlan?.features ?? DefaultFeatureSet;

  return {
    loading: isLoadingCurrentPlan,
    name: planName,
    features,
    priceLookupKeys: currentPlan?.priceLookupKeys ?? null,
    allWeldPlans: allWeldPlansData?.allWeldPlans ?? [],
  };
}

export function useWeldPlans() {
  const { data: allWeldPlansData, loading } = useAllWeldPlansQuery();
  const planAddOns = useAvailablePlanAddOns();

  const plans = allWeldPlansData?.allWeldPlans ?? [];
  const activePlans = plans.filter((x) => x.isLegacyPlan !== true);

  return {
    loading,
    plans,
    activePlans,
    getApplicablePlan: useCallback(
      (
        targetFeatures: Partial<
          Pick<
            FeatureSet,
            "minSyncIntervalInMinutes" | "limitConnectors" | "limitUsers"
          >
        >,
      ) => {
        if (targetFeatures.minSyncIntervalInMinutes) {
          const addOns = planAddOns.getByType(WeldAddOnType.SyncFrequency);
          return featureTests.minSyncIntervalInMinutes(
            targetFeatures.minSyncIntervalInMinutes,
            activePlans,
            addOns,
          );
        }
        if (targetFeatures.limitConnectors) {
          return featureTests.limitConnectors(
            targetFeatures.limitConnectors,
            activePlans,
            [],
          );
        }

        return null;
      },
      [activePlans, planAddOns],
    ),
  };
}

type TargetPlan = {
  plan: WeldPlan;
  addOn: Product | null;
};

type FeatureTests = {
  [K in keyof Pick<
    FeatureSet,
    "minSyncIntervalInMinutes" | "limitConnectors" | "limitUsers"
  >]: (
    arg: FeatureSet[K],
    plans: WeldPlan[],
    addOns: Product[],
  ) => TargetPlan | null;
};

const getApplicablePlanForSyncFrequency: FeatureTests["minSyncIntervalInMinutes"] =
  (value, plans, planAddOns) => {
    let applicableCombo: TargetPlan | null = null;

    // Which plan would allow this option?
    const targetPlan = maxBy(
      plans.filter((plan) => plan.features.minSyncIntervalInMinutes <= value),
      (plan) => plan.features.minSyncIntervalInMinutes,
    );

    if (targetPlan) {
      applicableCombo = {
        plan: targetPlan,
        addOn: null,
      };
    }

    const EssentialPlan = plans.find((x) => x.name === PlanName.Essential);
    if (targetPlan?.name !== PlanName.Essential && EssentialPlan != null) {
      // Which add-on on the Essential plan would allow this option?
      const targetAddOn = maxBy(
        planAddOns.filter(
          (x) =>
            x.features.minSyncIntervalInMinutes != null &&
            x.features.minSyncIntervalInMinutes <= value,
        ),
        (x) => x.features.minSyncIntervalInMinutes,
      );
      if (targetAddOn) {
        applicableCombo = {
          plan: EssentialPlan,
          addOn: targetAddOn,
        };
      }
    }
    return applicableCombo;
  };

const getApplicablePlanForConnectorsLimit: FeatureTests["limitConnectors"] = (
  value,
  plans,
) => {
  // Which plan would allow this option?
  let targetPlan = minBy(
    plans.filter((plan) => plan.features.limitConnectors >= value),
    (plan) => plan.features.limitConnectors,
  );

  if (!targetPlan) {
    targetPlan = plans.find((x) => x.features.limitConnectors === -1);
  }

  if (targetPlan) {
    return {
      plan: targetPlan,
      addOn: null,
    };
  }
  return null;
};

const getApplicablePlanForUsersLimit: FeatureTests["limitUsers"] = (
  value,
  plans,
) => {
  // Which plan would allow this option?
  let targetPlan = minBy(
    plans.filter((plan) => plan.features.limitUsers >= value),
    (plan) => plan.features.limitUsers,
  );

  if (!targetPlan) {
    targetPlan = plans.find((x) => x.features.limitUsers === -1);
  }

  if (targetPlan) {
    return {
      plan: targetPlan,
      addOn: null,
    };
  }
  return null;
};

const featureTests: FeatureTests = {
  minSyncIntervalInMinutes: getApplicablePlanForSyncFrequency,
  limitConnectors: getApplicablePlanForConnectorsLimit,
  limitUsers: getApplicablePlanForUsersLimit,
};
