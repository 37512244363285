import { ButtonPrimitive } from "@/components/elements/Button";
import IconWithBG from "@/components/elements/IconWithBG";
import Tooltip from "@/components/elements/Tooltip";
import cn from "@/helpers/classNames";
import { IntegrationLogoBox } from "@/integrations";
import { IntegrationType } from "@/integrations";
import { ComponentProps } from "@tw-classed/react";

export function ConnectorRecommendationItem({
  integration,
  size,
  ...props
}: {
  integration: IntegrationType;
  size: ComponentProps<typeof IntegrationLogoBox>["size"];
} & ComponentProps<typeof ButtonPrimitive>) {
  return (
    <Tooltip content={integration.name}>
      <ButtonPrimitive {...props} className={cn("group", props.className)}>
        <IntegrationLogoBox
          id={integration.id}
          size={size}
          className="opacity-80 ring-black/20 transition-all group-hover:opacity-100 group-hover:ring-2 dark:ring-white/20"
        />
      </ButtonPrimitive>
    </Tooltip>
  );
}

export function ConnectorRecommendation150More(
  props: Omit<
    ComponentProps<typeof ConnectorRecommendationItem>,
    "integration"
  >,
) {
  return (
    <ButtonPrimitive {...props} className={cn("group", props.className)}>
      <IconWithBG
        size="lg"
        icon={<span className="h-auto w-auto text-xs font-bold">..150+</span>}
        className="bg-gray-200 opacity-80 ring-black/20  transition-all group-hover:opacity-100 group-hover:ring-2 dark:bg-gray-700 dark:ring-white/20"
      />
    </ButtonPrimitive>
  );
}
