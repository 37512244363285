import produce from "immer";

import { GetStreamsQuery, OperationMode } from "@/apollo/types";

import { SourceStream, Substream } from "../types";

export type SourceStreamsState = { [key: string]: SourceStream | undefined };

export type SourceStreamsAction =
  | {
      type: "set_source_streams";
      payload: {
        streams: GetStreamsQuery["getStreams"];
      };
    }
  | {
      type: "toggle_full_sync";
      payload: {
        stream: GetStreamsQuery["getStreams"][0];
      };
    }
  | {
      type: "disable_incremental_sync";
      payload: {
        stream: GetStreamsQuery["getStreams"][0];
      };
    }
  | {
      type: "toggle_incremental_sync";
      payload: (
        | { mode: "MERGE"; primaryKeys: string[] }
        | { mode: "APPEND" }
      ) & {
        stream: GetStreamsQuery["getStreams"][0];
        cursor: string;
      };
    }
  | {
      type: "set_incremental_config";
      payload: {
        cursor: string;
        primaryKeys: string[];
        sourceStreamName: string;
      };
    }
  | {
      type: "change_excluded_column";
      payload: {
        columnName: string;
        sourceStreamName: string;
        checked: boolean;
      };
    }
  | {
      type: "change_hashed_column";
      payload: {
        columnName: string;
        sourceStreamName: string;
        hashed: boolean;
      };
    }
  | {
      type: "toggle_substream_excluded_column";
      payload: {
        sourceStreamName: string;
        substreamName: string;
        columnName: string;
      };
    }
  | {
      type: "set_substream_hashed_column";
      payload: {
        sourceStreamName: string;
        substreamName: string;
        columnName: string;
        hashed: boolean;
      };
    }
  | {
      type: "set_source_stream";
      payload: {
        checked: boolean;
        stream: GetStreamsQuery["getStreams"][0];
      };
    }
  | {
      type: "toggle_full_sync_at_midnight";
      payload: { sourceStreamName: string };
    }
  | {
      type: "toggle_protected_from_full_sync";
      payload: { sourceStreamName: string };
    };

const validateState = (
  stream: GetStreamsQuery["getStreams"][0],
  streamState: SourceStream,
) => {
  if (
    stream.configurableIncremental &&
    streamState?.mode !== "FULL" &&
    !streamState?.incrementalPointerId.length
  ) {
    // When incremental sync is configurable, but it's empty
    streamState.isIncrementalInvalid = true;
    return streamState;
  }

  streamState.isIncrementalInvalid = false;
  return streamState;
};

const createInitialSourceStreamState = (
  stream: GetStreamsQuery["getStreams"][0],
): SourceStream => {
  const state = {
    primaryKeys: [],
    incrementalPointerId: "",
    hashedColumns: [],
    excludedColumns: [],
    substreams: {},
    fullSyncAlways: false,
    fullSyncAtMidnight: false,
    protectedFromFullSync: false,
    mode: stream.supportedModes.includes(OperationMode.Incremental)
      ? "MERGE"
      : "FULL",
    ...stream.defaultConfig,
  };
  return validateState(stream, state);
};

export function sourceStreamsReducer(
  state: SourceStreamsState,
  action: SourceStreamsAction,
) {
  switch (action.type) {
    case "set_source_streams": {
      return produce(state, (draft) => {
        action.payload.streams.forEach((stream) => {
          if (draft[stream.name]) return;
          draft[stream.name] = {
            ...createInitialSourceStreamState(stream),
            substreams: stream.substreams.reduce<{ [key: string]: Substream }>(
              (prev, cur) => {
                prev[cur.destinationTableName] = {
                  hashedColumns: [],
                  excludedColumns: [],
                };
                return prev;
              },
              {},
            ),
          };
        });
        Object.keys(draft).forEach((sourceStreamName) => {
          if (
            !action.payload.streams.some(
              (value) => value.name === sourceStreamName,
            )
          ) {
            delete draft[sourceStreamName];
          }
        });
      });
    }
    case "set_source_stream": {
      return produce(state, (draft) => {
        const exists = draft[action.payload.stream.name];
        if (!action.payload.checked && exists) {
          delete draft[action.payload.stream.name];
          return;
        }
        if (exists) return;
        draft[action.payload.stream.name] = {
          ...createInitialSourceStreamState(action.payload.stream),
          substreams: action.payload.stream.substreams.reduce<{
            [key: string]: Substream;
          }>((prev, cur) => {
            prev[cur.destinationTableName] = {
              hashedColumns: [],
              excludedColumns: [],
            };
            return prev;
          }, {}),
        };
      });
    }
    case "toggle_substream_excluded_column": {
      return produce(state, (draft) => {
        const substream =
          draft[action.payload.sourceStreamName]?.substreams[
            action.payload.substreamName
          ];
        if (!substream)
          throw new Error("No substream:" + action.payload.substreamName);
        const exists = !!substream.excludedColumns.includes(
          action.payload.columnName,
        );
        if (exists) {
          substream.excludedColumns = substream.excludedColumns.filter(
            (c) => c !== action.payload.columnName,
          );
        } else {
          substream.excludedColumns.push(action.payload.columnName);
        }
      });
    }
    case "set_substream_hashed_column": {
      return produce(state, (draft) => {
        const substream =
          draft[action.payload.sourceStreamName]?.substreams[
            action.payload.substreamName
          ];
        if (!substream)
          throw new Error("No substream" + action.payload.substreamName);
        if (!action.payload.hashed) {
          substream.hashedColumns = substream.hashedColumns.filter(
            (c) => c !== action.payload.columnName,
          );
        } else {
          substream.hashedColumns.push(action.payload.columnName);
          substream.hashedColumns = [...new Set(substream.hashedColumns)];
        }
      });
    }
    case "toggle_full_sync": {
      return produce(state, (draft) => {
        const streamState = draft[action.payload.stream.name];
        if (!streamState) {
          throw new Error("No stream" + action.payload.stream.name);
        }
        streamState.fullSyncAlways = !streamState.fullSyncAlways;
        if (streamState.fullSyncAlways) {
          streamState.incrementalPointerId = "";
          streamState.primaryKeys = [];
          streamState.mode = "FULL";
          streamState.fullSyncAtMidnight = false;
          streamState.protectedFromFullSync = false;
        } else {
          streamState.mode = "MERGE";
        }

        validateState(action.payload.stream, streamState);
      });
    }
    case "disable_incremental_sync": {
      return produce(state, (draft) => {
        const streamState = draft[action.payload.stream.name];
        if (!streamState) {
          throw new Error("No stream" + action.payload.stream.name);
        }
        streamState.primaryKeys = [];
        streamState.incrementalPointerId = "";
        streamState.fullSyncAlways = true;
        streamState.mode = "FULL";

        validateState(action.payload.stream, streamState);
      });
    }
    case "toggle_incremental_sync": {
      return produce(state, (draft) => {
        const streamState = draft[action.payload.stream.name];
        if (!streamState) {
          throw new Error("No stream" + action.payload.stream.name);
        }
        streamState.fullSyncAlways = false;
        if (action.payload.mode === "MERGE") {
          streamState.mode = "MERGE";
          streamState.primaryKeys = action.payload.primaryKeys;
          streamState.incrementalPointerId = action.payload.cursor;
        } else {
          streamState.mode = "APPEND";
          streamState.primaryKeys = [];
          streamState.incrementalPointerId = action.payload.cursor;
        }

        validateState(action.payload.stream, streamState);
      });
    }
    case "toggle_full_sync_at_midnight": {
      return produce(state, (draft) => {
        const stream = draft[action.payload.sourceStreamName];
        if (!stream)
          throw new Error("No stream" + action.payload.sourceStreamName);
        stream.fullSyncAtMidnight = !stream.fullSyncAtMidnight;
        stream.protectedFromFullSync = false;
      });
    }
    case "toggle_protected_from_full_sync": {
      return produce(state, (draft) => {
        const stream = draft[action.payload.sourceStreamName];
        if (!stream)
          throw new Error("No stream" + action.payload.sourceStreamName);
        stream.protectedFromFullSync = !stream.protectedFromFullSync;
        stream.fullSyncAtMidnight = false;
      });
    }
    case "set_incremental_config": {
      return produce(state, (draft) => {
        const stream = draft[action.payload.sourceStreamName];
        if (!stream)
          throw new Error("No stream" + action.payload.sourceStreamName);
        stream.primaryKeys = action.payload.primaryKeys;
        stream.incrementalPointerId = action.payload.cursor;
        stream.isIncrementalInvalid = false;
      });
    }

    case "change_excluded_column": {
      return produce(state, (draft) => {
        const stream = draft[action.payload.sourceStreamName];
        if (!stream)
          throw new Error("No stream" + action.payload.sourceStreamName);
        if (!action.payload.checked) {
          stream.excludedColumns = [
            ...new Set([...stream.excludedColumns, action.payload.columnName]),
          ];
          stream.hashedColumns = stream.hashedColumns.filter(
            (c) => c !== action.payload.columnName,
          );
        } else {
          stream.excludedColumns = stream.excludedColumns.filter(
            (c) => c !== action.payload.columnName,
          );
        }
      });
    }

    case "change_hashed_column": {
      return produce(state, (draft) => {
        const stream = draft[action.payload.sourceStreamName];
        if (!stream)
          throw new Error("No stream" + action.payload.sourceStreamName);
        if (!action.payload.hashed) {
          stream.hashedColumns = stream.hashedColumns.filter(
            (c) => c !== action.payload.columnName,
          );
        } else {
          stream.hashedColumns = [
            ...new Set([...stream.hashedColumns, action.payload.columnName]),
          ];
        }
      });
    }
    default: {
      return state;
    }
  }
}
