import {
  Listbox,
  ListboxButtonProps,
  ListboxOptionProps,
  ListboxOptionsProps,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import React, { ElementType, Ref, forwardRef, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";

import { Button } from "../Button";

export type DropdownItem<T = {}> = {
  id: string;
  title: string;
  description?: string | JSX.Element;
  icon?: JSX.Element;
  value?: T;
  disabled?: boolean;
  badge?: React.ReactNode;
};

type Props<T> = {
  disabled?: boolean;
  options: DropdownItem<T>[];
  placeholder?: string;
  selected?: DropdownItem<T> | null;
  onSelect: (value: DropdownItem<T>) => void;
  allowClickDisabled?: true;
  className?: string;
  children?: (option: DropdownItem<T>) => React.ReactNode;
};

function ListboxDropdown<T>(props: Props<T>) {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: "absolute",
      placement: "bottom-start",
      modifiers: [
        { name: "preventOverflow", enabled: true },
        { name: "offset", options: { offset: [0, 10] } },
      ],
    },
  );

  const width = useMemo(
    () => referenceElement?.getBoundingClientRect().width || 0,
    [referenceElement],
  );

  return (
    <div ref={setReferenceElement} className={props.className}>
      <Listbox
        value={props.selected}
        onChange={props.onSelect}
        disabled={props.disabled}
      >
        <Listbox.Label className="sr-only">
          {props.placeholder ?? "Select an option"}
        </Listbox.Label>

        <ListboxButton className="h-auto" onClick={() => forceUpdate?.()}>
          {props.selected?.icon && (
            <div className="flex h-6 w-6 flex-none items-center justify-center">
              {props.selected?.icon}
            </div>
          )}
          <span className="grow text-left text-sm">
            <div className={classNames(!props.selected && "opacity-50")}>
              {props.selected?.title ?? props.placeholder ?? "Select option"}
            </div>
            {props.selected?.description && (
              <div className="text-xs font-normal text-gray-500">
                {props.selected?.description}
              </div>
            )}
          </span>
          <ChevronUpDownIcon className="w-5 text-gray-500" />
        </ListboxButton>

        {createPortal(
          <ListboxOptions
            ref={setPopperElement}
            style={{ ...styles.popper, width, zIndex: 60 }}
            {...attributes.popper}
          >
            {props.options.map((option) =>
              typeof props.children === "function" ? (
                props.children(option)
              ) : (
                <ListboxOption
                  key={option.id}
                  value={option}
                  disabled={option.disabled}
                >
                  {option.icon && (
                    <div className="flex-none">{option.icon}</div>
                  )}
                  <div className="flex-grow truncate">
                    <p className="text-left">{option.title}</p>
                    {option.description && (
                      <p className="w-full text-left text-xs">
                        {option.description}
                      </p>
                    )}
                  </div>
                  {option.badge}
                </ListboxOption>
              ),
            )}
          </ListboxOptions>,
          document.body,
        )}
      </Listbox>
    </div>
  );
}

export default ListboxDropdown;

export const ListboxButton = forwardRef<
  HTMLButtonElement,
  ListboxButtonProps<typeof Button>
>((props, ref) => {
  return (
    <Listbox.Button
      ref={ref}
      as={Button}
      {...props}
      className={(state) =>
        classNames(
          "flex w-full items-center space-x-2 rounded-sm border px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary dark:border-gray-600 dark:bg-gray-800 dark:text-white",
          state.disabled && "opacity-50",
          state.open && "ring-2 ring-primary",
          typeof props.className === "function"
            ? props.className(state)
            : props.className,
        )
      }
    />
  );
});

export const ListboxOptions = forwardRef<
  HTMLUListElement,
  ListboxOptionsProps<"ul">
>((props, ref) => {
  return (
    <Listbox.Options
      ref={ref}
      {...props}
      className={classNames(
        "absolute left-0 z-50 max-h-96 w-full origin-top-right overflow-auto rounded border bg-white shadow-lg focus:outline-none dark:border-gray-600 dark:bg-gray-700",
        props.className,
      )}
    />
  );
});

function ListboxOptionFn<TTag extends ElementType, TType>(
  props: ListboxOptionProps<TTag, TType>,
  ref: Ref<HTMLElement>,
) {
  return (
    <Listbox.Option
      ref={ref}
      {...(props as any)}
      value={props.value}
      className={({ active, selected, disabled }) => {
        return classNames(
          "rounded hover:bg-primary hover:text-white",
          "flex w-full cursor-default items-center space-x-4 px-4 py-2 ",
          selected && !active
            ? "text-primary dark:text-white"
            : "dark:text-white",
          selected && active && "bg-primary text-white",
          disabled && "opacity-40",
          (props as any).className,
        );
      }}
    />
  );
}

export const ListboxOption = forwardRef(ListboxOptionFn) as <
  TTag extends ElementType,
  TType,
>(
  props: ListboxOptionProps<TTag, TType> & { ref?: Ref<HTMLElement> },
) => ReturnType<typeof ListboxOptionFn>;
