import { XMarkIcon } from "@heroicons/react/24/outline";
import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import { Button, IconButton } from "@/components/elements/Button";
import { Input } from "@/components/primitives/input";
import FieldLabel from "@/components/primitives/InputLabel";
import { useCallback, useState } from "react";

import { KlaviyoReport, isKlaviyoStream } from "./KlaviyoForm";
import { InputError } from "@/components/primitives/InputError";
import Select from "@/components/elements/Select";
import { DropdownOption } from "@/integrations";

const INVALID_REPORT_MESSAGE = "Invalid report";

export default function KlaviyoCustomReport(props: {
  onChangeReport: (report: KlaviyoReport) => void;
  onRemoveReport: (report: KlaviyoReport) => void;
  onReportInvalid: (reportId: string) => void;
  onReportValid: (reportId: string) => void;
  report: KlaviyoReport;
  connectionId: string;
  statistics: { label: string; value: string }[];
  metrics: { label: string; value: string }[];
  types: { label: string; value: string }[];
  validationError?: string;
}) {
  const {
    report,
    onChangeReport,
    onRemoveReport,
    validationError,
    connectionId,
    statistics,
    metrics,
    types,
    onReportValid,
    onReportInvalid,
  } = props;

  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [validationCallError, setValidationCallError] = useState<string | null>(
    null,
  );

  const [callValidationQuery] = useValidateCustomFormLazyQuery();

  const runValidation = useCallback(async () => {
    //Call validation endpoint
    const queryData = report;
    const { data, error } = await callValidationQuery({
      variables: {
        input: {
          connectionId,
          payload: JSON.stringify({
            report: queryData,
          }),
        },
      },
    });
    //If error occurs, show warning and use initial metrics/dimensions
    if (error) {
      if (error.message === INVALID_REPORT_MESSAGE) {
        onReportInvalid(report.id);
      } else {
        setValidationCallError(error.message);
      }
    }

    const result = data?.validateCustomForm.customFormValidationResult;
    if (result) {
      if (result.valid) {
        setValidationSuccess(true);
        onReportValid(report.id);
        setValidationCallError(null);
      } else {
        setValidationCallError(result.message);
        onReportInvalid(report.id);
      }
    }
  }, [
    callValidationQuery,
    connectionId,
    onReportInvalid,
    onReportValid,
    report,
  ]);

  const changeReport = (report: KlaviyoReport) => {
    onChangeReport(report);
    setValidationSuccess(false);
  };

  return (
    <div className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Name
        </FieldLabel>
        <Input
          placeholder="Name of your report"
          value={report.name}
          onChange={(e) => changeReport({ ...report, name: e.target.value })}
        />
        {isKlaviyoStream(report.name) && (
          <InputError>
            {report.name} is a reserved table name and cannot be used
          </InputError>
        )}
      </div>
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Type
        </FieldLabel>
        <Select
          inputId="type"
          placeholder="Select one or more..."
          onChange={(v: DropdownOption<string>) => {
            onChangeReport({
              ...report,
              type: v,
            });
          }}
          options={types}
          value={report.type}
          hideSelectedOptions={false}
          isMulti={false}
        />
      </div>
      <div className="pb-4">
        <div className="flex h-auto justify-between">
          <FieldLabel id="statistics" required={true}>
            Statistics
          </FieldLabel>
          <Button
            onClick={() => {
              onChangeReport({ ...report, statistics });
            }}
            size="sm"
            variant="link"
            id="statistics-select-all"
          >
            Select all
          </Button>
        </div>

        <Select
          inputId="statistics"
          placeholder="Select one or more..."
          onChange={(v: DropdownOption<string>[]) => {
            onChangeReport({
              ...report,
              statistics: v,
            });
          }}
          options={statistics}
          value={report.statistics}
          hideSelectedOptions={true}
          isMulti={true}
          closeMenuOnSelect={false}
        />
      </div>
      <div className="pb-4">
        <FieldLabel id="query" required={true}>
          Conversion metric
        </FieldLabel>
        <Select
          inputId="statistics"
          placeholder="Select one..."
          onChange={(v: DropdownOption<string>) => {
            onChangeReport({
              ...report,
              conversionMetric: v,
            });
          }}
          options={metrics}
          value={report.conversionMetric}
          hideSelectedOptions={false}
          isMulti={false}
        />
      </div>
      <div className="flex justify-between">
        <Button
          size="sm"
          variant="solid"
          colorScheme="primary"
          onClick={() => runValidation()}
        >
          Validate report
        </Button>
      </div>
      <div className="absolute right-0 top-0 p-2">
        <IconButton
          size="xs"
          icon={<XMarkIcon />}
          variant="ghost"
          onClick={() => onRemoveReport(report)}
        />
      </div>
      {validationSuccess ? (
        <div className="mt-2">
          <Alert status="success">
            <AlertIcon />
            Report is valid
          </Alert>
        </div>
      ) : null}
      {validationError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            {validationError}
          </Alert>
        </div>
      ) : null}
      {!validationCallError && !validationSuccess ? (
        <div className="mt-2">
          <Alert status="info">
            <AlertIcon />
            Validate your report to continue
          </Alert>
        </div>
      ) : null}
      {validationCallError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Error:</AlertTitle>
            {validationCallError}
          </Alert>
        </div>
      ) : null}
    </div>
  );
}
