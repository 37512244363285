import { Button, DangerButton } from "@/components/elements/Button";
import { Heading } from "@/components/elements/Typography";
import { Overlay, OverlayBackdrop } from "@/components/elements/layout/Overlay";
import { DestinationIcon, HelpIcon } from "@/components/icons/outline";
import { DeleteAccountDialog } from "@/features/delete-account";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useIntercom } from "@/hooks/useIntercom";
import { useUserAccounts } from "@/providers/UserProvider";

import { UpgradeNowButton } from "../UpgradeNowButton";
import { WorkspaceSwitcher } from "../WorkspaceSwitcher";

export function FreemiumTrialEndedAppBlocker(props: { show?: boolean }) {
  const disclosure = useDisclosure();
  const accounts = useUserAccounts();
  const intercom = useIntercom();
  if (props.show !== true) {
    return null;
  }
  return (
    <Overlay className="absolute">
      <OverlayBackdrop />
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="m-4 max-w-lg rounded bg-white p-8 shadow dark:border dark:border-gray-700 dark:bg-gray-800">
          <div className="flex flex-col gap-8">
            <div>
              <Heading className="mb-4 text-center text-xl">
                Your Free Trial Has Ended
              </Heading>
              <div className="text-center leading-snug">
                To continue using WELD's powerful data pipelines, please upgrade
                to a paid plan.
              </div>
              <div className="mb-5 mt-10 flex justify-center">
                <UpgradeNowButton source="freemium trial ended app blocker" />
              </div>
            </div>
            <hr />
            <div>
              <div className="text-center text-sm">
                Not ready to upgrade yet? Chat with us to discuss extending your
                trial or explore other options.
              </div>
              <div className="mt-5 flex justify-center">
                <Button
                  size="sm"
                  icon={<HelpIcon />}
                  onClick={() => intercom.openChat()}
                >
                  Open chat
                </Button>
              </div>
            </div>
            {accounts.length > 1 && (
              <>
                <hr />
                <div>
                  <div className="text-center text-sm">
                    Your other accounts are not affected by this trial.
                  </div>
                  <div className="mt-5 flex justify-center">
                    <WorkspaceSwitcher allowCreateDestination={false}>
                      <Button size="sm" icon={<DestinationIcon />}>
                        Switch account
                      </Button>
                    </WorkspaceSwitcher>
                  </div>
                </div>
              </>
            )}
            <hr />
            <div>
              <div className="text-sm">
                If you choose not to upgrade, you may delete your account. Note
                that this will permanently delete all your data stored in WELD
                but will not affect data already synced to your warehouse.
              </div>
              <DangerButton
                size="sm"
                variant="link"
                onClick={() => disclosure.onOpen()}
              >
                Delete Account
              </DangerButton>
              <DeleteAccountDialog {...disclosure} />
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
