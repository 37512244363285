import dayjs from "dayjs";

import { ListModelsQuery, useOrchestrationWorkflowQuery } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import { IntegrationLogo } from "@/integrations";

import EditModelMaterializationButton from "./EditModelMaterialization";
import OrchestrationSchedulerTypeInfo from "./OrchestrationSchedulerTypeInfo";
import { InfoItem } from "./TableMaterilizationInfo";
import UnpublishModelButton from "./UnpublishModelButton";

type Props = { model: ListModelsQuery["models"][0]; workflowId?: string };

const ViewInfo = (props: Props) => {
  const { data } = useOrchestrationWorkflowQuery({
    variables: {
      workflowId: props.workflowId ?? "",
    },
    skip: !props.workflowId,
  });
  const dwSync = props.model.dwSync;
  if (!dwSync) return null;

  return (
    <div className="mx-auto max-w-5xl space-y-8 p-8">
      <div className="flex space-x-2">
        <div className="text-md">Materialized as view</div>
        <SyncIndicator integrationId={props.model.integrationId} />{" "}
      </div>
      <div className="flex flex-wrap text-sm ">
        {!!data && (
          <InfoItem
            label="Schedule"
            info={
              <OrchestrationSchedulerTypeInfo
                orchestrationScheduler={props.model.orchestrationScheduler}
                workflow={data?.orchestrationWorkflow}
              />
            }
          />
        )}
        <InfoItem
          label="First created"
          info={<div>{dayjs(dwSync.createdAt).format("YYYY/MM/DD HH:mm")}</div>}
        />
        <InfoItem
          label="Latest updated"
          info={
            <div>{dayjs(dwSync.latestSyncTime).format("YYYY/MM/DD HH:mm")}</div>
          }
        />
        <InfoItem
          label={`Warehouse reference`}
          info={<span className="italic">{dwSync.dwSqlReference}</span>}
        />
      </div>
      <EditModelMaterializationButton />

      <UnpublishModelButton
        modelId={props.model.id}
        materializationType={props.model.materializationType}
      />
    </div>
  );
};

export default ViewInfo;

const SyncIndicator = (props: { integrationId: string }) => {
  return (
    <Tooltip content={`Synced to ${props.integrationId} `}>
      <div className="" onClick={() => {}}>
        <IntegrationLogo id={props.integrationId} className="w-5" />
      </div>
    </Tooltip>
  );
};
