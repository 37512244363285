import { PaperAirplaneIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ChevronRightIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "@tanstack/react-location";
import {
  CurrentUserWithAccountsDocument,
  DeleteAccountFeedback,
  OpenInvitationsDocument,
  useDeclineOwnInvitationMutation,
  useDeleteAccountMutation,
  useOpenInvitationsQuery,
  useResendOwnInvitationMutation,
} from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import confirm from "@/components/elements/Confirm";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import classNames from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { capitalize } from "lodash";
import PageContainer from "@/pages/Setup/components/PageContainer";
import { Description, Heading } from "@/pages/Setup/components/Typography";
import { useToast } from "@/providers/ToastProvider";
import { AccountType, useUserAccounts } from "@/providers/UserProvider";
import React from "react";
import * as routeHelpers from "router/routeHelpers";
import { useNavigateToWorkspace } from "router/useNavigateToWorkspace";

export function WorkspacesOverview() {
  const existingAccounts = useUserAccounts();
  const invitationsQuery = useOpenInvitationsQuery();
  const navigate = useNavigate();
  const navigateToWorkspace = useNavigateToWorkspace();

  const invitationsLoaded =
    invitationsQuery.loading === false && invitationsQuery.error == null;
  const invitations = React.useMemo(
    () => invitationsQuery.data?.openInvitations ?? [],
    [invitationsQuery.data?.openInvitations],
  );

  React.useEffect(() => {
    if (!invitationsLoaded) return;
    if (existingAccounts.length === 0 && invitations.length === 0) {
      // Redirect to create account page. Remember to include the search params
      navigate({
        to: routeHelpers.createWorkspaceRoute(),
        replace: true,
        search: true,
      });
    }
  }, [existingAccounts, invitationsLoaded, invitations, navigate]);

  const { connectedWorkspaces, unconnectedWorkspaces } = React.useMemo(() => {
    return existingAccounts.reduce<{
      connectedWorkspaces: typeof existingAccounts;
      unconnectedWorkspaces: typeof existingAccounts;
    }>(
      (acc, x) => {
        if (x.dataWarehouseConnectionId) {
          acc.connectedWorkspaces.push(x);
        } else {
          acc.unconnectedWorkspaces.push(x);
        }
        return acc;
      },
      { connectedWorkspaces: [], unconnectedWorkspaces: [] },
    );
  }, [existingAccounts]);

  const title = "Select your account";
  return (
    <PageContainer size="md">
      <Heading>{title}</Heading>
      <Description className="mt-2 w-3/4 text-center text-sm">
        Select or create a new account or join one you have been invited to.
      </Description>
      <div className="my-8 w-full max-w-sm space-y-8">
        {invitations.length > 0 && (
          <div>
            <SectionHeading>
              Open account invitations, join by verifying your email.
            </SectionHeading>
            <div className="space-y-2">
              {invitations.map((x) => (
                <div
                  key={x.id}
                  className="w-full rounded-sm border border-gray-200 px-4 py-3 text-gray-400 dark:border-gray-700"
                >
                  <div className="flex h-9 w-full items-center text-left">
                    <SquareBox className="mr-4">
                      {x.account.name?.[0]}
                    </SquareBox>
                    <span className="mr-2 truncate">{x.account.name}</span>

                    <div className="ml-auto shrink-0">
                      <InvitationActions accountId={x.account.id} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <div>
            <SectionHeading className="flex">
              Existing accounts
              {connectedWorkspaces.length > 5 && (
                <Button
                  onClick={() => {
                    navigate({
                      to: routeHelpers.createWorkspaceRoute(),
                      search: true,
                    });
                  }}
                  variant="ghost"
                  size="xs"
                  className="ml-auto"
                >
                  + Create new
                </Button>
              )}
            </SectionHeading>
          </div>
          <div className="space-y-2">
            {connectedWorkspaces.map((x) => (
              <ButtonCard
                key={x.id}
                className="w-full px-4 py-3"
                onClick={() => navigateToWorkspace.bySlug(x.slug)}
              >
                <div className="flex w-full items-center text-left">
                  <SquareBox colorScheme="primary" className="mr-4">
                    {x.name[0]}
                  </SquareBox>
                  <span className="mr-2 truncate">{x.name}</span>
                  <ChevronRightIcon className="ml-auto w-4 shrink-0 transform ease-in-out group-hover:scale-110" />
                </div>
              </ButtonCard>
            ))}
            <ButtonCard
              className="w-full px-4 py-3"
              onClick={() =>
                navigate({
                  to: routeHelpers.createWorkspaceRoute(),
                  search: true,
                })
              }
            >
              + Create new account
            </ButtonCard>
          </div>
        </div>
        {unconnectedWorkspaces.length > 0 && (
          <div>
            <SectionHeading className="flex">
              Accounts not yet configured
            </SectionHeading>
            <div className="space-y-2">
              {unconnectedWorkspaces.map((x) => (
                <UnconfiguredWorkspaceItem key={x.id} account={x} />
              ))}
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
}

function SectionHeading(
  props: React.PropsWithChildren<React.ComponentProps<"div">>,
) {
  return (
    <div className="mb-2 flex items-center text-sm text-gray-500 dark:text-gray-300">
      {props.children}
    </div>
  );
}

type SquareBoxProps = {
  colorScheme?: "primary" | "gray";
};

function SquareBox(
  props: React.PropsWithChildren<SquareBoxProps & React.ComponentProps<"div">>,
) {
  function getColorClasses(props: SquareBoxProps) {
    switch (props.colorScheme) {
      case "primary":
        return "bg-primary text-white";
      default:
        return "bg-gray-500 text-white";
    }
  }

  return (
    <div
      className={classNames(
        "grid h-8 w-8 shrink-0 select-none place-items-center whitespace-nowrap rounded-sm",
        getColorClasses(props),
        "text-sm font-semibold uppercase",
        props.className,
      )}
    >
      <span>{props.children}</span>
    </div>
  );
}

type InvitationActionsProps = {
  accountId: string;
};

function InvitationActions({ accountId }: InvitationActionsProps) {
  const toast = useToast();
  const [resendInvitation, resendStatus] = useResendOwnInvitationMutation({
    variables: {
      accountId,
    },
    onCompleted: () => {
      toast("Invitation sent", "Check your email", "success");
    },
    onError: () => {
      toast("Invitation was not sent", "", "error");
    },
  });

  const [declineInvitation, declineStatus] = useDeclineOwnInvitationMutation({
    variables: {
      accountId,
    },
    refetchQueries: [OpenInvitationsDocument],
    onCompleted: () => {
      toast("Invitation declined", "", "success");
    },
    onError: () => {
      toast("Invitation was not declined", "", "error");
    },
  });

  const loading = resendStatus.loading || declineStatus.loading;
  const called =
    (resendStatus.called && !resendStatus.error) ||
    (declineStatus.called && !declineStatus.error);

  if (called) {
    return loading ? <LoadingSpinner className="h-5 w-5" /> : null;
  }

  return (
    <div className="flex flex-col items-end">
      <Button
        size="xs"
        colorScheme="secondary"
        variant="link"
        onClick={() => resendInvitation()}
        icon={<PaperAirplaneIcon />}
      >
        resend mail
      </Button>
      <Button
        size="xs"
        colorScheme="danger"
        variant="link"
        onClick={async () => {
          const confirmed = await confirm({
            type: "danger",
            title: "Are you sure?",
            message: "You will no longer be able to join the account",
          });
          if (confirmed) {
            declineInvitation();
          }
        }}
        icon={<TrashIcon />}
      >
        decline
      </Button>
    </div>
  );
}

function UnconfiguredWorkspaceItem({ account }: { account: AccountType }) {
  const navigate = useNavigate();
  const navigateToWorkspace = useNavigateToWorkspace();
  const disclusure = useDisclosure();
  const toast = useToast();

  const [deleteAccount, { loading: deletingAccount }] =
    useDeleteAccountMutation({
      onError(error: { message: string }) {
        toast(
          `${capitalize(account.name ?? "Your account")} was not deleted`,
          error.message,
          "error",
        );
      },
      refetchQueries: [CurrentUserWithAccountsDocument],
      onCompleted() {
        navigate({ to: routeHelpers.workspacesRoute() });
      },
    });

  return (
    <div className="flex gap-2">
      <ButtonCard
        className="grow px-4 py-3"
        onClick={() => navigateToWorkspace.bySlug(account.slug)}
      >
        <div className="flex w-full items-center text-left">
          <SquareBox colorScheme="primary" className="mr-4">
            {account.name[0]}
          </SquareBox>
          <span className="mr-2 truncate">{account.name}</span>
          <Cog6ToothIcon className="ml-auto w-4 shrink-0 transform ease-in-out group-hover:scale-110" />
        </div>
      </ButtonCard>
      <ButtonCard className="w-14 shrink-0" onClick={disclusure.onOpen}>
        {deletingAccount ? <LoadingSpinner /> : <TrashIcon className="w-4 " />}
      </ButtonCard>
      <ConfirmDeleteModal
        title="this account"
        onConfirm={async () => {
          await deleteAccount({
            context: {
              accountId: account.id,
            },
            variables: {
              input: {
                id: account.id,
                deletionDetails: {
                  comment: "unconfigured account",
                  feedback: DeleteAccountFeedback.Other,
                },
              },
            },
          });
        }}
        show={disclusure.isOpen}
        onClose={disclusure.onClose}
      />
    </div>
  );
}
