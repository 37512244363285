import React from "react";
import { twMerge } from "tailwind-merge";

import { MaterializationType } from "@/apollo/types";
import IconWithBG from "@/components/elements/IconWithBG";
import Tooltip from "@/components/elements/Tooltip";
import TableIcon from "@/components/icons/TableIcon";
import { DataSourceIcon, ReverseEtlIcon } from "@/components/icons/outline";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

export const IconBox = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof IconWithBG>
>((props, ref) => (
  <IconWithBG
    ref={ref}
    className={twMerge("bg-gray-100 dark:bg-gray-700", props.className)}
    size="sm"
    {...props}
  />
));

export const EtlJobBox = () => (
  <Tooltip content="Data source">
    <IconBox
      icon={<DataSourceIcon />}
      className="bg-orange-100 dark:bg-orange-500/50"
    />
  </Tooltip>
);
export const ReverseEtlJobBox = () => (
  <Tooltip content="Export">
    <IconBox
      icon={<ReverseEtlIcon />}
      className="bg-rose-100 dark:bg-rose-500/50"
    />
  </Tooltip>
);

export const ModelJobBox = ({
  materializationType,
}: {
  materializationType: MaterializationType;
}) => (
  <Tooltip
    content={
      materializationType === MaterializationType.Table
        ? "Model Materialization job"
        : "Model test job"
    }
  >
    <IconBox
      icon={
        materializationType === MaterializationType.Table ? (
          <TableIcon />
        ) : (
          <DocumentTextIcon />
        )
      }
    />
  </Tooltip>
);
