import { RecurrenceInterval } from "@/apollo/types";
import Toggle from "@/components/elements/Toggle";
import Tooltip from "@/components/elements/Tooltip";
import { SectionHeading, TextMuted } from "@/components/elements/Typography";
import { ActiveRowTooltipTrigger } from "@/components/modules/ActiveRow";
import cn from "@/helpers/classNames";

import { RowVolumeSlider } from "../../../usage-limit";

export function PlanConfiguration(props: {
  tiers: { upTo: number; price: number }[];
  billingPeriod: RecurrenceInterval;
  usageLimit: number | undefined;
  onChangeUsageLimit: (value: number | undefined) => void;
  enableMeteredUsageLimit?: boolean;
  onToggleMeteredUsageLimit?: (enabled: boolean) => void;
}) {
  if (props.billingPeriod === RecurrenceInterval.Year) {
    return (
      <div className="">
        <TextMuted as="div" className="mb-4">
          Use the slider to select your monthly{" "}
          <ActiveRowTooltipTrigger>
            <span className="cursor-help underline decoration-dashed underline-offset-2">
              active row
            </span>
          </ActiveRowTooltipTrigger>{" "}
          row volume
        </TextMuted>
        {props.tiers && props.tiers.length > 0 && (
          <RowVolumeSlider
            steps={props.tiers.map((x) => x.upTo)}
            value={props.usageLimit}
            onChange={props.onChangeUsageLimit}
            labelAllTicks
          />
        )}
      </div>
    );
  }
  return (
    <div>
      <div className="flex gap-4">
        <SectionHeading className="mb-2">Usage limit</SectionHeading>
        <Tooltip
          content={
            props.enableMeteredUsageLimit
              ? "Usage is limited"
              : "Usage is not limited"
          }
        >
          <div className="ml-auto">
            <Toggle
              checked={props.enableMeteredUsageLimit}
              onChange={props.onToggleMeteredUsageLimit}
            />
          </div>
        </Tooltip>
      </div>
      <TextMuted as="div" className="mb-4 text-sm">
        Set a cap on your costs by limitting the number of{" "}
        <ActiveRowTooltipTrigger>
          <span className="cursor-help underline decoration-dashed underline-offset-2">
            active rows
          </span>
        </ActiveRowTooltipTrigger>{" "}
        your account may sync each month. Upon exceeding the set limit, your
        syncs will be paused for the remainder of the month. You can change the
        limit at any time.
      </TextMuted>
      {props.tiers != null && (
        <div
          className={cn({
            "opacity-40 saturate-0": !props.enableMeteredUsageLimit,
          })}
        >
          <RowVolumeSlider
            steps={props.tiers.map((x) => x.upTo)}
            value={props.usageLimit}
            onChange={props.onChangeUsageLimit}
            labelAllTicks
          />
        </div>
      )}
    </div>
  );
}
