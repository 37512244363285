import { isEmpty, isEqual, noop } from "lodash";
import { useEffect, useId, useMemo, useState } from "react";

import {
  AvroSchemaFieldDto,
  GetStreamsQuery,
  OperationMode,
  SchemasQuery,
  useSchemasQuery,
} from "@/apollo/types";
import {
  Button,
  IconButton,
  PrimaryButton,
  SecondaryButton,
} from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import Select from "@/components/elements/Select";
import Toggle from "@/components/elements/Toggle";
import Tooltip from "@/components/elements/Tooltip";
import { Checkbox } from "@/components/primitives/Checkbox";
import classNames from "@/helpers/classNames";
import { useTimeout } from "@/hooks/useTimeout";
import { IntegrationType } from "@/integrations";
import { Menu, Switch } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { StreamMode } from "../types/StreamMode";
import { HiglightedText } from "./HiglightedText";
import { OptionTooltips } from "./OptionTooltips";
import { SyncLabels } from "./SyncLabels";
import { useSourceStreamsState } from "./TableConfig";
import { TableOptionsMenu } from "./TableOptionsMenu";

function resolveIncrementalConfig(
  integrationId: string,
  schema: {
    fields: AvroSchemaFieldDto[];
    primaryKeys?: string[] | null;
  },
): { cursor: string; primaryKeys: string[] } | null {
  if (integrationId.includes("ftp")) {
    const pkField = schema.fields.find((c) => c.name.match(/_id$/i))?.name;
    /**
     * By dispatching PK with empty array and cursor with a value it will always default to APPEND mode.
     * (Empty array as PK will convert to null in the backend.)
     */
    return {
      cursor: "_modified",
      primaryKeys: pkField ? [pkField] : [],
    };
  }

  if (!Array.isArray(schema.primaryKeys) || schema.primaryKeys.length === 0) {
    return null;
  }

  if (integrationId === "postgresql") {
    return {
      cursor: "xmin",
      primaryKeys: schema.primaryKeys,
    };
  }

  const UpdatedAtMatcherRegex =
    /\b(updated|updatedat|updated_at|updatetime|update_time|updatedate|update_date|updatedon|updated_on|modifiedat|modified_at|modified|modifieddate|modified_date|datemodified|date_modified|lastupdated|last_updated|lastmodified|last_modified|datemodified|date_modified|timestampupdated|timestamp_updated|updatetimestamp|update_timestamp|updatedtimestamp|updated_timestamp)\b/gi;
  const updatedAtField = schema.fields.find((c) =>
    c.name.match(UpdatedAtMatcherRegex),
  )?.name;
  if (updatedAtField) {
    return {
      cursor: updatedAtField,
      primaryKeys: schema.primaryKeys,
    };
  }
  return null;
}

export default function StreamItem(props: {
  stream: GetStreamsQuery["getStreams"][0];
  search: string;
  sourceIntegration: IntegrationType;
  schema: SchemasQuery["getAllAvroSchemas"]["schemas"][0] | undefined;
  sourceId: string;
  sourceSettings: Record<string, any>;
  required?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useSourceStreamsState();
  const checked = !!state[props.stream.name] || !!props.required;
  const hasSubstreams = !!props.stream.substreams.length;

  useEffect(() => {
    if (!checked) {
      setOpen(false);
    }
  }, [checked]);

  const schema = useSchemaWithLazyLoading(
    props.stream.name,
    props.sourceId,
    props.sourceSettings,
    props.schema,
  );

  const hasColumns = checked && schema?.fields && schema.fields.length > 0;

  const onToggle = (checked: boolean) => {
    dispatch({
      type: "set_source_stream",
      payload: { checked, stream: props.stream },
    });

    if (!checked) {
      return;
    }
    if (
      schema &&
      props.stream.supportedModes.includes(OperationMode.Incremental)
    ) {
      const incrementalConfig = resolveIncrementalConfig(
        props.sourceIntegration.id,
        schema,
      );

      if (incrementalConfig) {
        dispatch({
          type: "set_incremental_config",
          payload: {
            cursor: incrementalConfig.cursor,
            primaryKeys: incrementalConfig.primaryKeys,
            sourceStreamName: props.stream.name,
          },
        });
      }
    }
  };

  const checkboxId = useId();
  return (
    <div className="relative text-sm">
      <div>
        {hasSubstreams && <ConnectingLine isParent={true} />}
        <div
          onClick={() => {
            onToggle(!checked);
          }}
          className="group flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <div className="flex w-full min-w-0 grow items-center gap-4">
            <Checkbox
              checked={checked}
              onChange={noop}
              onClick={(e) => {
                e.preventDefault();
              }}
              aria-labelledby={`source-stream-checkbox-label__${checkboxId}`}
              id={`source-stream-checkbox__${checkboxId}`}
            />
            <div className="flex w-full min-w-0 items-center gap-2">
              {hasColumns && (
                <OpenButton
                  open={open}
                  disabled={false}
                  onToggle={(e) => {
                    e.stopPropagation();
                    setOpen((prev) => !prev);
                  }}
                  checked={checked}
                />
              )}
              {checked && !schema && <LoadingSpinner className="w-4" />}
              <div
                id={`source-stream-checkbox-label__${checkboxId}`}
                className="cursor-default truncate"
                title={props.stream.name}
              >
                <HiglightedText
                  highlighted={props.search}
                  text={props.stream.name}
                />
              </div>
            </div>
            {props.required && (
              <div className="rounded-sm bg-primary px-1 text-1xs text-white">
                Required
              </div>
            )}
          </div>
          {checked && (
            <div className="ml-auto">
              <TableOptions
                stream={props.stream}
                sourceIntegration={props.sourceIntegration}
                schemaOptions={schema?.fields}
                primaryKeys={schema?.primaryKeys ?? undefined}
              />
            </div>
          )}
        </div>
        {open ? (
          <div className="pb-2 pl-8">
            {schema?.fields?.map((o) => (
              <StreamSchemaOption
                key={o.name}
                option={o}
                stream={props.stream}
                isPrimaryKey={!!schema?.primaryKeys?.includes(o.name)}
                required={props.required}
              />
            ))}
          </div>
        ) : null}
      </div>
      {hasSubstreams && (
        <div className="">
          {props.stream.substreams.map((substream, index) => (
            <SubStreamItem
              key={substream.id}
              substream={substream}
              isLast={index === props.stream.substreams.length - 1}
              search={props.search}
              stream={props.stream}
              reqired={props.required}
            />
          ))}
        </div>
      )}
    </div>
  );
}

/**
 * If the data in `props.schema` doesn't come in within 20s,
 * we allow lazy loading - starting our own little request for data.
 *
 * We prefer to use `props.schema` as it comes from a more optimized, but fail-prone source.
 */
const useSchemaWithLazyLoading = (
  name: string,
  sourceId: string,
  sourceSettings: Record<string, any>,
  schema: SchemasQuery["getAllAvroSchemas"]["schemas"][0] | undefined,
) => {
  const [state] = useSourceStreamsState();
  const checked = !!state[name];

  const [lazyLoadAllowed, setLazyLoadAllowed] = useState(false);
  useTimeout(() => {
    if (!schema) setLazyLoadAllowed(true);
  }, 20_000);

  const { data: schemaQueryData } = useSchemasQuery({
    variables: {
      sourceStreamNames: name,
      sourceConnectionId: sourceId,
      connectionSettings: sourceSettings,
    },
    skip: !checked || !lazyLoadAllowed,
  });

  return (
    schema ||
    schemaQueryData?.getAllAvroSchemas.schemas.find(
      (schema) => schema.sourceStreamName === name,
    )
  );
};

const TableOptions = (props: {
  stream: GetStreamsQuery["getStreams"][0];
  sourceIntegration: IntegrationType;
  schemaOptions?:
    | SchemasQuery["getAllAvroSchemas"]["schemas"][0]["fields"]
    | undefined;
  primaryKeys?: string[] | undefined;
}) => {
  const [state, dispatch] = useSourceStreamsState();
  const streamState = state[props.stream.name];
  const [showIncrementalSetup, setShowIncrementalSetup] = useState(false);

  const mode: StreamMode = useMemo(() => {
    if (isEqual(props.stream.supportedModes, [OperationMode.Full])) {
      return StreamMode.FIXED_FULL;
    }
    if (!streamState) {
      if (props.stream.supportedModes.includes(OperationMode.Incremental)) {
        return StreamMode.INCREMENTAL;
      }
      return StreamMode.APPEND;
    }
    if (streamState.fullSyncAlways) {
      return StreamMode.MANUAL_FULL;
    }
    if (streamState.incrementalPointerId && isEmpty(streamState.primaryKeys)) {
      return StreamMode.APPEND;
    }
    return StreamMode.INCREMENTAL;
  }, [streamState, props.stream.supportedModes]);

  const showIncrementalOption = props.stream.configurableIncremental;
  const showAlwaysFullOption = mode !== StreamMode.FIXED_FULL;
  const showMidnightOption = [
    StreamMode.INCREMENTAL,
    StreamMode.APPEND,
  ].includes(mode);

  const showProtectedOption =
    ![StreamMode.FIXED_FULL, StreamMode.MANUAL_FULL].includes(mode) &&
    !streamState?.fullSyncAtMidnight;

  const showOptions =
    showIncrementalOption ||
    showAlwaysFullOption ||
    showMidnightOption ||
    showProtectedOption;

  return (
    <div
      className="flex items-center justify-end gap-2"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {streamState?.isIncrementalInvalid ? (
        <div className="flex h-full flex-row items-center">
          <Tooltip content={"Setup is required for incremental mode"}>
            <div>
              <ExclamationCircleIcon
                className="h-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          </Tooltip>
        </div>
      ) : null}
      <SyncLabels
        streamState={streamState}
        mode={mode}
        configurableIncremental={props.stream.configurableIncremental}
        onIncrementalClick={() => setShowIncrementalSetup(true)}
      ></SyncLabels>

      {showOptions && (
        <TableOptionsMenu>
          {showIncrementalOption && (
            <Menu.Item>
              <Switch.Group>
                <Tooltip
                  content={
                    mode === "APPEND"
                      ? OptionTooltips.append
                      : OptionTooltips.incremental
                  }
                  maxWidth="300px"
                  placement="left-start"
                >
                  <div className="flex w-full items-center p-2 text-sm hover:bg-gray-100 hover:dark:bg-gray-700">
                    <Switch.Label className="grow">
                      {mode === StreamMode.INCREMENTAL
                        ? "Incremental"
                        : mode === StreamMode.APPEND
                          ? "Incremental (APPEND)"
                          : "Set up Incremental sync"}
                    </Switch.Label>
                    <Toggle
                      checked={
                        mode === StreamMode.INCREMENTAL ||
                        mode === StreamMode.APPEND
                      }
                      onChange={(checked) => {
                        if (checked) {
                          setShowIncrementalSetup(true);
                        } else {
                          dispatch({
                            type: "disable_incremental_sync",
                            payload: {
                              stream: props.stream,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </Tooltip>
              </Switch.Group>
            </Menu.Item>
          )}
          {showAlwaysFullOption && (
            <Menu.Item>
              <div>
                <Switch.Group>
                  <Tooltip
                    content={OptionTooltips.full}
                    maxWidth="300px"
                    placement="left-start"
                  >
                    <div className="flex w-full items-center p-2 text-sm hover:bg-gray-100 hover:dark:bg-gray-700">
                      <Switch.Label className="grow">
                        Always full sync
                      </Switch.Label>
                      <Toggle
                        checked={!!streamState?.fullSyncAlways}
                        onChange={(checked) => {
                          if (checked) {
                            dispatch({
                              type: "toggle_full_sync",
                              payload: {
                                stream: props.stream,
                              },
                            });
                          } else {
                            setShowIncrementalSetup(true);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                </Switch.Group>
              </div>
            </Menu.Item>
          )}
          {showMidnightOption && (
            <Menu.Item>
              <div>
                <Switch.Group>
                  <Tooltip
                    content={OptionTooltips.midnight}
                    maxWidth="300px"
                    placement="left-start"
                  >
                    <div className="flex w-full items-center p-2 text-sm hover:bg-gray-100 hover:dark:bg-gray-700">
                      <Switch.Label className="grow">
                        Full sync at midnight
                      </Switch.Label>
                      <Toggle
                        checked={!!streamState?.fullSyncAtMidnight}
                        onChange={() =>
                          dispatch({
                            type: "toggle_full_sync_at_midnight",
                            payload: {
                              sourceStreamName: props.stream.name,
                            },
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </Switch.Group>
              </div>
            </Menu.Item>
          )}
          {showProtectedOption && (
            <Menu.Item>
              <div>
                <Switch.Group>
                  <Tooltip
                    content={OptionTooltips.protected}
                    maxWidth="300px"
                    placement="left-start"
                  >
                    <div className="flex w-full items-center p-2 text-sm hover:bg-gray-100 hover:dark:bg-gray-700">
                      <Switch.Label className="grow">Protected</Switch.Label>
                      <Toggle
                        checked={!!streamState?.protectedFromFullSync}
                        onChange={() =>
                          dispatch({
                            type: "toggle_protected_from_full_sync",
                            payload: {
                              sourceStreamName: props.stream.name,
                            },
                          })
                        }
                      />
                    </div>
                  </Tooltip>
                </Switch.Group>
              </div>
            </Menu.Item>
          )}
        </TableOptionsMenu>
      )}
      <Modal
        isOpen={showIncrementalSetup}
        onClose={() => setShowIncrementalSetup(false)}
      >
        <ModalCloseButton />
        {showIncrementalSetup && (
          <IncrementalSetup
            stream={props.stream}
            sourceIntegration={props.sourceIntegration}
            schemaOptions={props.schemaOptions}
            onClose={() => setShowIncrementalSetup(false)}
            primaryKeys={props.primaryKeys}
            currentMode={mode === "APPEND" ? "APPEND" : "MERGE"}
          />
        )}
      </Modal>
    </div>
  );
};

const IncrementalSetup = (props: {
  stream: GetStreamsQuery["getStreams"][0];
  sourceIntegration: IntegrationType;
  schemaOptions:
    | SchemasQuery["getAllAvroSchemas"]["schemas"][0]["fields"]
    | undefined;
  currentMode?: "APPEND" | "MERGE";
  onClose: () => void;
  primaryKeys?: string[] | undefined;
}) => {
  const [state, dispatch] = useSourceStreamsState();
  const streamState = state[props.stream.name];

  const filteredSchemaOptions = useMemo(
    () =>
      props.schemaOptions?.filter(
        (option) =>
          !streamState?.excludedColumns.some((c) => c === option.name) &&
          !streamState?.hashedColumns.some((c) => c === option.name),
      ) ?? [],
    [
      props.schemaOptions,
      streamState?.excludedColumns,
      streamState?.hashedColumns,
    ],
  );

  const [cursor, setCursor] = useState<string | null>(() => {
    if (streamState?.incrementalPointerId) {
      return streamState.incrementalPointerId;
    }
    const incrementalConfig = resolveIncrementalConfig(
      props.sourceIntegration.id,
      {
        fields: props.schemaOptions ?? [],
        primaryKeys: props.primaryKeys,
      },
    );
    return incrementalConfig?.cursor ?? null;
  });

  const [primaryKeys, setPrimaryKeys] = useState<string[] | undefined>(() => {
    if (streamState?.primaryKeys && streamState.primaryKeys.length > 0) {
      return streamState.primaryKeys;
    }
    return props.primaryKeys;
  });

  const [mode, setMode] = useState<"APPEND" | "MERGE">(
    props.currentMode ?? "MERGE",
  );

  const createSelectOption = (value: string | null | undefined) =>
    value ? { value, label: value } : null;

  const submitIncremental = () => {
    if (mode === "MERGE") {
      if (!primaryKeys?.length || !cursor) return;
      dispatch({
        type: "toggle_incremental_sync",
        payload: {
          stream: props.stream,
          mode,
          primaryKeys: primaryKeys,
          cursor: cursor,
        },
      });
    } else {
      if (!cursor) return;
      dispatch({
        type: "toggle_incremental_sync",
        payload: {
          stream: props.stream,
          mode,
          cursor: cursor,
        },
      });
    }
    props.onClose();
  };

  const modeOptions = {
    MERGE: "MERGE (Recommended)",
    APPEND: "APPEND",
  };

  const submitDisabled = useMemo(() => {
    if (mode === "MERGE")
      return !cursor || !primaryKeys || primaryKeys.length === 0;
    if (mode === "APPEND") return !cursor;
  }, [cursor, mode, primaryKeys]);

  if (!streamState) return null;

  return (
    <>
      <ModalHeader>Setup incremental sync</ModalHeader>
      <ModalBody className="flex flex-col gap-6">
        <div>
          <label className="mb-1 block text-xs">Mode</label>
          <Select
            value={{ value: mode, label: modeOptions[mode] }}
            options={(["MERGE", "APPEND"] as ("MERGE" | "APPEND")[]).map(
              (value) => ({
                value,
                label: modeOptions[value],
              }),
            )}
            onChange={(item: { value: "MERGE" | "APPEND" }) => {
              setMode(item.value);
            }}
            isClearable={false}
          />
        </div>
        {mode === "MERGE" && (
          <div className="text-sm">
            Pick primary key and cursor field to enable incremental sync for{" "}
            {props.stream.name}. Only changed and new rows will be updated on
            subsequent syncs.
          </div>
        )}
        {mode === "APPEND" && (
          <div className="text-sm">
            Pick cursor field to enable incremental sync in APPEND mode for{" "}
            {props.stream.name}. APPEND mode will never update rows if they
            change, only add new rows.
          </div>
        )}
        {mode === "MERGE" && (
          <div>
            <label className="mb-1 block text-xs">Primary key</label>
            <Select
              placeholder="Select primary key..."
              isMulti
              value={primaryKeys?.map(createSelectOption)}
              options={filteredSchemaOptions
                .filter((o) => o.name !== "xmin")
                .map((option) => ({
                  value: option.name,
                  label: option.name,
                }))}
              onChange={(items: { value: string }[] | null) => {
                setPrimaryKeys(items?.map((option) => option.value));
              }}
            />
          </div>
        )}
        <div>
          <label className="mb-1 block text-xs">Cursor</label>
          {props.sourceIntegration?.id.includes("ftp") ? (
            //Hardcoded cursor value for FTP syncs
            <Select
              value={createSelectOption(cursor)}
              isClearable={false}
              autoSelectOnSingleOption
              options={[{ value: "_modified", label: "_modified" }]}
              onChange={(item: { value: string } | null) => {
                setCursor(item?.value ?? null);
              }}
            />
          ) : (
            <Select
              placeholder="Select one..."
              value={createSelectOption(cursor)}
              options={filteredSchemaOptions.map((option) => ({
                value: option.name,
                label: option.name,
              }))}
              onChange={(item: { value: string } | null) => {
                setCursor(item?.value ?? null);
              }}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          onClick={() => submitIncremental()}
          disabled={submitDisabled}
        >
          Enable incremental sync
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onClose()}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </>
  );
};

const ConnectingLine = (props: { isParent?: boolean }) => (
  <div
    className="absolute w-auto"
    style={{
      top: "18px",
      left: -6,
      height: "calc(100% - 35px)",
    }} // Fine tune wrapper to the center-left of checkboxes
  >
    <div
      className="absolute border-b border-gray-200 dark:border-gray-500"
      style={{ width: "8px" }}
    />
    {props.isParent && (
      <div className="absolute h-full border-l border-gray-200 dark:border-gray-500" />
    )}
  </div>
);

type SubstreamAvroSchema = {
  name: string;
  type: string;
  fields: { name: string; type: string }[];
};

const SubStreamItem = (props: {
  substream: GetStreamsQuery["getStreams"][0]["substreams"][0];
  isLast: boolean;
  search: string;
  stream: GetStreamsQuery["getStreams"][0];
  reqired?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useSourceStreamsState();

  const streamState = state[props.stream.name];
  const substreamState =
    streamState?.substreams[props.substream.destinationTableName];

  //const checked = !!substreamState; TODO: comment in when backend is fixed
  const checked = !!streamState || !!props.reqired; // TODO: remove when backend fixed

  useEffect(() => {
    if (!checked) {
      setOpen(false);
    }
  }, [checked]);

  /*const onToggle = () =>
    dispatch({
      type: "toggle_substream",
      payload: {
        sourceStreamName: props.stream.name,
        substreamName: props.substream.destinationTableName,
      },
    });*/
  return (
    <div className="relative">
      <ConnectingLine />
      <div className="group flex items-center gap-4 px-4 py-2">
        <Checkbox
          checked={checked}
          disabled={true} // TODO: remove when backend fixed
          onChange={() => {
            //onToggle();
          }}
        />
        <div className="flex items-center gap-2">
          {checked && (
            <OpenButton
              open={open}
              disabled={true} // TODO: remove when backend fixed
              checked={checked}
              //disabled={!checked} // TODO: comment in when backend fixed
              onToggle={() => setOpen((prev) => !prev)}
            />
          )}

          <HiglightedText
            highlighted={props.search}
            text={props.substream.destinationTableName}
          />
        </div>
      </div>
      {open && (
        <div className="space-y-1 py-1 pl-2">
          {(props.substream.avroSchema as SubstreamAvroSchema).fields?.map(
            (field) => (
              <SchemaOption
                key={field.name}
                title={field.name}
                isCursor={false}
                isPrimaryKey={false}
                checked={!substreamState?.excludedColumns.includes(field.name)}
                onChangeHashed={(hashed) => {
                  dispatch({
                    type: "set_substream_hashed_column",
                    payload: {
                      columnName: field.name,
                      sourceStreamName: props.stream.name,
                      substreamName: props.substream.destinationTableName,
                      hashed,
                    },
                  });
                }}
                hashed={!!substreamState?.hashedColumns.includes(field.name)}
                onChange={() =>
                  dispatch({
                    type: "toggle_substream_excluded_column",
                    payload: {
                      columnName: field.name,
                      sourceStreamName: props.stream.name,
                      substreamName: props.substream.destinationTableName,
                    },
                  })
                }
              />
            ),
          )}
        </div>
      )}
    </div>
  );
};

const OpenButton = (props: {
  open: boolean;
  disabled: boolean;
  onToggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  checked: boolean;
}) => {
  return (
    <IconButton
      type="button"
      variant="ghost"
      size="xs"
      disabled={props.disabled}
      onClick={(e) => !props.disabled && props.onToggle(e)}
      icon={
        <ChevronRightIcon
          className={classNames(
            "w-4 transition-transform",
            props.open && !props.disabled && "rotate-90",
            !props.checked && "invisible",
          )}
        />
      }
    />
  );
};

const StreamSchemaOption = (props: {
  option: AvroSchemaFieldDto;
  stream: GetStreamsQuery["getStreams"][0];
  isPrimaryKey: boolean;
  required?: boolean;
}) => {
  const [state, dispatch] = useSourceStreamsState();

  const streamState = state[props.stream.name];
  const checked =
    (!!streamState &&
      !streamState?.excludedColumns.some((c) => c === props.option.name)) ||
    !!props.required;

  const hashed = !!streamState?.hashedColumns.some(
    (c) => c === props.option.name,
  );

  const isPrimaryKey = useMemo(() => {
    if (!props.stream.configurableIncremental && props.isPrimaryKey)
      return true;
    if (!streamState) return false;
    return streamState.primaryKeys.includes(props.option.name);
  }, [
    props.isPrimaryKey,
    props.option.name,
    props.stream.configurableIncremental,
    streamState,
  ]);

  return (
    <SchemaOption
      title={props.option.name}
      checked={checked}
      hashed={hashed}
      isCursor={streamState?.incrementalPointerId === props.option.name}
      isPrimaryKey={isPrimaryKey}
      onChangeHashed={(hashed) => {
        dispatch({
          type: "change_hashed_column",
          payload: {
            columnName: props.option.name,
            sourceStreamName: props.stream.name,
            hashed,
          },
        });
      }}
      onChange={(checked) => {
        dispatch({
          type: "change_excluded_column",
          payload: {
            checked,
            columnName: props.option.name,
            sourceStreamName: props.stream.name,
          },
        });
      }}
      readOnly={!streamState}
      required={props.required}
    />
  );
};

const SchemaOption = (props: {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onChangeHashed: (hashed: boolean) => void;
  title: string;
  hashed: boolean;
  isPrimaryKey: boolean;
  isCursor: boolean;
  readOnly?: boolean;
  required?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "group flex items-center overflow-hidden px-4 py-1",
        !props.readOnly && "hover:bg-gray-100 dark:hover:bg-gray-700",
        props.readOnly && "opacity-50",
      )}
      onClick={() => {
        if (!props.readOnly) {
          props.onChange(!props.checked);
        }
      }}
    >
      <div
        className={classNames(
          "flex w-full min-w-0 grow items-center space-x-2",
          props.readOnly ? "cursor-default" : "cursor-auto",
        )}
      >
        <Checkbox
          checked={props.checked}
          disabled={props.isCursor || props.isPrimaryKey}
          readOnly={props.readOnly}
          onChange={noop}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
        <div className="truncate" title={props.title}>
          {props.title}
        </div>
        {(props.isPrimaryKey || props.isCursor) && (
          <div className="whitespace-nowrap rounded-sm bg-primary px-1 text-xs text-white">
            {props.isCursor ? "Cursor" : "Primary key"}
          </div>
        )}
      </div>
      {props.required && (
        <div className="rounded-sm bg-primary px-1 text-1xs text-white">
          Required
        </div>
      )}
      <HashButton
        hashed={props.hashed}
        onChange={props.onChangeHashed}
        disabled={!props.checked || props.isCursor || props.isPrimaryKey}
      />
    </div>
  );
};

const HashButton = (props: {
  hashed: boolean;
  onChange: (hashed: boolean) => void;
  disabled: boolean;
}) => {
  return (
    <Menu
      as="div"
      className="inline-block text-left"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu.Button
        as={Button}
        size="xs"
        iconRight={<ChevronDownIcon className="w-4" />}
        disabled={props.disabled}
        type="button"
        className={classNames({
          "invisible group-hover:visible": !props.hashed,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.hashed ? "Hashed" : "Unhashed"}
      </Menu.Button>
      <Menu.Items
        onClick={(e) => e.stopPropagation()}
        className="absolute right-0 z-50 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none dark:bg-gray-900"
      >
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "opacity-75" : ""
              } group flex w-full flex-col space-y-2 px-2 py-2 text-xs dark:text-white ${
                !props.hashed ? "bg-primary text-white" : ""
              }`}
              onClick={() => props.onChange(false)}
            >
              <span className="text-xs font-semibold">Unhashed</span>
              <span className="w-full text-left">
                Values in this column will be unchanged when synced
              </span>
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "opacity-75" : ""
              } group flex w-full flex-col space-y-2 px-2 py-2 text-xs dark:text-white ${
                props.hashed ? "bg-primary text-white" : ""
              }`}
              onClick={() => props.onChange(true)}
            >
              <span className="text-xs font-semibold">Hashed</span>
              <span className="w-full text-left">
                Values in this column will be hashed before they are synced
              </span>
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};
