import classNames from "@/helpers/classNames";
import { Column, useTable } from "react-table";

function DataSchemaTable<T extends object = any>(props: {
  columns: Column<T>[];
  data: any;
  onRowClick?: (id: string) => void;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: props.columns,
      data: props.data,
    });

  return (
    <div className="relative w-full overflow-x-hidden rounded-sm border border-gray-200 dark:border-gray-700">
      <table
        {...getTableProps()}
        className="w-full table-auto divide-y divide-gray-200 dark:divide-gray-700"
      >
        <thead className="bg-gray-100 dark:bg-gray-900">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="sticky top-0 bg-white dark:bg-gray-700"
            >
              {headerGroup.headers.map((column) => (
                <th
                  scope="col"
                  className="px-2 py-1 text-left text-xs font-medium tracking-wider text-gray-500 dark:text-gray-400"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="max-h-96 divide-y divide-gray-200 overflow-y-auto break-all bg-white dark:divide-gray-700 dark:bg-gray-800"
        >
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                onClick={() =>
                  props.onRowClick && props.onRowClick(props.data[i].id)
                }
                className={classNames(
                  props.onRowClick && "cursor-pointer hover:bg-gray-100",
                )}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td className="px-2 py-1 text-xs" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataSchemaTable;
