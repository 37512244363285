import dayjs from "dayjs";
import React, { useMemo } from "react";
import { LinkWithSlug } from "routes";

import {
  ListModelsQuery,
  OrchestrationSchedulerType,
  OrchestrationWorkflowQuery,
  useGetLocalMaterializationJobQuery,
  useOrchestrationWorkflowQuery,
  useRematerializeNowMutation,
} from "@/apollo/types";
import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { SecondaryButton } from "@/components/elements/Button";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { LoadingFull } from "@/components/elements/LoadingComponents";
import Tooltip from "@/components/elements/Tooltip";
import { OrchestrationTypeIcon } from "@/components/icons/OrchestrateIcon";
import classNames from "@/helpers/classNames";
import { dateTimeFormat } from "@/helpers/dateFormat";
import { useToast } from "@/providers/ToastProvider";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import EditModelMaterializationButton from "./EditModelMaterialization";
import UnpublishModelButton from "./UnpublishModelButton";

export const TableMaterilizationInfo = (props: {
  modelId: string;
  workflowId?: string;
  model: ListModelsQuery["models"][0];
  tableReference?: ListModelsQuery["models"][0]["dwTable"];
}) => {
  const toast = useToast();

  const [reMaterializeTableNow, { loading: loadingRecreate }] =
    useRematerializeNowMutation({
      variables: {
        modelId: props.modelId,
      },
      onError(error) {
        toast(`Error recreating materialization`, error.message, "error");
      },
      onCompleted() {
        toast(
          `Recreated materialization`,
          `The materialization was recreated.`,
          "success",
        );
      },
    });

  const canRecreateTable =
    props.tableReference?.status && props.tableReference?.status !== "RUNNING";

  const { workflowJobItem, workflow } = useWorkflowModelJob(
    props.modelId,
    props.workflowId,
    props.model.orchestrationScheduler !== OrchestrationSchedulerType.Global,
  );

  const materializationErrorMessage = useMemo(() => {
    return (
      props.tableReference?.latestRunErrorMessage ||
      props.model.localMaterializationJob?.latestErrorMessage ||
      workflowJobItem?.errorMessage
    );
  }, [
    workflowJobItem?.errorMessage,
    props.model.localMaterializationJob?.latestErrorMessage,
    props.tableReference?.latestRunErrorMessage,
  ]);

  return (
    <div className="relative mx-auto max-w-5xl space-y-6 p-8">
      <>
        <div className="flex items-center space-x-2">
          <div className="text-md dark:text-white">Materialized as table</div>
          <MaterializeJobStatus
            status={
              props.tableReference?.status ??
              props.model.localMaterializationJob?.status ??
              ""
            }
          />
        </div>

        <div className="flex flex-wrap items-center">
          <OrchestrationSchedulerTypeInfo
            orchestrationScheduler={props.model.orchestrationScheduler}
            workflow={workflow}
          />

          {materializationErrorMessage && (
            <InfoItem
              label="Error"
              info={
                <div className="text-sm">{materializationErrorMessage}</div>
              }
            />
          )}

          {props.model?.orchestrationScheduler ===
            OrchestrationSchedulerType.Local && (
            <LocalScheduleInfo modelId={props.model.id} />
          )}

          <InfoItem
            label="Last created"
            info={
              props.tableReference?.latestSyncTime ? (
                <FormattedDateString
                  value={props.tableReference.latestSyncTime}
                />
              ) : (
                "Table not created yet."
              )
            }
          />

          {props.model?.orchestrationScheduler ===
            OrchestrationSchedulerType.Global && (
            <WorkflowInfo modelId={props.model.id} workflow={workflow} />
          )}
        </div>

        <EditModelMaterializationButton />
        <div className="flex items-center space-x-2">
          <SecondaryButton
            className="w-44"
            onClick={() => {
              if (!loadingRecreate && canRecreateTable) reMaterializeTableNow();
            }}
            isDisabled={!canRecreateTable}
            isLoading={loadingRecreate}
            loadingText="Recreating..."
          >
            Recreate table now
          </SecondaryButton>
          <Tooltip content="Will request the table to be recreated now, and then return to the scheduled execution.">
            <div>
              <InformationCircleIcon className="h-5 w-5 dark:text-white" />
            </div>
          </Tooltip>
        </div>
        <UnpublishModelButton
          modelId={props.modelId}
          materializationType={props.model.materializationType}
        />
      </>
    </div>
  );
};

const LocalScheduleInfo = (props: { modelId: string }) => {
  const { localMaterializationJob, loading: materitalizationJobLoading } =
    useMaterializationJobPolling(props.modelId);

  if (materitalizationJobLoading) return <LoadingFull />;

  if (!localMaterializationJob) return null;

  return (
    <>
      <InfoItem
        label="Will recreate"
        info={
          <div>
            <CronDisplayReadable
              cron={localMaterializationJob.cronExpression}
            />
          </div>
        }
      />
      <InfoItem
        label="Next run"
        info={<FormattedDateString value={localMaterializationJob.nextRun} />}
      />
    </>
  );
};

const WorkflowInfo = (props: {
  modelId: string;
  workflow: OrchestrationWorkflowQuery["orchestrationWorkflow"];
}) => {
  if (!props.workflow) return <WorkspaceOrchestrationMissingWarning />;

  return (
    <>
      <InfoItem
        label="Will recreate"
        info={
          <div>
            <CronDisplayReadable cron={props.workflow.cronExpression} />
          </div>
        }
      />
      {props.workflow.nextRun && (
        <InfoItem
          label="Next run"
          info={<FormattedDateString value={props.workflow.nextRun.dueAt} />}
        />
      )}
    </>
  );
};

export const InfoItem: React.FC<{ label: string; info: React.ReactNode }> = (
  props,
) => {
  return (
    <div className="flex w-full flex-none pb-6 text-sm dark:text-white lg:w-1/2">
      <div className="w-48 flex-none text-gray-500">{props.label}</div>
      <div>{props.info}</div>
    </div>
  );
};

const OrchestrationSchedulerTypeInfo = (props: {
  orchestrationScheduler?: OrchestrationSchedulerType | null;
  workflow?: OrchestrationWorkflowQuery["orchestrationWorkflow"];
}) => {
  return (
    <InfoItem
      label="Schedule"
      info={
        props.orchestrationScheduler ? (
          <div className={classNames("flex items-center space-x-2")}>
            <OrchestrationTypeIcon
              schedulerType={props.orchestrationScheduler}
              className="h-4 w-4"
            />
            <span className="">
              {props.orchestrationScheduler === OrchestrationSchedulerType.Local
                ? "Independent"
                : props.workflow?.name ?? "Missing orhcestration"}
            </span>
            {props.orchestrationScheduler ===
              OrchestrationSchedulerType.Global &&
              props.workflow && (
                <>
                  <LinkWithSlug
                    className="underline"
                    to={`/orchestration/${props.workflow.id}`}
                  >
                    view orchestration
                  </LinkWithSlug>
                </>
              )}
          </div>
        ) : (
          "No scheduled recreation"
        )
      }
    />
  );
};

export const FormattedDateString: React.FC<{ value: number | string }> = ({
  value,
}) => {
  const dateString = parseToDateString(value);
  return <span>{dateString}</span>;
};

export const HumanizedTimeDiff: React.FC<{ value: number | string }> = (
  props,
) => {
  const nextRun = dayjs(props.value);
  const now = dayjs();
  if (!nextRun.isValid()) return <span>{"Invalid timestamp"}</span>;
  const humanizedDiff = dayjs.duration(nextRun.diff(now)).humanize(true);
  return <span>{humanizedDiff}</span>;
};

const parseToDateString = (value: number | string) => {
  const date = dayjs(value);
  if (!date.isValid()) return "Invalid timestamp";
  return date.format(dateTimeFormat);
};

//'RUNNING' | 'SUCCEDED' | 'FAILED' | 'PENDING'
const badgeVariants: Record<string, BadgeVariant> = {
  SUCCEEDED: BadgeVariant.Success,
  FAILED: BadgeVariant.Error,
  RUNNING: BadgeVariant.Info,
  PENDING: BadgeVariant.Info,
};

export const MaterializeJobStatus = (props: { status: string }) => {
  return <Badge variant={badgeVariants[props.status]}>{props.status}</Badge>;
};

const useMaterializationJobPolling = (
  modelId?: string,
  skip: boolean = false,
) => {
  const { data: getLocalMaterializationJobQuery, loading } =
    useGetLocalMaterializationJobQuery({
      variables: { modelId: modelId ?? "" },
      pollInterval: 20000,
      skip: !modelId || skip,
    });
  return {
    localMaterializationJob:
      getLocalMaterializationJobQuery?.model?.localMaterializationJob,
    loading,
  };
};

const useWorkflowModelJob = (
  modelId: string,
  workflowId?: string,
  skip?: boolean,
) => {
  const { data } = useOrchestrationWorkflowQuery({
    variables: {
      workflowId: workflowId ?? "",
    },
    skip: skip || !workflowId,
  });

  const workflowJobItem = useMemo(() => {
    return data?.orchestrationWorkflow?.currentRun?.jobs.find(
      (j) =>
        j.target?.__typename === "MaterializedTableReference" &&
        j.target.modelId === modelId,
    );
  }, [data?.orchestrationWorkflow?.currentRun?.jobs, modelId]);

  return {
    workflowJobItem,
    workflow: data?.orchestrationWorkflow,
  };
};

export const WorkspaceOrchestrationMissingWarning = () => {
  return (
    <div className="w-full rounded border border-danger-light bg-white px-4 py-2 text-sm dark:border-danger dark:bg-gray-800">
      <div className="flex items-center space-x-2">
        <InformationCircleIcon className="h-6 w-6 text-danger-light" />
        <span>
          Model not configured to be part of an Orchestration. Models will not
          update continuously until it is enabled.{" "}
          <LinkWithSlug className="underline" to={"/orchestration"}>
            Set up now
          </LinkWithSlug>
        </span>
      </div>
    </div>
  );
};
