import DateInput from "@/components/elements/DateInput";

export default function StartDateConfig(props: {
  value?: string | Date | null;
  onChange: (date: Date | null) => void;
}) {
  return (
    <div className=" space-y-4">
      <div className="dark:white text-sm">Limit historical data</div>

      <div>
        <p className="mb-2 text-xs text-gray-700 dark:text-gray-500">
          Only sync data after the selected date
        </p>
        <DateInput
          date={
            typeof props.value === "string"
              ? new Date(props.value)
              : props.value || null
          }
          setDate={(date) => props.onChange(date)}
          placeholder="Select date..."
        />
      </div>
    </div>
  );
}
