import { Suspense, lazy, useCallback, useEffect, useMemo } from "react";

import { SubscriptionStatus } from "@/apollo/types";
import { Heading, TextMuted } from "@/components/elements/Typography";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import {
  AiAssistantModelContextProvider,
  ChatView,
  EdIcon,
} from "@/features/ai-assistant";
import { useSubscriptionStatus, useWeldPlan } from "@/features/subscription";
import cn from "@/helpers/classNames";
import {
  useGetEditorTextState,
  useModelEditorState,
} from "@/pages/ModelTool/ModelEditorStore";
import { datadogRum } from "@datadog/browser-rum";
import { ErrorBoundary } from "@sentry/react";

import { useQueryDependencies } from "../../useQueryDependencies";
import { useAiAssistantSidebarContext } from "./AiAssistantSidebarProvider";
import { useAssistantSidebar } from "./useAssistantSidebar";

const AiAssistantContainer = lazy(
  () => import("features/ai-assistant/AiAssistantContainer"),
);

export function AiAssistantSidebar(
  props: { isMobileSizeViewport: boolean } & ReturnType<
    typeof useAssistantSidebar
  >,
) {
  const { onClose, isOpen, onOpen, props: assistantProps } = props;

  const { features } = useWeldPlan();
  const { status, trial } = useSubscriptionStatus();
  const isAssistantEnabled =
    status === SubscriptionStatus.Active ||
    (status === SubscriptionStatus.Freemium && trial?.isActive === true);

  useEffect(() => {
    if (!isOpen) return;
    // Start a session replay recording when ai-assistant is opened
    // Add a "AI opened" action to the recording to make it easier to find in datadog
    datadogRum.addAction("AI opened");
    datadogRum.startSessionReplayRecording();
  }, [isOpen]);

  const modelState = useModelEditorState();
  const getTextState = useGetEditorTextState();
  const modelDependencyReferences = useQueryDependencies();

  const modelContextValue = useMemo(() => {
    if (!modelState.currentModelId) {
      return null;
    }
    return {
      id: modelState.currentModelId,
      name: modelState.modelName,
      type: modelState.currentModelType,
      getTextValue: () => getTextState().weldSql,
      dependencies: modelDependencyReferences,
    };
  }, [modelState, getTextState, modelDependencyReferences]);

  const { readInitOptionsOnce } = useAiAssistantSidebarContext();

  const onChangeThread = useCallback(
    (threadId: string | undefined) => {
      onOpen({
        threadId: features.aiAssistantHistoryEnabled ? threadId : undefined,
      });
    },
    [features.aiAssistantHistoryEnabled, onOpen],
  );

  const onChangeView = useCallback(
    (view: ChatView) => {
      onOpen({
        view,
        threadId: assistantProps.threadId,
      });
    },
    [onOpen, assistantProps.threadId],
  );

  return (
    <div className="relative flex h-full flex-col overflow-hidden bg-white dark:bg-gray-800">
      {!isAssistantEnabled && <AIAssistantTeaser />}
      <ErrorBoundary fallback={<div>Error loading AiAssistantSidebar</div>}>
        <Suspense fallback={<div>Loading AiAssistantSidebar...</div>}>
          {isOpen && isAssistantEnabled && (
            <AiAssistantModelContextProvider model={modelContextValue}>
              <AiAssistantContainer
                {...assistantProps}
                getInitOptions={readInitOptionsOnce}
                onClose={onClose}
                onChangeThread={onChangeThread}
                onChangeView={onChangeView}
                enableHistory={features.aiAssistantHistoryEnabled}
              />
            </AiAssistantModelContextProvider>
          )}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

function AIAssistantTeaser(props: React.ComponentProps<"div">) {
  return (
    <div {...props} className={cn("flex flex-col gap-4 p-6", props.className)}>
      <Heading className="flex items-center gap-3 text-xl">
        <EdIcon className="h-6 w-6" /> Meet Ed, our AI Assistant
      </Heading>
      <TextMuted className="text-sm leading-snug">
        Our AI Assistant, Ed, is here to help you with your data modeling needs.
        Ed can help you create new models, answer questions about your data, fix
        issues, and more.
      </TextMuted>
      <div className="flex w-full flex-col gap-4 text-center">
        <span>Access to Ed is available on all paid plans.</span>
        <div>
          <UpgradeNowButton source="feature guard - ai assistant" />
        </div>
      </div>
    </div>
  );
}
