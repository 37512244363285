import {
  EltSyncDocument,
  GetRawViewsDocument,
  useDeleteRawViewMutation,
  useRecreateViewMutation,
  useRemoveTableFromSyncMutation,
} from "@/apollo/types";
import confirm from "@/components/elements/Confirm";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import MenuItem from "@/components/elements/MenuItem";
import TableMenu, { MenuButton } from "@/components/modules/TableMenu";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import { useInserInEditor } from "@/hooks/useInsertInEditor";
import { useMixpanel } from "@/monitoring/mixpanel";

import { useQueryRawData } from "@/pages/ModelTool/hooks/useRawDataActions";
import { useToast } from "@/providers/ToastProvider";
import { useOpenLineageModal } from "../../lineage/RawDataLineageModal";
import { EltStreamItemType, ViewItemType } from "../SidebarFactory";

type RawDataOptionsProps = {
  item: ViewItemType;
};

const RecreateViewMenuItem = (props: {
  syncId: string;
  sourceStreamName: string;
}) => {
  const [recreateRawView] = useRecreateViewMutation({
    variables: {
      eltSyncId: props.syncId,
      sourceStreamName: props.sourceStreamName,
    },
  });

  const toast = useToast();

  return (
    <MenuItem
      text="Recreate raw view"
      size="sm"
      onClick={async () => {
        const result = await recreateRawView();
        if (result.errors && result.errors.length > 0) {
          toast("Error recreating raw view", result.errors[0].message, "error");
        } else {
          toast(
            "Raw view recreated",
            "The raw view has been recreated.",
            "success",
          );
        }
      }}
    />
  );
};

export const RawDataOptions = ({ item }: RawDataOptionsProps) => {
  const mixpanel = useMixpanel();

  const toast = useToast();

  const [deleteRawView, deleteRawViewMutation] = useDeleteRawViewMutation({
    variables: { viewId: item.rawView.viewId },
    refetchQueries: [GetRawViewsDocument],
    onCompleted: () => {
      toast(
        "Table removed",
        "The data reference was successfully removed.",
        "success",
      );
    },
    onError: (e) => {
      toast("Error removing table from sync", e.message, "error");
    },
  });

  const [removeTable, removeTableMutation] = useRemoveTableFromSyncMutation({
    refetchQueries: [
      {
        query: EltSyncDocument,
        variables: { eltSyncId: item.rawView.syncId },
      },
      GetRawViewsDocument,
    ],
    onCompleted: () => {
      toast(
        "Table removed",
        "Table was successfully removed from the data sync.",
        "success",
      );
    },
    onError: (e) => {
      toast("Error removing table from sync", e.message, "error");
    },
  });

  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const { inserToEditor, editorOpen } = useInserInEditor();

  const setShowLineageModal = useOpenLineageModal();

  const queryRawData = useQueryRawData();
  //Mark as a stonly element if this is from a synced connector.
  const markForStonly = !!item.rawView && !!item.rawView.syncId;

  if (deleteRawViewMutation.loading || removeTableMutation.loading)
    return <LoadingSpinner className="h-4 w-4" />;
  return (
    <>
      <TableMenu
        size="sm"
        button={
          <MenuButton
            data-stonly={markForStonly ? "raw-table-options" : undefined}
          />
        }
      >
        <MenuItem
          text="Query data"
          size="sm"
          data-stonly={markForStonly ? "query-data" : undefined}
          onClick={() => {
            queryRawData(item.rawView);
          }}
        />

        {!item.isViewImported && (
          <MenuItem
            text="View data source"
            size="sm"
            onClick={() => {
              if (!item.rawView.syncId) return;
              mixpanel.track("View Data Source Sync Clicked", {
                source: "editor sidebar",
              });
              viewSync({
                syncId: item.rawView.syncId,
              });
            }}
          />
        )}
        {item.rawView.viewId && (
          <MenuItem
            text="Open data lineage"
            size="sm"
            onClick={() => {
              setShowLineageModal({
                focusedId: item.rawView.viewId,
                title: `${item.rawView.schema} / ${item.rawView.sourceStream}`,
              });
            }}
          />
        )}
        {item.rawView.syncId && (
          <RecreateViewMenuItem
            syncId={item.rawView.syncId}
            sourceStreamName={item.rawView.sourceStream}
          ></RecreateViewMenuItem>
        )}
        {editorOpen && (
          <MenuItem
            text="Insert reference in editor"
            size="sm"
            onClick={() => {
              inserToEditor(`{{${item.rawView.weldTag}}}`);
            }}
          />
        )}

        {!item.rawView.syncId && (
          <MenuItem
            text="Delete reference"
            size="sm"
            onClick={async () => {
              if (deleteRawViewMutation.loading) return;
              const confirmed = await confirm({
                type: "danger",
                title: "Delete reference",
                message:
                  "Are you sure you want to delete the reference to this raw data? This might affect models using this data.",
              });
              if (!confirmed) return;
              deleteRawView();
            }}
          />
        )}

        {item.rawView.syncId && (
          <MenuItem
            text="Delete table"
            size="sm"
            onClick={async () => {
              if (removeTableMutation.loading || !item.rawView.syncId) return;
              const confirmed = await confirm({
                type: "danger",
                title: "Delete table",
                message:
                  "Are you sure you want to delete the table? This might affect models using this data.",
              });
              if (!confirmed) return;
              removeTable({
                variables: {
                  sourceStreamName: item.rawView.sourceStream,
                  syncId: item.rawView.syncId,
                  viewId: item.rawView.viewId,
                },
              });
            }}
          />
        )}
      </TableMenu>
    </>
  );
};

export const ELTStreamOptions = (props: { item: EltStreamItemType }) => {
  const mixpanel = useMixpanel();

  const toast = useToast();

  const [removeTable, removeTableMutation] = useRemoveTableFromSyncMutation({
    refetchQueries: [
      {
        query: EltSyncDocument,
        variables: { eltSyncId: props.item.sync.id },
      },
      GetRawViewsDocument,
    ],
    onCompleted: () => {
      toast(
        "Table removed",
        "Table was successfully removed from the data sync.",
        "success",
      );
    },
    onError: (e) => {
      toast("Error removing table from sync", e.message, "error");
    },
  });
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  if (removeTableMutation.loading)
    return <LoadingSpinner className="h-4 w-4" />;

  return (
    <>
      <TableMenu size="sm">
        <MenuItem
          text="View sync"
          size="sm"
          onClick={() => {
            mixpanel.track("View Data Source Sync Clicked", {
              source: "editor sidebar",
            });
            viewSync({
              syncId: props.item.sync.id,
            });
          }}
        />

        {props.item.sync.id && (
          <RecreateViewMenuItem
            syncId={props.item.sync.id}
            sourceStreamName={props.item.streamName}
          ></RecreateViewMenuItem>
        )}

        <MenuItem
          text="Delete table"
          size="sm"
          onClick={async () => {
            if (removeTableMutation.loading) return;

            const confirmed = await confirm({
              type: "info",
              title: "Delete table from sync",
              message:
                "This will prevent the table from being created in future syncs.",
            });
            if (!confirmed) return;

            removeTable({
              variables: {
                sourceStreamName: props.item.streamName,
                syncId: props.item.sync.id,
              },
            });
          }}
        />
      </TableMenu>
    </>
  );
};
