import { useMonaco } from "@monaco-editor/react";
import { Outlet } from "@tanstack/react-location";
import Center from "@/components/elements/layout/Center";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Sidebar, SidebarProvider } from "@/components/elements/Sidebar";
import { WorkspaceBanners } from "@/components/modules/AppBanners";
import { useDashboardKBarActions } from "@/components/modules/KBar/actions/useDashboardKBarActions";
import MobileHeader from "@/components/modules/MobileHeader";
import { EditorTabs } from "@/components/modules/ModelTabs";
import {
  WorkspaceSidebar,
  WorkspaceSidebarContent,
} from "@/components/modules/sidebar/WorkspaceSidebar";
import {
  FirebaseAuthWrapper,
  WeldFirebaseAppProvider,
} from "@/features/firebase";
import { ActiveMigrationOverlay } from "@/features/migration/ActiveMigrationOverlay";
import SimplifiedRevELTSidebar from "@/features/reverse-elt/SimplifiedRevELTSidebar";
import { useIsMobileSizeViewport } from "@/hooks/useIsMobileSizeViewport";
import { ModelEditorStoreProvider } from "@/pages/ModelTool/ModelEditorStore";
import {
  ReflexContainer,
  ReflexElement,
  ReflexHandle,
  ReflexSplitter,
} from "react-reflex";
import useLocalStorageState from "use-local-storage-state";

import { useFocusMode } from "./hooks/useFocusMode";
import { useKeyboardShortcuts } from "./hooks/useKeyboardShortcuts";
import { DataLineageModal } from "./lineage/RawDataLineageModal";
import {
  AiAssistantSidebar,
  useAssistantSidebar,
} from "./model-generator/assistant";
import { PreviewCompiledSqlModal } from "./Preview/PreivewCompiledSqlModal";
import SchemaSidebar from "./SchemaSidebar";
import ModelSidebar from "./Sidebar/ModelSidebar";
import { createModelToolStorageKey } from "./utils/createLocalStorageKey";

function ModelEditorApp() {
  // This loads and initializes Monaco, fetches JS bundles and language support
  // `monaco` will be `null` initially until the load is complete. We'll use this as a loading indicator.
  const monaco = useMonaco();

  const [isFocusMode] = useFocusMode();

  useKeyboardShortcuts();
  useDashboardKBarActions();

  const [sidebarSize, setSidebarSize] = useLocalStorageState(
    createModelToolStorageKey("sidebar-size"),
    { defaultValue: 256 },
  );

  const isMobileSizeViewport = useIsMobileSizeViewport();
  const assistantSidebar = useAssistantSidebar();

  const renderEditorContent = () => {
    return (
      <div className="relative flex h-full w-full overflow-hidden">
        <ReflexContainer orientation="vertical" windowResizeAware>
          <ReflexElement>
            <div className="flex h-full w-full">
              <div className="relative flex h-full flex-grow flex-col overflow-hidden">
                <EditorTabs />
                <main className="w-full flex-grow overflow-hidden">
                  {monaco == null ? (
                    <Center className="h-full w-full">
                      <LoadingSpinner />
                    </Center>
                  ) : (
                    /*outlet produces: EditorWithBar.tsx or ModelEditorEmptyState.tsx or visualize */
                    <Outlet />
                  )}
                </main>
              </div>
              <SchemaSidebar isMobileSizeViewport={isMobileSizeViewport} />
            </div>
          </ReflexElement>
          <ReflexSplitter style={{ border: 0, width: 0 }} />
          {assistantSidebar.isOpen && (
            <ReflexElement minSize={384} flex={2 / 5}>
              <AiAssistantSidebar
                isMobileSizeViewport={isMobileSizeViewport}
                {...assistantSidebar}
              />
              <ReflexHandle className="absolute left-0 top-0 h-full w-1.5 border-l border-gray-200 hover:border-gray-400 dark:border-gray-700 dark:hover:border-gray-500" />
            </ReflexElement>
          )}
        </ReflexContainer>
        <DataLineageModal />
      </div>
    );
  };

  return (
    <div className="absolute inset-0 flex flex-col">
      <WorkspaceBanners />
      <ActiveMigrationOverlay />
      <div
        id="editor-pane"
        className="flex h-full w-full flex-col overflow-hidden bg-white dark:bg-gray-800"
      >
        {isMobileSizeViewport ? (
          <SidebarProvider
            isCollapsed
            breakpoint={Number.MAX_SAFE_INTEGER}
            collapsedWidth={350}
            width={350}
          >
            <MobileHeader />
            <div className="flex h-full grow overflow-hidden">
              <EditorMobileSidebar />
              {renderEditorContent()}
            </div>
          </SidebarProvider>
        ) : (
          <div className="flex h-full w-full">
            <WorkspaceSidebar defaultCollapsed />
            <ReflexContainer orientation="vertical" windowResizeAware>
              {!isFocusMode && (
                <ReflexElement
                  size={sidebarSize}
                  minSize={128}
                  onStopResize={(e) =>
                    setSidebarSize((e.domElement as HTMLDivElement).offsetWidth)
                  }
                >
                  <ModelSidebar />
                </ReflexElement>
              )}
              <ReflexSplitter style={{ border: 0, width: 0 }} />
              <ReflexElement minSize={128}>
                {renderEditorContent()}
                <ReflexHandle className="absolute left-0 top-0 h-full w-1.5 border-l border-gray-200 hover:border-gray-400 dark:border-gray-700 dark:hover:border-gray-500" />
              </ReflexElement>
            </ReflexContainer>
          </div>
        )}
      </div>
      <PreviewCompiledSqlModal />

      <SimplifiedRevELTSidebar />
    </div>
  );
}

export default function ModelEditor() {
  return (
    <WeldFirebaseAppProvider>
      <FirebaseAuthWrapper
        loadingElement={
          <Center className="absolute inset-0 h-full w-full">
            <LoadingSpinner />
          </Center>
        }
      >
        <ModelEditorStoreProvider>
          <ModelEditorApp />
        </ModelEditorStoreProvider>
      </FirebaseAuthWrapper>
    </WeldFirebaseAppProvider>
  );
}

function EditorMobileSidebar() {
  return (
    <Sidebar>
      <div className="flex h-full w-full border-r border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-800">
        <div
          style={{ width: 52 }}
          className="border-r border-gray-200 p-2 dark:border-gray-700"
        >
          <WorkspaceSidebarContent />
        </div>

        <div className="grow">
          <ModelSidebar />
        </div>
      </div>
    </Sidebar>
  );
}
