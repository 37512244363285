import { Cog6ToothIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useMatch } from "@tanstack/react-location";
import { IconButton } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { BetaFeatureText } from "@/components/elements/FeatureStateText";
import Tooltip from "@/components/elements/Tooltip";
import DashboardIcon from "@/components/icons/DashboardIcon";
import { useCurrentModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useGetQueryDependencies } from "@/pages/ModelTool/useQueryDependencies";
import { useEffect, useMemo, useRef, useState } from "react";
import { LocationGenerics } from "routes";

import {
  useGetEditorTextState,
  useModelEditorState,
} from "../../ModelEditorStore";
import { usePreviewsAtom } from "../../Preview/Preview";
import { usePreview } from "../../Preview/usePreview";
import { AddVisualizationSidebar } from "../EditVisualization/AddNewVisualization";
import { EditVisualizationSidebar } from "../EditVisualization/EditVisualization";
import { useDashboards } from "../useDashboards";
import { useModelTiles } from "../useModelTiles";
import { Tile } from "../VisualizationType";
import { TileVisualization } from "../Visualize";
import { usePreviewData } from "./usePreviewData";

type Props = {};

const ModelGraphPanel = (props: Props) => {
  const {
    params: { modelId },
  } = useMatch<LocationGenerics>();

  const currentModel = useCurrentModel();

  const { data, loading } = usePreviewData(modelId ?? "");

  const modelTileList = useModelTileList(modelId);

  const [editingTile, setEditingTile] = useState<null | ModelTile>(null);
  const [addingTile, setAddingTile] = useState(false);

  useAutostartPreview();

  return (
    <div className="h-full w-full overflow-auto">
      <div className="flex flex-wrap p-4">
        {modelTileList?.map((dt) => (
          <TileView
            key={dt.tileId}
            modelTile={dt}
            data={data ?? []}
            loading={loading}
            onEdit={setEditingTile}
          />
        ))}
        <div className="h-96 w-full overflow-hidden p-4 lg:w-1/2">
          <ButtonCard
            onClick={() => {
              setAddingTile(true);
            }}
            className="flec h-full w-full flex-col items-center space-y-2"
          >
            <PlusIcon className="h-6 w-6" />
            <div>Add new visualization</div>
            <BetaFeatureText />
          </ButtonCard>
        </div>
      </div>

      <EditVisualizationSidebar
        isOpen={!!editingTile}
        dashboardId={editingTile?.dashboard?.id}
        modelId={modelId}
        tile={editingTile?.tile}
        onFinished={() => {
          setEditingTile(null);
          setAddingTile(false);
        }}
        usePreviewData
        disableChangingModel
      />
      <AddVisualizationSidebar
        show={addingTile}
        onClose={setAddingTile}
        modelId={modelId}
        modelName={currentModel?.name}
        usePreviewData={true}
      />
    </div>
  );
};

export default ModelGraphPanel;

const TileView = (props: {
  modelTile: ModelTile;
  data: any[];
  loading: boolean;
  onEdit: (modelTile: ModelTile) => void;
}) => {
  return (
    <div className="h-96 w-full p-4 lg:w-1/2">
      <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border dark:border-gray-700">
        <div className="flex items-center justify-between  px-4 pt-4">
          <div className="space-y-1">
            {props.modelTile.dashboard && (
              <div className="flex items-center space-x-1 text-xs">
                <DashboardIcon className="h-3 w-3" />
                <span>{props.modelTile.dashboard.name}</span>
              </div>
            )}
            <span>{props.modelTile.tile.name}</span>
          </div>
          <Tooltip content={"Change graph settings"}>
            <IconButton
              onClick={() => {
                props.onEdit(props.modelTile);
              }}
              className={""}
              variant="ghost"
              size="sm"
              icon={<Cog6ToothIcon className="w-4 text-gray-500" />}
            />
          </Tooltip>
        </div>
        <div className="relative w-full  flex-grow">
          {props.data || props.loading ? (
            <TileVisualization
              data={props.data}
              loading={props.loading}
              visualization={props.modelTile.tile.visualization}
            />
          ) : (
            <div className=" grid h-full w-full place-content-center italic">
              Run query to view visualization
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const useAutostartPreview = () => {
  const preview = usePreview();
  const [previews] = usePreviewsAtom();

  const state = useModelEditorState();
  const getTextState = useGetEditorTextState();
  const getQueryDependencies = useGetQueryDependencies();

  const prevCalled = useRef<string>();

  useEffect(() => {
    if (!state.initialized || prevCalled.current === state.currentModelId)
      return;
    if (previews.some((p) => p.modelId === state.currentModelId)) return;

    preview({
      sqlQuery: getTextState().weldSql,
      dependencies: getQueryDependencies(getTextState().dependencyReferences),
      modelName: state.modelName,
      modelId: state.currentModelId,
      isTextSelection: false,
      modelType: state.currentModelType,
    });
    prevCalled.current = state.currentModelId;
  }, [
    preview,
    previews,
    state.currentModelId,
    state.initialized,
    state.modelName,
    getTextState,
    getQueryDependencies,
    state.currentModelType,
  ]);
};

type ModelTile = {
  dashboard?: { id: string; name: string };

  tileId: string;
  tile: Tile;
};

/*
  Get a list of all tiles for the current model from:
  - All dashboards
  - All model tiles
*/
const useModelTileList = (modelId?: string) => {
  const dashboards = useDashboards();
  const modelTiles = useModelTiles(modelId);

  return useMemo(() => {
    const modelTileList: ModelTile[] = [];
    if (!modelId) return modelTileList;

    dashboards.forEach((dashboard) => {
      if (!dashboard.tiles) return;
      Object.entries(dashboard.tiles).forEach(([tileId, tile]) => {
        if (tile.modelId === modelId) {
          modelTileList.push({
            dashboard: {
              id: dashboard.id,
              name: dashboard.name,
            },
            tileId,
            tile: { ...tile, i: tileId },
          });
        }
      });
    });

    modelTiles.forEach((tile) => {
      modelTileList.push({
        tileId: tile.i,
        tile,
      });
    });

    return modelTileList;
  }, [dashboards, modelId, modelTiles]);
};
