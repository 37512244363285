import { Transition } from "@headlessui/react";
import { WeldBadgeIcon } from "@/components/icons/WeldBadgeIcon";
import classNames from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import React from "react";

import { Description, Heading } from "./Typography";

type Step = [string, number];

const progressSteps: Step[] = [
  ["Setting up account", 3000],
  ["Connecting to data warehouse", 7000],
  ["Importing demo data", 5000],
  ["Welding dashboard", 4000],
  ["Training AI assistant", 3000],
  ["Verifying setup", 5000],
  ["Finishing up", 5000],
  ["Preparing confetti", 5000],
  ["Almost there", 7000],
  ["Any minute now", 6000],
  ["Hang in there", -1],
];

export const managedBQProgressSteps: Step[] = [...progressSteps];
managedBQProgressSteps[1] = ["Spinning up a brand new data warehouse", 7000];

function useProgressSteps(steps: Step[]) {
  const [index, setIndex] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (index === null || index >= steps.length) {
      return;
    }
    const [, stepDuration] = steps[index];
    if (stepDuration <= 0) {
      return;
    }
    const timer = setTimeout(() => {
      setIndex((i) => {
        if (i === null) {
          return i;
        }
        return i + 1;
      });
    }, stepDuration);
    return () => {
      clearTimeout(timer);
    };
  }, [steps, index]);

  return {
    steps: steps.map((x, i) => ({
      isActive: i === index,
      text: x[0],
    })),
    startProgress: () => setIndex(0),
  };
}

function ProgressSteps(props: { steps: Step[] }) {
  const { steps, startProgress } = useProgressSteps(props.steps);

  useMountEffect(startProgress);

  return (
    <div className="relative h-10 w-full">
      {steps.map((step) => (
        <div key={step.text} className="absolute inset-0 flex justify-center">
          <Transition
            as={Heading}
            appear
            show={step.isActive}
            enter="transition-all duration-500"
            enterFrom="opacity-0 -translate-x-20 scale-75"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-20 scale-50"
            className="inline-block whitespace-nowrap text-center"
          >
            {step.text}
          </Transition>
        </div>
      ))}
    </div>
  );
}

export function ConnectDataWarehouseLoadingScreen(props: {
  isComplete?: boolean;
  progressSteps?: Step[];
}) {
  return (
    <div className="flex flex-col items-center">
      <div className="relative flex h-16 w-16 flex-col items-center">
        {!props.isComplete && (
          <div className="absolute left-1/2 top-1/2 h-40 w-40 -translate-x-1/2 -translate-y-1/2 ">
            <span className="inline-flex h-full w-full animate-ping-slow rounded-full bg-gray-500 opacity-10"></span>
          </div>
        )}
        <WeldBadgeIcon
          isChecked={props.isComplete}
          className={classNames(
            "transition-all",
            props.isComplete
              ? "text-gray-800 dark:text-gray-700"
              : "animate-heart-beat text-gray-500 dark:text-gray-700",
          )}
        />
      </div>
      {props.isComplete ? (
        <>
          <Heading>Congrats!</Heading>
          <Description className="mt-2 text-center text-sm">
            Your data warehouse is now fully set up
          </Description>
        </>
      ) : (
        <>
          <ProgressSteps steps={props.progressSteps ?? progressSteps} />
          <Description className="mt-2 text-center text-sm">
            This may take up to 60 seconds depending on the type of data
            warehouse.
          </Description>
        </>
      )}
    </div>
  );
}
