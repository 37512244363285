import { OrchestrationJobFragment, useListSyncsQuery } from "@/apollo/types";
import { nodeTypes } from "@/components/elements/reactflow/CustomReactFlowNodes";
import { useEltSyncs } from "@/hooks/useSync";
import { getReactFlowLineageElements } from "@/pages/ModelTool/lineage/Lineage";
import {
  EltSyncNode,
  LineageNode,
  OrchestrationNode,
  RevEltNode,
  SourceStreamNode,
  dfTraverseDependentsFromMultiple,
} from "@/pages/ModelTool/lineage/useLineageDag";
import { useDarkMode } from "@/providers/DarkModeProvider";
import { useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Edge,
  getConnectedEdges,
} from "react-flow-renderer";

type Props = {
  jobs: OrchestrationJobFragment[];
};

const OrchestrationGraph = (props: Props) => {
  const { isDarkModeEnabled } = useDarkMode();
  const { edgeElements, nodeElements } = useOrchestrationNodes(
    props.jobs ?? [],
  );

  const [highlighedEdges, setHighlightedEdges] = useState<Edge[]>([]);

  const styledEdges: Edge[] = edgeElements.map((e) =>
    highlighedEdges.some((h) => h.id === e.id)
      ? {
          ...e,
          style: {
            stroke: "#0070F4",
            strokeWidth: 4,
            opacity: 0.7,
          },
          zIndex: 1000,
          className: "stroke-current",
        }
      : e,
  );

  return (
    <div className="relative h-full bg-gray-50">
      <div className="absolute inset-0 h-full w-full bg-white dark:bg-gray-800">
        <ReactFlow
          // key={key}
          nodesDraggable={false}
          nodes={nodeElements}
          edges={styledEdges}
          nodeTypes={nodeTypes}
          snapGrid={[18, 18]}
          minZoom={0.1}
          defaultZoom={1}
          defaultPosition={[250, 200]}
          onNodeMouseEnter={(_event, node) => {
            const edges = getConnectedEdges([node], edgeElements);
            setHighlightedEdges(edges);
          }}
          onNodeMouseLeave={() => setHighlightedEdges([])}
        >
          <Background size={0.7} color={isDarkModeEnabled ? "#555" : "#ddd"} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default OrchestrationGraph;

const useOrchestrationNodes = (jobs: OrchestrationJobFragment[]) => {
  const { eltSyncs } = useEltSyncs();
  const { data: listSyncsQueryResult } = useListSyncsQuery();

  const orchestrationNodeItems = useMemo(() => {
    const nodes: (
      | OrchestrationNode
      | EltSyncNode
      | SourceStreamNode
      | RevEltNode
    )[] = [];

    jobs.forEach((orchestrationJob) => {
      const dependencies = [...orchestrationJob.incomingDependencies];
      if (orchestrationJob.target?.__typename === "SourceStream") {
        dependencies.push(orchestrationJob.target.eltSync.id);
        nodes.push({
          id: orchestrationJob.itemId,
          type: "source-stream",
          dependencies,
          dependents: orchestrationJob.outgoingDependencies,
          job: orchestrationJob,
          eltSyncId: orchestrationJob.target.eltSync.id,
        });
      } else if (orchestrationJob.target?.__typename === "Sync") {
        const targetId = orchestrationJob.target.id;
        const revEltSync = listSyncsQueryResult?.syncs.find(
          (s) => s.id === targetId,
        );
        if (revEltSync) {
          nodes.push({
            id: orchestrationJob.itemId,
            type: "rev-elt",
            dependencies,
            dependents: orchestrationJob.outgoingDependencies,
            revEltSync,
            job: orchestrationJob,
          });
        }
      } else {
        nodes.push({
          id: orchestrationJob.itemId,
          type: "orchestration-job",
          dependencies,
          dependents: orchestrationJob.outgoingDependencies,
          job: orchestrationJob,
        });
      }
    });

    eltSyncs.forEach((eltSync) => {
      const dependents = nodes
        .filter((n) => n.dependencies.includes(eltSync.id))
        .map((n) => n.id);
      if (!dependents.length) return;

      nodes.push({
        id: eltSync.id,
        type: "elt-sync",
        dependencies: [],
        dependents: dependents,
        eltSync,
      });
    });

    return nodes;
  }, [eltSyncs, jobs, listSyncsQueryResult]);

  const dag = useMemo(() => {
    const startNodes = orchestrationNodeItems.filter(
      (node) => node.dependencies.length === 0,
    );

    const lineageNodes = dfTraverseDependentsFromMultiple(
      orchestrationNodeItems,
      startNodes,
    );

    const arrangedNodes = reArrangeDependenciesOrchestrator(lineageNodes);

    return getReactFlowLineageElements(arrangedNodes);
  }, [orchestrationNodeItems]);

  return dag;
};

const reArrangeDependenciesOrchestrator = (nodes: LineageNode[]) => {
  //Move the syncs and raw views to the "deepest" layer, to get a more clean graph:
  return nodes.map((n) => {
    let depth = n.depth;
    if (n.type === "orchestration-job") {
      if (!hasSourceStreamDependency(n, nodes)) {
        depth += 2;
      }
    }

    return {
      ...n,
      depth: depth,
    };
  });
};

const hasSourceStreamDependency = (node: LineageNode, nodes: LineageNode[]) => {
  const hasDirectDependency = node.dependencies.some(
    (d) => nodes.find((n) => n.id === d)?.type === "source-stream",
  );
  if (hasDirectDependency) return true;

  const hasIndirectDpendency = node.dependencies.some((d) => {
    const dependency = nodes.find((n) => n.id === d);
    if (!dependency) return false;
    return hasSourceStreamDependency(dependency, nodes);
  });

  return hasIndirectDpendency;
};
