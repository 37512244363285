import { ComponentProps } from "react";

import { PlanName } from "@/apollo/types";
import { Button, PrimaryButton } from "@/components/elements/Button";
import { ChangePlanDialog } from "@/features/billing";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useAddOnsCheckoutDialog } from "@/pages/Settings/Billing/Checkout";
import { PuzzlePieceIcon, RocketLaunchIcon } from "@heroicons/react/20/solid";

export function UpgradeNowButton(
  props: ComponentProps<typeof PrimaryButton> & {
    source: string;
    planName?: PlanName;
  },
) {
  const { children, planName, source, ...buttonProps } = props;
  const mixpanel = useMixpanel();
  const disclosure = useDisclosure();
  return (
    <>
      <PrimaryButton
        icon={children ? undefined : <RocketLaunchIcon />}
        {...buttonProps}
        onClick={(e) => {
          props.onClick?.(e);
          if (e.isDefaultPrevented()) {
            return;
          }
          mixpanel.track("Upgrade Now Button Clicked", {
            source,
            planName,
          });
          disclosure.onOpen();
        }}
      >
        {children ?? "Upgrade Now"}
      </PrimaryButton>
      <ChangePlanDialog {...disclosure} size="lg" />
    </>
  );
}

export function ViewAddOnsButton(
  props: ComponentProps<typeof Button> & {
    source: string;
  },
) {
  const { openCheckoutDialog } = useAddOnsCheckoutDialog();
  const mixpanel = useMixpanel();
  return (
    <Button
      {...props}
      icon={<PuzzlePieceIcon />}
      onClick={(e) => {
        props.onClick?.(e);
        openCheckoutDialog();
        mixpanel.track("View Addons Button Clicked", {
          source: props.source,
        });
      }}
    />
  );
}
