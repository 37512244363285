import { useMemo } from "react";

import IconWithBG from "@/components/elements/IconWithBG";
import ImportIcon from "@/components/icons/ImportIcon";
import { useEltSyncs } from "@/hooks/useSync";
import { IntegrationLogoBox } from "@/integrations";
import { useRawViews } from "@/pages/ModelTool/useRawViews";
import { useDataWarehouse } from "@/providers/account";

import {
  EltStreamItemType,
  FolderItemType,
  ViewItemType,
} from "../SidebarFactory";

type RawViews = ReturnType<typeof useRawViews>["rawViews"];

/**
 * Ensures that folder's children are always views.
 */
type ViewFolder = Omit<FolderItemType, "children"> & {
  children: (ViewItemType | EltStreamItemType)[];
};

export const useDataSourcesFolders = () => {
  const { eltSyncs, loading: loadingEltSyncs } = useEltSyncs({
    pollInterval: 60_000,
  });
  const { rawViews, loading: loadingRawViews } = useRawViews({
    pollInterval: 60_000,
  });

  const { integrationId: dwIntegrationId } = useDataWarehouse();

  const dataSourcesFolders = useMemo(() => {
    const viewRegistry = rawViews.reduce(
      (acc, view) => {
        if (view.syncId) {
          // Key format: `OQS1rehmj6VWTY_myTableName`
          acc[`${view.syncId}_${view.sourceStream}`] = view;
        }
        return acc;
      },
      {} as Record<string, RawViews[0]>,
    );

    const addedItemIds: string[] = [];

    const folders: Record<string, ViewFolder> = {};

    // Insert all the syncs and their streams into folders
    for (const eltSync of eltSyncs) {
      if (!eltSync.sourceStreams.length) continue;

      const schemaName =
        eltSync.destinationSchemaName ?? eltSync.sourceIntegrationId;

      if (!folders[eltSync.id]) {
        folders[eltSync.id] = newFolder(
          eltSync.id,
          schemaName,
          eltSync.sourceIntegrationId,
          eltSync.id,
          eltSync.status === "PAUSED",
        );
      }

      //Add items for each sourceStream:
      for (const stream of eltSync.sourceStreams) {
        const viewKey = `${eltSync.id}_${stream.name}`;

        const rawView = viewRegistry[viewKey];
        if (!!rawView) {
          folders[eltSync.id].children.push({
            itemType: "view",
            id: rawView.viewId,
            rawView,
            isViewImported: false,
          });
          addedItemIds.push(rawView.viewId);
        } else {
          folders[eltSync.id].children.push({
            itemType: "elt-stream",
            id: viewKey,
            sync: eltSync,
            streamName: stream.name,
          });
        }
      }

      //Add remaining items (substeams)
      rawViews
        .filter((view) => {
          return view.syncId === eltSync.id;
        })
        .forEach((view) => {
          if (addedItemIds.includes(view.viewId)) return;

          folders[eltSync.id].children.push({
            itemType: "view",
            id: view.viewId,
            rawView: view,
            isViewImported: false,
          });
          addedItemIds.push(view.viewId);
        });
    }

    // Add imported views into folders
    for (const view of rawViews) {
      if (addedItemIds.includes(view.viewId)) continue;

      const schemaName = view.schema;

      // If the view is from the data warehouse, we don't want to show the DW logo
      const instegrationId =
        view.integrationId === dwIntegrationId
          ? undefined
          : view.integrationId ?? undefined;

      if (!folders[schemaName]) {
        folders[schemaName] = newFolder(
          schemaName,
          view.schema,
          instegrationId,
        );
      }

      folders[schemaName].children.push({
        itemType: "view",
        id: view.sourceStream,
        rawView: view,
        isViewImported: true,
      });
      addedItemIds.push(view.viewId);
    }

    // Deduplicate folders for connectors that have only one sync per sourcestream
    const deduplicatedFolders = deduplicateSomeFolders(Object.values(folders));

    /**
     * 1. Sort folders by schema name.
     * 2. Sort children by item name
     * 3. Sort folders by syncPaused status (last synced folders come last)
     */
    const sortedFolders = deduplicatedFolders
      .sort((a, b) => {
        // Sort by syncPaused status (last synced folders come last)
        if (a.folder.syncPaused && !b.folder.syncPaused) {
          return 1;
        }
        if (!a.folder.syncPaused && b.folder.syncPaused) {
          return -1;
        }
        // Sort by schema name
        return a.folder.name.localeCompare(b.folder.name);
      })
      .map((folder) => {
        folder.children.sort((a, b) =>
          getItemName(a).localeCompare(getItemName(b)),
        );
        return folder;
      });

    return sortedFolders;
  }, [dwIntegrationId, eltSyncs, rawViews]);

  return {
    dataSourcesFolders,
    loading: loadingEltSyncs || loadingRawViews,
  };
};

const newFolder = (
  folderId: string,
  destinationSchemaName: string,
  integrationId?: string,
  syncId?: string,
  syncPaused?: boolean,
): ViewFolder => {
  const icon = integrationId ? (
    <IntegrationLogoBox id={integrationId} size="1xs" />
  ) : (
    <IconWithBG
      icon={<ImportIcon />}
      size="1xs"
      className="bg-gray-200 dark:bg-gray-700"
    />
  );
  return {
    id: folderId,
    itemType: "folder",
    folder: {
      id: folderId,
      name: destinationSchemaName,
      folderType: "raw",
      syncId,
      integrationId,
      syncPaused,
    },
    icon,
    children: [],
  };
};

/**
 * Just a private utility to simplify code above a bit.
 *
 * Stream names come as dot separated, so we convert them manually (public.users => public_users).
 *
 *
 */
const getItemName = (item: ViewFolder["children"][0]): string => {
  if (item.itemType === "elt-stream") return item.streamName.replace(".", "_");
  return item.rawView.path.slice(2).join(".");
};

/**
 *
 * Deduplicate folders by merging their children if they are of the following connector types:
 * S3, google_sheets, ftp, sftp
 *
 */
const deduplicateableIntegrations = [
  "s3",
  "google-sheets",
  "google-sheets-service-account",
  "ftp",
  "sftp",
];
const deduplicateSomeFolders = (folderItems: ViewFolder[]) => {
  const foldersMap = [...folderItems].reduce(
    (acc, folderItem) => {
      const isDeduplicatable = deduplicateableIntegrations.includes(
        folderItem.folder.integrationId ?? "",
      );
      if (isDeduplicatable) {
        const key = `dublicate_${folderItem.folder.name}`;

        if (!acc[key]) {
          acc[key] = { ...folderItem };
        } else {
          // Merge children and remove syncId for all merged folders:
          acc[key].children = [...acc[key].children, ...folderItem.children];
          acc[key].folder.syncId = undefined;
          acc[key].folder.syncPaused =
            acc[key].folder.syncPaused && folderItem.folder.syncPaused;
        }
      } else {
        acc[folderItem.id] = folderItem;
      }
      return acc;
    },
    {} as Record<string, ViewFolder>,
  );

  return Object.values(foldersMap);
};
