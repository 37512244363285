import { useCallback, useEffect, useRef } from "react";

import { Button, PrimaryButton } from "@/components/elements/Button";
import { CodeDisplay } from "@/components/elements/CodeDisplay";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { stringToUniqueId } from "@/helpers/stringToUniqueId";
import { useEditorState } from "@/pages/ModelTool/EditorStore";
import { usePreview } from "@/pages/ModelTool/Preview/usePreview";
import { useMonacoInstance } from "@/pages/ModelTool/QueryEditor/SQLEditor";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import {
  extractWeldTags,
  useGetQueryDependencies,
} from "@/pages/ModelTool/useQueryDependencies";
import { useToast } from "@/providers/ToastProvider";
import { PlayIcon } from "@heroicons/react/24/solid";

export const SQLDisplayBox = (props: {
  sql: string;
  loading: boolean;
  chatThreadId?: string;
}) => {
  const insertText = useInsertToEditor();
  const preview = usePreview();
  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();

  const [editorState] = useEditorState();

  const getQueryDependencies = useGetQueryDependencies();

  //Auto scroll to bottom of chat when new message is added
  const messagesEndRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.sql) messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [props.sql]);

  return (
    <div className="space-y-2 pl-9 pt-2">
      <CodeDisplay
        language="sql"
        showLineNumber={false}
        code={props.sql}
        style={{
          overflow: "auto",
          maxHeight: "24rem",
        }}
      />

      <div className="flex items-stretch justify-start ">
        {editorState.initialized &&
          editorState.currentEditorState !== "dashboard" && (
            <>
              <PrimaryButton
                size="sm"
                className="mr-2"
                onClick={() => {
                  preview({
                    sqlQuery: props.sql,
                    dependencies: getQueryDependencies(
                      extractWeldTags(props.sql),
                    ),
                    modelId: stringToUniqueId(props.sql),
                    isTextSelection: false,
                    modelName: "SQL assistant query",
                    modelType: "draft",
                    chatTreadId: props.chatThreadId,
                  });
                }}
                iconRight={<PlayIcon />}
              >
                Run
              </PrimaryButton>
              <Button
                className="mr-2"
                size="sm"
                variant="outline"
                colorScheme="primary"
                onClick={() => {
                  insertText(props.sql);
                }}
              >
                Insert to editor
              </Button>
            </>
          )}

        <Button
          size="sm"
          variant="outline"
          colorScheme="primary"
          onClick={() => {
            const draft = createDraftModel(props.sql);
            openModelInTab({ modelId: draft.id, type: "draft" });
          }}
        >
          New model
        </Button>
      </div>

      <div ref={messagesEndRef} />
    </div>
  );
};

const useInsertToEditor = () => {
  const [editor] = useMonacoInstance();
  const toast = useToast();

  const handleInsert = useCallback(
    (text: string) => {
      if (!editor) return toast("Not inserted", "No editor found", "warning");

      const model = editor?.getModel();
      if (model) {
        const range = editor?.getSelection();
        if (!range) return;
        // insert at cursor position
        model.pushEditOperations(
          [],
          [
            {
              range,
              text,
            },
          ],
          () => null,
        );
        editor.focus();
        // select last line
        const lastLine = model.getLineCount();
        const lastColumn = model.getLineContent(lastLine).length + 1;
        editor.setSelection({
          startLineNumber: lastLine,
          startColumn: lastColumn,
          endLineNumber: lastLine,
          endColumn: lastColumn,
        });
      }
    },
    [editor, toast],
  );

  return handleInsert;
};
