import React, { ComponentProps, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CellProps, Column } from "react-table";

import {
  CurrentUserWithAccountsDocument,
  useAddNotificationEmailMutation,
  useRemoveNotificationEmailMutation,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import MenuItem from "@/components/elements/MenuItem";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { Heading, TextMuted } from "@/components/elements/Typography";
import FormTextInput from "@/components/modules/FormFactory/FormTextInput";
import TableMenu from "@/components/modules/TableMenu";
import useDeleteItem from "@/hooks/useDeleteItem";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { PlusIcon } from "@heroicons/react/20/solid";

import SlackNotificationSetup from "./SlackNotifications";

const NotificationPreferences = () => {
  return (
    <div className="flex flex-col gap-8">
      <div>
        <Heading className="mb-2">Notification email addresses</Heading>
        <NotificationEmailsTable />
      </div>
      <div>
        <Heading className="mb-2">Slack notifications</Heading>
        <SlackNotificationSetup />
      </div>
    </div>
  );
};

type Row = {
  email: string;
};

const NotificationEmailsTable = () => {
  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Email address",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.email}</span>;
        },
        accessor: "email",
      },
      {
        id: "actions",
        Header: "",
        Cell: ({ row }: CellProps<Row>) => (
          <div className="flex justify-end">
            <NotificationEmailMenu email={row.original.email} />
          </div>
        ),
      },
    ],
    [],
  );

  const account = useCurrentAccount();
  const tableData = account.notificationEmails
    .slice()
    .sort()
    .map((email) => ({
      email,
    }));

  const newEmailDisclosure = useDisclosure();

  return (
    <div className="flex flex-col gap-2">
      {tableData.length ? (
        <DefaultTable columns={columns} data={tableData} />
      ) : (
        <TextMuted className="text-sm text-gray-500 dark:text-gray-400">
          Add an address to start receiving notifications by email.
        </TextMuted>
      )}
      <div>
        <SecondaryButton
          className="mt-2"
          icon={<PlusIcon className="w-4" />}
          onClick={() => newEmailDisclosure.onOpen()}
          size={tableData.length === 0 ? "md" : "sm"}
        >
          Add email address
        </SecondaryButton>
      </div>
      <NewEmailModal {...newEmailDisclosure} />
    </div>
  );
};

const NewEmailModal = (props: ComponentProps<typeof Modal>) => {
  const toast = useToast();
  const form = useForm<{ notificationEmail: string }>({
    mode: "onChange",
    shouldUnregister: true,
  });
  const [addNotificationEmail] = useAddNotificationEmailMutation({
    onCompleted() {
      toast(
        "Successful",
        "Notification email address successfully added",
        "success",
      );
      props.onClose();
    },
    onError(error) {
      toast("Error adding notification email address", error.message, "error");
      props.onClose();
    },
    refetchQueries: [CurrentUserWithAccountsDocument],
  });

  const onSubmit = (data: { notificationEmail: string }) => {
    addNotificationEmail({
      variables: data,
    });
  };

  return (
    <Modal {...props}>
      <ModalCloseButton />
      <ModalHeader>Add notification email address</ModalHeader>
      <FormProvider {...form}>
        <form id="add-email-form" onSubmit={form.handleSubmit(onSubmit)}>
          <ModalBody>
            <FormTextInput
              field={{
                type: "email",
                required: true,
                name: "notificationEmail",
                label: "Email address to receive notifications",
                inputProps: {
                  placeholder: "Email address",
                  autoComplete: "off",
                  defaultValue: "",
                },
              }}
            />
          </ModalBody>
          <ModalFooter className="flex-row-reverse justify-start gap-4">
            <PrimaryButton
              disabled={!form.formState.isValid}
              onClick={(e) => {
                e.stopPropagation();
                onSubmit(form.getValues());
              }}
            >
              Add
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </SecondaryButton>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

const NotificationEmailMenu = ({ email }: { email: string }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem({
    title: "email address",
    variables: { notificationEmail: email },
    mutation: useRemoveNotificationEmailMutation,
    refetchQueries: [{ query: CurrentUserWithAccountsDocument }],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="email address"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <MenuItem text={"Delete"} onClick={() => setShowConfirmModal(true)} />
      </TableMenu>
    </>
  );
};

export default NotificationPreferences;
