import { useListOrchestrationWorkflowsQuery } from "@/apollo/types";
import { useMemo } from "react";

export const useListOrchestrationWorkflows = (poll: boolean = true) => {
  const { data, loading } = useListOrchestrationWorkflowsQuery({
    pollInterval: poll ? 5000 : undefined,
  });

  return useMemo(() => {
    const workflows = data?.orchestrationWorkflows ?? [];

    return {
      workflows,
      loading,
    };
  }, [data?.orchestrationWorkflows, loading]);
};
