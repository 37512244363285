import {
  useCurrentSubscriptionStateLazyQuery,
  useCurrentUserWithAccountsLazyQuery,
  useCurrentWeldPlanLazyQuery,
  useNotificationsLazyQuery,
  usePlanLimitsStatusLazyQuery,
  useStripeSubscriptionLazyQuery,
} from "@/apollo/types";
import { useCurrentAccount } from "@/providers/account";

export function useRevalidateSubscriptionCache() {
  const account = useCurrentAccount();

  const [fetchUserWithAccounts] = useCurrentUserWithAccountsLazyQuery({
    fetchPolicy: "network-only",
    context: {
      skipXAccountIdHeader: true,
    },
  });

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    fetchPolicy: "network-only",
    variables: { slug: account.slug },
  });

  const [fetchCurrentWeldPlan] = useCurrentWeldPlanLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchPlanLimitsStatus] = usePlanLimitsStatusLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchCurrentSubscriptionState] = useCurrentSubscriptionStateLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchNotifications] = useNotificationsLazyQuery({
    fetchPolicy: "network-only",
  });

  return async () => {
    // Refetch the user data, i.e. accounts with settings, such as block ELT syncs etc.
    await fetchUserWithAccounts();
    // The detailed Stripe Subscription data, i.e. Stripe Customer and Subscription details
    await fetchStripeSubscription();
    // The Weld plan definitions, i.e. limits, add-ons etc.
    await fetchCurrentWeldPlan();
    // The current plan limits status, i.e. current usage, limits etc.
    await fetchPlanLimitsStatus();
    // The current subscription status (removes Freemium/Trial Expired banners etc.)
    await fetchCurrentSubscriptionState();
    // The current notifications that may have been triggered/removed by creating the subscription
    await fetchNotifications();
  };
}
