import { useEffect } from "react";

import { Button, ButtonGroup, IconButton } from "@/components/elements/Button";
import confirm from "@/components/elements/Confirm";
import { Heading, TextMuted } from "@/components/elements/Typography";
import HistoryIcon from "@/components/icons/HistoryIcon";
import { HelpIcon } from "@/components/icons/outline";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import cn from "@/helpers/classNames";
import { useCallbackRef } from "@/hooks/useCallbackRef";
import { useControllableState } from "@/hooks/useControllableState";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { AiChatAssistant } from "./components/AiChatAssistant";
import { ChatHistory } from "./components/ChatHistory";
import { EdIcon } from "./components/EdIcon";
import { ChatProvider } from "./providers/ChatProvider";
import { CurrentModelContext } from "./types";
import { useChat } from "./useChat";

export type ChatView = "chat" | "history";

export default function AiAssistantContainer(props: {
  threadId?: string;
  view?: ChatView;
  initToken?: string;
  enableHistory?: boolean;
  getInitOptions?: (token: string) =>
    | {
        threadId?: string;
        context?: CurrentModelContext;
        input?: string;
      }
    | undefined;
  onClose: () => void;
  onChangeThread: (threadId: string | undefined) => void;
  onChangeView?: (view: ChatView) => void;
}) {
  const [view, setView] = useControllableState<ChatView>({
    prop: props.view ?? "chat",
    defaultProp: "chat",
    onChange: props.onChangeView,
  });

  const [threadId, setThreadId] = useControllableState<string | null>({
    // When enableHistory is false, the threadId state should be `uncontrolled`, i.e. internal to this component
    // In this way, chat history will not be available so the user cannot access a specific thread via URL.
    prop: props.enableHistory ? (props.threadId ?? null) : undefined,
    onChange: (threadId) => props.onChangeThread(threadId ?? undefined),
  });

  const chat = useChat(threadId ?? undefined, {
    onThreadCreated: setThreadId,
  });

  const getInitOptions = useCallbackRef(props.getInitOptions);
  const initChatFromExternalTrigger = useCallbackRef(
    async (
      input: string,
      modelContext: CurrentModelContext | undefined,
      threadId: string | undefined,
    ) => {
      const isThreadMatch =
        threadId !== undefined && threadId === chat.threadId;
      if (!props.enableHistory && !isThreadMatch && chat.messages.length > 0) {
        const confirmed = await confirm({
          title: "Clear existing AI chat?",
          message:
            "This will clear all chat messages and start a new conversation. Are you sure?",
          confirmButtonText: "Ok",
        });
        if (!confirmed) return;
        setThreadId(null);
      }
      chat.submitMessage({
        threadId,
        input,
        modelContext,
      });
    },
  );

  useEffect(() => {
    if (props.initToken && typeof getInitOptions === "function") {
      const initOptions = getInitOptions(props.initToken);
      if (initOptions?.input == null) {
        return;
      }
      initChatFromExternalTrigger(
        initOptions.input,
        initOptions.context,
        initOptions.threadId,
      );
    }
  }, [props.initToken, getInitOptions, initChatFromExternalTrigger]);
  return (
    <>
      <div className="flex p-0">
        <div className="flex flex-1 items-center justify-between gap-4 py-2">
          <div className="flex items-center justify-start gap-2 pl-6">
            <div className="flex h-6 w-6 items-center justify-center">
              <EdIcon className="h-5 w-5" />
            </div>
            <span className="text-lg font-bold">Ed</span>
          </div>
          <div className="flex flex-1 items-center justify-end pr-2">
            <ButtonGroup
              variant="outline"
              colorScheme="secondary"
              size="sm"
              className="gap-2"
            >
              {view === "history" ? (
                <Button
                  icon={<HelpIcon />}
                  onClick={() => setView("chat")}
                  className="h-6"
                >
                  Chat
                </Button>
              ) : (
                <Button
                  icon={<HistoryIcon />}
                  onClick={() => setView("history")}
                  className="h-6"
                >
                  History
                </Button>
              )}
              {props.enableHistory ? (
                <Button
                  icon={<PencilSquareIcon />}
                  onClick={() => {
                    setView("chat");
                    setThreadId(null);
                  }}
                  className="h-6"
                >
                  New Chat
                </Button>
              ) : (
                <Button
                  isDisabled={chat.messages.length === 0}
                  icon={<PencilSquareIcon />}
                  onClick={() => {
                    setView("chat");
                    setThreadId(null);
                  }}
                  className="h-6"
                >
                  Reset
                </Button>
              )}
            </ButtonGroup>
            <IconButton
              className="ml-4"
              variant="ghost"
              size="sm"
              icon={<XMarkIcon className="h-5 w-5" />}
              onClick={() => props.onClose()}
            />
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        {view === "history" ? (
          <>
            {!props.enableHistory ? (
              <div className="px-6 py-10">
                <ChatHistoryTeaser />
              </div>
            ) : (
              <ChatHistory
                onSelectThread={(thread) => {
                  setView("chat");
                  setThreadId(thread.id);
                }}
                emptyState={
                  <div className="my-10 flex flex-col items-center gap-8 px-6">
                    <TextMuted className="text-center">
                      Chat history is empty
                    </TextMuted>
                    <div>
                      <Button
                        colorScheme="primary"
                        variant="solid"
                        icon={<PencilSquareIcon />}
                        onClick={() => {
                          setView("chat");
                          setThreadId(null);
                        }}
                      >
                        Start a new conversation
                      </Button>
                    </div>
                  </div>
                }
              />
            )}
          </>
        ) : (
          <ChatProvider {...chat}>
            <AiChatAssistant />
          </ChatProvider>
        )}
      </div>
    </>
  );
}

function ChatHistoryTeaser(props: React.ComponentProps<"div">) {
  return (
    <div {...props} className={cn("flex flex-col gap-4", props.className)}>
      <Heading className="text-xl">
        Discover Your Past Interactions with Chat History
      </Heading>
      <TextMuted className="text-sm leading-snug">
        Chat History lets you revisit your interactions with Ed, providing
        valuable context to your past questions. Stay informed and pick up where
        you left off with ease.
      </TextMuted>
      <div className="flex w-full flex-col gap-4 text-center">
        <span>
          Chat History is exclusively available on the <strong>Core</strong>,{" "}
          <strong>Pro</strong> and <strong>Business</strong> plans.
        </span>
        <div>
          <UpgradeNowButton source="feature guard - chat history" />
        </div>
      </div>
    </div>
  );
}
