import { classed } from "@/components/classed.config";

export const Heading = classed.h2("text-base font-medium");

export const SectionHeading = classed.h3("font-medium text-sm");

export const SectionSubHeading = classed.h4(
  "font-medium text-xs text-gray-700 dark:text-gray-500",
);

export const TextMuted = classed.span("text-gray-500 dark:text-gray-400");
