import React, { Suspense, useCallback } from "react";

import { PlanName } from "@/apollo/types";
import { Modal } from "@/components/elements/Modal";
import * as Sentry from "@sentry/react";
import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";

import { useBillingPeriodToggle } from "../../shared";
import { LocaleProvider, StripeProvider } from "../../shared/providers";
import { ConfirmSubscriptionContainer } from "./ConfirmSubscriptionContainer";

const CheckoutContainer = React.lazy(() => import("./CheckoutContainer"));

type LocationGenerics = MakeGenerics<{
  Search: { checkout: string };
}>;

export function useCheckoutModal() {
  const navigate = useNavigate<LocationGenerics>();
  const search = useSearch<LocationGenerics>();

  const isConfirmSubscription = search.checkout === "confirm_subscription";

  const planName =
    search.checkout && !isConfirmSubscription
      ? Object.values(PlanName).find((x) => x === search.checkout)
      : undefined;

  const createOpenProps = (planName: PlanName) => ({
    search: {
      checkout: planName,
    },
  });

  const onOpen = useCallback(
    (planName: PlanName) => {
      navigate(createOpenProps(planName));
    },
    [navigate],
  );

  const onClose = useCallback(() => {
    navigate({
      search: {
        checkout: undefined,
      },
    });
  }, [navigate]);

  return {
    openCheckoutModal: onOpen,
    closeCheckoutModal: onClose,
    getLinkProps: (planName: PlanName) => createOpenProps(planName),
    planName,
    isConfirmSubscription,
  };
}

export function CheckoutModalRoot() {
  const { closeCheckoutModal, planName, isConfirmSubscription } =
    useCheckoutModal();
  const { billingPeriod } = useBillingPeriodToggle();
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Modal
        isOpen={!!planName}
        size="xl"
        centerOnScreen={false}
        onClose={() => {}}
      >
        <Suspense fallback={<div />}>
          {planName && (
            <LocaleProvider>
              <CheckoutContainer
                planName={planName}
                billingPeriod={billingPeriod}
                onClose={() => {
                  closeCheckoutModal();
                }}
              />
            </LocaleProvider>
          )}
        </Suspense>
      </Modal>
      <Modal
        isOpen={isConfirmSubscription}
        size="md"
        centerOnScreen={false}
        onClose={() => {}}
      >
        <Suspense fallback={<div />}>
          <StripeProvider>
            <ConfirmSubscriptionContainer
              onClose={() => {
                closeCheckoutModal();
              }}
            />
          </StripeProvider>
        </Suspense>
      </Modal>
    </Sentry.ErrorBoundary>
  );
}
