import { useMatch } from "@tanstack/react-location";
import { OrchestrationWorkflowRunQuery } from "@/apollo/types";
import { HRForContent } from "@/components/elements/layout/Content";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import TableIcon from "@/components/icons/TableIcon";
import MainPageLayout from "@/components/layouts/MainPageLayout";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import { useModelDisplayName } from "@/pages/ModelTool/hooks/useFullPathNames";
import Lineage from "@/pages/ModelTool/lineage/Lineage";
import { useMemo } from "react";
import { LocationGenerics } from "routes";

import { useWorkflowRun } from "./components/useWorkflowRun";
import { getJobStatusColor } from "./components/utility/getJobStatusColor";
import { WorkflowJobStatusDisplay } from "./components/WorkflowJobStatusDisplay";

type Props = {};

const ModelDetails = (props: Props) => {
  const match = useMatch<LocationGenerics>();
  const { isEnabled: isOrchestrationEnabled } = useOrchestrationFeatureGuard();

  const { orchestrationWorkflowRun, loading } = useWorkflowRun(
    match.search.workflowRunId,
  );

  const job = useMemo(() => {
    return orchestrationWorkflowRun?.jobs.find(
      (j) =>
        (j.target?.__typename === "MaterializedTableReference" ||
          j.target?.__typename === "ModelViewReference") &&
        j.target.modelId === match.params.modelId,
    );
  }, [match.params.modelId, orchestrationWorkflowRun?.jobs]);

  return (
    <MainPageLayout>
      <div className="relative flex h-full flex-col">
        <HRForContent />
        <div className="relative flex space-x-8 py-4 text-sm">
          {job && <JobDetail job={job} />}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <LoadingSpinner className="h-6 w-6" />
            </div>
          )}
        </div>
        <HRForContent />
        {isOrchestrationEnabled &&
          job &&
          job.target &&
          "modelTestRuns" in job.target &&
          job.target.modelTestRuns.length > 0 && (
            <>
              <HRForContent />
            </>
          )}

        <div className="grow ">
          <Lineage focusedId={match.params.modelId} />
        </div>
      </div>
    </MainPageLayout>
  );
};

export const ModelTitle = (props: { modelId: string }) => {
  const { modelName, pathName } = useModelDisplayName(props.modelId);
  return (
    <div className="flex items-center space-x-2">
      <TableIcon className="h-5 w-5 flex-none" />
      <div>
        {pathName && <span className="">{pathName}.</span>}
        <span className="font-medium">{modelName}</span>
      </div>
    </div>
  );
};

const JobDetail = (props: {
  job: OrchestrationWorkflowRunQuery["orchestrationWorkflowRun"]["jobs"][0];
}) => {
  if (
    props.job.target?.__typename !== "MaterializedTableReference" &&
    props.job.target?.__typename !== "ModelViewReference"
  )
    return null;

  return (
    <>
      <div className="">
        <div className="font-medium">status</div>
        <div className={getJobStatusColor(props.job.status, "text")}>
          <WorkflowJobStatusDisplay jobStatus={props.job.status} />
        </div>
      </div>
      {props.job.errorMessage && (
        <div className="grow">
          <div className="font-medium">error message</div>
          <div className="text-sm italic">{props.job.errorMessage}</div>
        </div>
      )}
    </>
  );
};

export default ModelDetails;
