import { useMemo } from "react";

import { DependencyType, QueryDependencyInput } from "@/apollo/types";
import Tooltip from "@/components/elements/Tooltip";
import { IntegrationLogoBox } from "@/integrations";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useRawViews } from "@/pages/ModelTool/useRawViews";

import { useViewDataSourceSlideOver } from "../view-data-source-slideover";

/*
 * Display different usefull error messages for when a query failed.
 */
export const QueryErrorMessages = (props: {
  errorMsg?: string; //Original error message as returned from DW / BE
  dataReferences: QueryDependencyInput[]; //Any references used in the query
}) => {
  const noRowsFound = [
    "No rows returned from executed query.",
    "Result does not contain any rows",
  ].includes(props.errorMsg || "");

  const isReferenceError = useMemo(
    () =>
      [
        `Syntax error: Unexpected "{"`,
        `Reference not found for: `,
        `or perhaps it does not exist`,
      ].some((errorMsg) => (props.errorMsg || "").includes(errorMsg)),
    [props.errorMsg],
  );

  return (
    <div className="flex flex-col items-center space-y-8">
      <div className="text-md">{props.errorMsg || "Unknown error."}</div>
      {noRowsFound && <NoRowsErrorInfo dataReferences={props.dataReferences} />}
      {isReferenceError && (
        <ReferenceError
          errorMsg={props.errorMsg}
          dataReferences={props.dataReferences}
        />
      )}
    </div>
  );
};

const ReferenceError = (props: {
  errorMsg?: string;
  dataReferences: QueryDependencyInput[];
}) => {
  return (
    <div className="max-w-sm space-y-1 rounded-sm border px-4 pb-4 pt-2 text-left text-xs">
      <div className="mb-2 font-medium">Error with data reference</div>
      <div>
        It looks like there might be an issue with the data reference. Read more
        about how to{" "}
        <a
          className="underline"
          href={`https://weld.app/docs/workspace/editor/data-models#reference-models`}
          target="_blank"
          rel="noreferrer"
        >
          reference data in WELD
        </a>
      </div>
    </div>
  );
};

const NoRowsErrorInfo = (props: { dataReferences: QueryDependencyInput[] }) => {
  const incompleteViews = useIncompleteRawViews(props.dataReferences);
  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  if (!incompleteViews.length) return null;
  return (
    <div className="space-y-1 rounded-sm border px-4 pb-4 pt-2 text-xs">
      <div className="mb-2 font-medium">
        Dependent data source{incompleteViews.length > 1 && "s"}{" "}
        {incompleteViews.length > 1 ? "have" : "has"} not synced yet
      </div>
      {incompleteViews.map((view) => {
        return (
          <div className="flex items-center space-x-1" key={view.viewId}>
            <Tooltip content={"Click to view data sync status"}>
              <button
                className="flex items-center space-x-1 underline"
                onClick={() => {
                  if (!view.syncId) return;

                  viewSync({ syncId: view.syncId });
                }}
              >
                {view.integrationId && (
                  <IntegrationLogoBox
                    size="1xs"
                    id={view.integrationId || ""}
                  />
                )}
                <div>{`${view.weldTag}`}</div>
              </button>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

const useIncompleteRawViews = (queryDependency: QueryDependencyInput[]) => {
  const { rawViews } = useRawViews();
  const { models } = useListModels();

  return useMemo(() => {
    const rawViewIds: string[] = [];

    for (const dependency of queryDependency) {
      if (
        dependency.type === DependencyType.ModelView ||
        dependency.type === DependencyType.MaterializedTable
      ) {
        rawViewIds.push(...getRawViewIds(dependency.dwItemId, models, []));
      }

      if (dependency.type === DependencyType.RawView) {
        rawViewIds.push(dependency.dwItemId);
      }
    }

    // Get the view objects
    const views = rawViews.filter((rawView) =>
      rawViewIds.includes(rawView.viewId),
    );

    const incompleteViews = views.filter((view) => !view.firstSyncCompleted);

    return incompleteViews;
  }, [rawViews, queryDependency, models]);
};

/*
 * This function is used to get the raw view ids a model is depending on.
 * It traverses back through the dependencies of the model to find all the raw views.
 */
const getRawViewIds = (
  modelId: string,
  allModels: ReturnType<typeof useListModels>["models"],
  rawViewIds: string[],
) => {
  const model = allModels.find((m) => m.id === modelId);
  if (!model) return rawViewIds;

  const nextRawViews: string[] = [];
  model.publishedQuery?.dependencies?.forEach((dependency) => {
    if (dependency.type === DependencyType.RawView)
      nextRawViews.push(dependency.dwItemId);

    if (
      dependency.type === DependencyType.MaterializedTable ||
      dependency.type === DependencyType.ModelView
    )
      nextRawViews.push(...getRawViewIds(dependency.dwItemId, allModels, []));
  });

  return [...nextRawViews, ...rawViewIds];
};
