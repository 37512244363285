import classNames from "@/helpers/classNames";
import { forwardRef } from "react";

type SelectorButtonProps = {
  headline: string;
  description: string;
  icon: JSX.Element;
  selected: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

export const SelectorButton = forwardRef<
  HTMLButtonElement,
  SelectorButtonProps
>((props, ref) => {
  return (
    <button
      ref={ref}
      className={classNames(
        props.className,
        "flex items-center space-x-4 border p-4 dark:border-gray-700 dark:text-white",
        !props.disabled && "hover:bg-gray-100 dark:hover:bg-gray-700",
        props.disabled
          ? "opacity-50"
          : props.selected
            ? "bg-white dark:bg-gray-600"
            : "opacity-75 hover:opacity-100",
      )}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div
        className={classNames(
          "shrink-0 rounded-full p-2",
          props.selected
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-500",
        )}
      >
        {props.icon}
      </div>
      <div className="grow  text-left">
        <div className={classNames("text-md font-medium")}>
          {props.headline}
        </div>
        <div className={classNames("text-sm text-gray-500 dark:text-gray-200")}>
          {props.description}
        </div>
      </div>
    </button>
  );
});
