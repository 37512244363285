import produce from "immer";
import { MutableRefObject, useContext } from "react";
import { FormProvider } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { Connection, useEltConnectionSettingsLazyQuery } from "@/apollo/types";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { SectionHeading, TextMuted } from "@/components/elements/Typography";
import Center from "@/components/elements/layout/Center";
import { ConnectorsLimitAlert } from "@/components/modules/PlanAlerts";
import {
  ConnectorOption as ConnectorOptionType,
  useConnectorOptions,
} from "@/features/connectors";
import {
  CustomConnectorFeatureGuard,
  CustomConnectorTeaser,
} from "@/features/feature-guards";
import {
  useConnectorsLimitReachedUpgradeDialog,
  useWeldPlan,
} from "@/features/subscription";
import { useMountEffect } from "@/hooks/useMountEffect";
import {
  IntegrationType,
  isCustomConnector,
  useCreateConnection,
  useIntegrationsMap,
} from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "@/pages/Connections/ConnectionForm/ConnectionForm";
import {
  ConnectionSlideOverContext,
  ConnectionSlideOverContextProvider,
} from "@/pages/Connections/NewConnectionSlideOver";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import { useToast } from "@/providers/ToastProvider";
import {
  ConnectorOption,
  Connectors,
  SearchFilter,
} from "@/widgets/connectors";
import {
  RecommendedConnections,
  RecommendedConnectorOption,
} from "@/widgets/recommended-connectors";
import { RequestConnector } from "@/widgets/request-connector";

import { useStepsContext } from "../../../stepper/useSteps";
import { Content, Footer } from "../../components/Layout";
import { NavButton } from "../../components/NavButton";
import { useDataSourceStepContext } from "../../contexts/DataSourceStepsContext";
import {
  createInitialState,
  useDataSourceStateContext,
} from "../../step-state";
import IPWhitelistInfo from "./IPWhitelistInfo";

export function SourceStepContainer() {
  const { state, setState } = useDataSourceStateContext();
  const { onGoToStep } = useStepsContext();
  const integrations = useIntegrationsMap();
  const toast = useToast();
  const mixpanel = useMixpanel();

  const [fetchConnectionSettings, { loading }] =
    useEltConnectionSettingsLazyQuery();
  return (
    <>
      <ConnectionSlideOverContextProvider
        abilityFilter="EltSourceConnection"
        integrationId={state.source.value.integrationId}
      >
        <ConnectionViews
          integrationId={state.source.value.integrationId}
          onConnectionAdded={async (connection) => {
            setState(
              produce(state, (draft) => {
                const sourceValues = {
                  value: {
                    integrationId: connection.integrationId,
                    sourceId: connection.id,
                  },
                  isValid: true,
                  isDirty: false,
                };

                const currentSourceId = state.source.value.sourceId;
                if (currentSourceId && currentSourceId !== connection.id) {
                  // Changing source means we need to reset state in all other steps
                  const newState = createInitialState();
                  newState.source = sourceValues;
                  return newState;
                } else {
                  draft.source = sourceValues;
                }
              }),
            );

            const { data, error } = await fetchConnectionSettings({
              variables: {
                input: {
                  id: connection.id,
                },
              },
            });

            if (data === undefined && error) {
              toast(
                `Connection failed for "${connection.label}"`,
                error.message,
                "error",
              );
              onGoToStep("source");
              // Reset the state to clear the connection
              setState(createInitialState());
              return;
            }

            // A connector has been selected, so now the sync setup will be begin.
            mixpanel.track("Data Source Setup Started");
            mixpanel.time_event("Data Source Setup Completed Step Viewed");

            // Figure out if we need to go to the connection settings step
            // or directly to the select tables step
            if (
              data?.getEltSettings.connectionSettings?.required ||
              integrations.get(connection.integrationId)?.supportsStartDate
            ) {
              onGoToStep("source-settings");
            } else {
              onGoToStep("tables");
            }
          }}
        />
      </ConnectionSlideOverContextProvider>
      {loading && (
        <Center className="absolute inset-0 z-10 bg-white/70 dark:bg-black/30">
          <LoadingSpinner />
        </Center>
      )}
    </>
  );
}

function ConnectionViews(props: {
  integrationId?: string;
  onConnectionAdded: (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
  ) => void;
}) {
  const { setState } = useDataSourceStateContext();
  const { integration, setIntegration } = useContext(
    ConnectionSlideOverContext,
  );
  const toast = useToast();
  const mixpanel = useMixpanel();

  const handleConnectionAdded = (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
    usingExistingConnection: boolean = false,
  ) => {
    props.onConnectionAdded(connection);
    mixpanel.track("Connector Setup Succeeded", {
      connection,
      integration_id: connection.integrationId,
      using_existing_connection: usingExistingConnection,
    });
  };

  const { loading, createConnection } = useCreateConnection({
    onSuccess(connection) {
      handleConnectionAdded(connection);
    },
    onError(error, integrationId) {
      toast("Connector not created", error.message, "error");
      mixpanel.track("Connector Setup Failed", {
        error: error.message,
        integration_id: integrationId,
      });
    },
  });

  return (
    <div className="flex h-full flex-col">
      <div className="mb-4">
        <ConnectorsLimitAlert />
      </div>
      {integration === null ? (
        <SelectConnecterView
          onConnectionAdded={(connection) => {
            handleConnectionAdded(connection, true);
          }}
        />
      ) : (
        <ConfigureConnectorView
          integration={integration}
          onSubmit={(data) => {
            createConnection(integration, data);
          }}
          isLoading={loading}
          onCancel={() => {
            setIntegration(null);
            setState(createInitialState());
          }}
        />
      )}
    </div>
  );
}

function SelectConnecterView(props: {
  onConnectionAdded: (
    connection: Pick<Connection, "id" | "label" | "integrationId">,
  ) => void;
}) {
  const { state, setState } = useDataSourceStateContext();
  const { initialFocusRef } = useDataSourceStepContext();
  const { setIntegration } = useContext(ConnectionSlideOverContext);
  const mixpanel = useMixpanel();
  const trackDebounced = useDebouncedCallback(mixpanel.track, 500);

  const dwh = useDataWarehouseContext();
  const options = useConnectorOptions({ dwIntegration: dwh.integration });

  const { validateLimitReached, limitReachedDialog } =
    useConnectorsLimitReachedUpgradeDialog();

  const handleIntegrationSelected = (option: ConnectorOptionType) => {
    validateLimitReached(option.integration.id, () => {
      setIntegration(option.integration);
      setState(
        produce(state, (draft) => {
          draft.source.value.integrationId = option.integration.id;
        }),
      );
    });
  };

  return (
    <Connectors
      options={options}
      enableSelectExistingConnection
      abilityFilter="EltSourceConnection"
      // Return existing connection immediately
      onSelectConnection={props.onConnectionAdded}
      // Otherwise, open the connection configuration
      onSelectIntegration={handleIntegrationSelected}
      onOptionsFiltered={(filteredOptions, filters) => {
        if (filters.searchText.trim() === "") {
          return;
        }
        trackDebounced("Connection Options Filtered", {
          resultCount: filteredOptions.length,
          abilityFilter: filters.abilityFilter,
          searchText: filters.searchText.trim().toLowerCase(),
        });
      }}
    >
      {({ options, filters }) => (
        <div className="flex h-full flex-1 flex-col gap-3 overflow-hidden">
          <div className="px-6 py-1">
            <SearchFilter
              ref={
                initialFocusRef as
                  | MutableRefObject<HTMLInputElement | null>
                  | undefined
              }
            />
          </div>
          <div className="flex flex-col gap-6 overflow-auto px-6 pb-8 pt-1">
            {!filters.searchText && (
              <RecommendedConnections
                abilityFilter="EltSourceConnection"
                onSelectIntegration={handleIntegrationSelected}
              >
                {({ options }) => (
                  <div>
                    {options.length > 0 && (
                      <>
                        <SectionHeading className="mb-2">
                          Recommendations
                        </SectionHeading>
                        <div className="grid gap-4 xs:grid-cols-2 md:grid-cols-3">
                          {options.map((option) => {
                            return (
                              <RecommendedConnectorOption
                                key={option.value}
                                option={option}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </RecommendedConnections>
            )}
            <div>
              <SectionHeading className="mb-2">All Connectors</SectionHeading>
              <div className="grid gap-4 sm:grid-cols-2">
                {options.map((option) => (
                  <ConnectorOption
                    key={option.value}
                    option={option}
                    showAbilityLabels={false}
                  />
                ))}
              </div>
            </div>
            <div className="my-8 flex flex-col items-center">
              <TextMuted className="mb-2 block text-center">
                Didn't find what you are looking for?
              </TextMuted>
              <RequestConnector />
            </div>
          </div>
          {limitReachedDialog()}
        </div>
      )}
    </Connectors>
  );
}

function ConfigureConnectorView(props: {
  integration: IntegrationType;
  onSubmit: (data: any) => void;
  isLoading: boolean;
  onCancel: () => void;
}) {
  const { features } = useWeldPlan();

  const formMethods = useConnectionForm(props.integration);
  const mixpanel = useMixpanel();

  useMountEffect(() => {
    mixpanel.time_event("Connector Setup Succeeded");
    mixpanel.track("Connector Setup Form Viewed", {
      integration_id: props.integration.id,
    });
  });

  return (
    <FormProvider {...formMethods}>
      <Content>
        <CustomConnectorFeatureGuard
          integrationId={props.integration.id}
          fallback={() => (
            <div className="mx-auto w-full max-w-lg rounded border border-gray-200 p-8 dark:border-gray-700">
              <CustomConnectorTeaser />
            </div>
          )}
        >
          <form
            id="integration-form"
            onSubmit={formMethods.handleSubmit(props.onSubmit)}
            className="flex h-full flex-col"
          >
            <ConnectionForm
              integration={props.integration}
              isLoading={props.isLoading}
            />
            {showWhitelistInfo(props.integration.id) && (
              <IPWhitelistInfo integrationId={props.integration.id} />
            )}
          </form>
        </CustomConnectorFeatureGuard>
      </Content>
      <Footer className="flex-row-reverse">
        <ConnectionFormSubmitButton
          integration={props.integration}
          isLoading={props.isLoading}
          isDisabled={
            isCustomConnector(props.integration.id) &&
            !features.customConnectorEnabled
          }
        />
        <NavButton
          colorScheme="secondary"
          variant="ghost"
          onClick={() => props.onCancel()}
        >
          Back
        </NavButton>
      </Footer>
    </FormProvider>
  );
}

const showWhitelistInfo = (integrationId: string) => {
  const dbConnectors = [
    //My-SQL
    "mysql",
    "adobe-commerce",
    "amazon-aurora-mysql",
    "mysql-on-amazon-rds",
    "mysql-on-google-cloud-sql",
    "mysql-on-azure-database",
    "wordpress",
    "woocommerce",
    "yoast-seo",
    "wordfence-security",
    "typo-three",
    "spree-commerce",
    "os-commerce",
    "presta-shop",
    "magento",
    "magento-on-amazon-rds",
    "plausible-analytics",
    "memberpress",
    "joomla",
    "invision-community",
    "loaded-commerce",
    "monster-insights",
    "contact-form-seven",

    //MS-SQL
    "mssql",
    "sql-server-on-rds",
    "microsoft-dynamics-365",

    //Postgres
    "postgresql",
    "supabase",
    "amazon-aurora-postgresql",
    "heroku-postgres",
    "phoenix-via-postgresql",
    "postgresql-on-azure-database",
    "postgresql-on-google-cloud",
    "postgresql-on-rds",

    //MongoDB
    "mongodb",
    "meteor-via-mongodb",
    "mongodb-sharded-cluster",
    "node-bb",
  ];

  return dbConnectors.includes(integrationId);
};
