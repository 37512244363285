import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "@/helpers/classNames";
import { PropsWithChildren, useState } from "react";

export default function DataBox(
  props: PropsWithChildren<{
    collapsed?: boolean;
    collapsible?: boolean;
    header: string;
    paddingless?: boolean;
    subheader?: string;
    action?: JSX.Element;
    loading?: boolean;
  }>,
) {
  const {
    children,
    collapsed = false,
    collapsible = false,
    header,
    paddingless = false,
    subheader,
    action,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <div className="flex-1">
      <div className="">
        <div
          className={classNames(
            !isCollapsed && "border-b",
            "text-gray-500 dark:border-gray-700 dark:text-white",
          )}
        >
          <div className="flex items-center justify-between">
            <div className="py-4">
              <p className="text-gray-800 dark:text-white">{header}</p>
              {subheader && (
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {subheader}
                </p>
              )}
            </div>
            <div>
              {action && action}
              {collapsible && (
                <span
                  className="flex cursor-pointer items-center border-l py-4 dark:border-gray-700"
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <ChevronDownIcon
                    className={classNames(
                      !isCollapsed && "rotate-180",
                      "h-5 transform transition-transform dark:text-white",
                    )}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        {!isCollapsed && (
          <div className={classNames(!paddingless && "py-4")}>{children}</div>
        )}
      </div>
    </div>
  );
}

export function DetailItem({
  title,
  children,
  multi = false,
}: PropsWithChildren<{
  title: string;
  multi?: boolean;
}>) {
  return (
    <div className={`${multi ? "" : "flex"} items-center`}>
      <div
        className={`${
          multi ? "mb-4" : "w-48"
        } shrink-0 whitespace-nowrap text-sm text-gray-700 dark:text-gray-400`}
      >
        {title}
        {multi ? ":" : ""}
      </div>
      <div className="grow overflow-hidden text-sm text-gray-900 dark:text-white">
        {children}
      </div>
    </div>
  );
}

export function Empty(props: PropsWithChildren<{}>) {
  return (
    <div className="flex h-32 flex-col items-center justify-center text-center text-gray-400">
      {props.children}
    </div>
  );
}
