import { useDebouncedCallback } from "use-debounce";

import PageHeader from "@/components/elements/PageHeader";
import { SectionHeading, TextMuted } from "@/components/elements/Typography";
import Content from "@/components/elements/layout/Content";
import Breadcrumbs from "@/components/modules/Breadcrumbs";
import { ConnectorsLimitAlert } from "@/components/modules/PlanAlerts";
import {
  ConnectorOption as ConnectorOptionType,
  useConnectorOptions,
} from "@/features/connectors";
import { useConnectorsLimitReachedUpgradeDialog } from "@/features/subscription";
import { Ability } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";
import {
  AbilityFilter,
  ConnectorOption,
  Connectors,
  SearchFilter,
} from "@/widgets/connectors";
import {
  RecommendedConnections,
  RecommendedConnectorOption,
} from "@/widgets/recommended-connectors";
import { RequestConnector } from "@/widgets/request-connector";
import { Search, useNavigate, useSearch } from "@tanstack/react-location";

export default function NewConnectionLayout() {
  const navigate = useNavigate();
  const search = useSearch<Search<Partial<{ ability: Ability }>>>();
  const mixpanel = useMixpanel();
  const trackDebounced = useDebouncedCallback(mixpanel.track, 500);

  const dwh = useDataWarehouseContext();
  const options = useConnectorOptions({ dwIntegration: dwh.integration });

  const { validateLimitReached, limitReachedDialog } =
    useConnectorsLimitReachedUpgradeDialog();

  const handleIntegrationSelected = (option: ConnectorOptionType) => {
    validateLimitReached(option.integration.id, () => {
      navigate({
        to: `./${option.integration.id}`,
        search: option.isCommunity
          ? {
              name: option.label,
            }
          : undefined,
      });
    });
  };

  return (
    <Content>
      <PageHeader.Container>
        <Breadcrumbs />
      </PageHeader.Container>
      <div className="mb-4">
        <ConnectorsLimitAlert />
      </div>
      <Connectors
        options={options}
        abilityFilter={search.ability}
        onAbilityFilterChange={(ability) => {
          navigate({
            search: {
              ability: ability ?? undefined,
            },
          });
        }}
        onSelectIntegration={handleIntegrationSelected}
        onOptionsFiltered={(filteredOptions, filters) => {
          if (filters.searchText.trim() === "") {
            return;
          }
          trackDebounced("Connection Options Filtered", {
            resultCount: filteredOptions.length,
            abilityFilter: filters.abilityFilter,
            searchText: filters.searchText.trim().toLowerCase(),
          });
        }}
      >
        {({ options, filters }) => (
          <div className="flex flex-col gap-5">
            <div className="flex gap-4">
              <SearchFilter className="w-full max-w-xs" autoFocus />
              <AbilityFilter />
            </div>
            {!filters.searchText && (
              <RecommendedConnections
                abilityFilter={search.ability}
                onSelectIntegration={handleIntegrationSelected}
              >
                {({ options }) => (
                  <div>
                    {options.length > 0 && (
                      <>
                        <SectionHeading className="mb-2">
                          Recommendations
                        </SectionHeading>
                        <div className="grid gap-4 xs:grid-cols-2 lg:grid-cols-3">
                          {options.map((option) => {
                            return (
                              <RecommendedConnectorOption
                                key={option.value}
                                option={option}
                                showAbilityLabels
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </RecommendedConnections>
            )}
            <div>
              <SectionHeading className="mb-2">All Connectors</SectionHeading>
              <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {options.map((option) => (
                  <ConnectorOption
                    key={option.value}
                    option={option}
                    showAbilityLabels
                  />
                ))}
              </div>
            </div>
            <div className="my-8 flex flex-col items-center">
              <TextMuted className="mb-2 block text-center">
                Didn't find what you are looking for?
              </TextMuted>
              <RequestConnector />
            </div>
          </div>
        )}
      </Connectors>
      {limitReachedDialog()}
    </Content>
  );
}
