import { atom, useAtom } from "jotai";
import { capitalize } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DependencyType,
  ListModelsQuery,
  MaterializationType,
  MetricColumn,
  MetricColumnType,
  QueryDependencyInput,
} from "@/apollo/types";
import { ReactComponent as EdIcon } from "@/assets/ed.svg";
import { Button, TabButton } from "@/components/elements/Button";
import { ButtonCard } from "@/components/elements/ButtonCard";
import { CodeDisplay } from "@/components/elements/CodeDisplay";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { Modal, ModalBody, ModalHeader } from "@/components/elements/Modal";
import Tooltip from "@/components/elements/Tooltip";
import TableIcon from "@/components/icons/TableIcon";
import { EditorIcon } from "@/components/icons/outline";
import { useOpenModelInTab } from "@/components/modules/ModelTabs";
import { PreviewDWData } from "@/components/modules/PreviewData/PreviewDataModal";
import { Checkbox } from "@/components/primitives/Checkbox";
import cn from "@/helpers/classNames";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCreateDraftModel } from "@/pages/ModelTool/QueryEditor/useModelDraft";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useAssistantSidebar } from "@/pages/ModelTool/model-generator/assistant";

import { useCurrentTemplate } from "./TemplatesProvider";
import { useTemplateConfig } from "./useTemplateConfig";

type Props = {};

type OpenMetric = {
  open: boolean;
  selectedModel?: ListModelsQuery["models"][0];
};

const MetricsDescriptionOpenAtom = atom<OpenMetric>({ open: false });
export const useOpenMetricsDescription = () => {
  const [, setOpen] = useAtom(MetricsDescriptionOpenAtom);
  return useCallback(
    (newState: OpenMetric) => {
      setOpen(newState);
    },
    [setOpen],
  );
};

const CoreMetricsDescription = (props: Props) => {
  const [state, setSelectedMetric] = useAtom(MetricsDescriptionOpenAtom);
  const template = useCurrentTemplate();
  const { config } = useTemplateConfig();

  const { models } = useListModels();

  const templateMetrics = useMemo(() => {
    return config?.metrics.map((metric) => {
      const model = models.find(
        (m) => m.templateItemId === metric.config.templateItemId,
      );
      return {
        metric,
        model,
      };
    });
  }, [config?.metrics, models]);

  return (
    <Modal
      isOpen={state.open}
      onClose={() => {
        setSelectedMetric({ open: false });
      }}
      size="lg"
    >
      <ModalHeader>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => {
              setSelectedMetric({ open: true });
            }}
            className="hover:underline"
            tabIndex={-1}
          >
            {capitalize(template.name)} Metrics
          </button>
          {state.selectedModel && (
            <>
              <Slash />
              <div className="flex items-center space-x-2">
                <div className="flex h-6 w-6 flex-none items-center justify-center rounded bg-blue-500">
                  <TableIcon className="h-3 w-3 text-white" />
                </div>
                <div>{state.selectedModel.name}</div>
              </div>
            </>
          )}
        </div>
      </ModalHeader>
      <ModalBody className="space-y-4 pb-8">
        {!state.selectedModel &&
          templateMetrics?.map(({ metric, model }) => {
            return (
              <ButtonCard
                key={metric.name}
                className=" flex w-full items-center space-x-4 overflow-hidden p-4"
                onClick={() =>
                  setSelectedMetric({
                    open: true,
                    selectedModel: model,
                  })
                }
              >
                <div className="flex h-12 w-12 flex-none items-center justify-center rounded bg-blue-500">
                  <TableIcon className="text-white " />
                </div>

                <div className="overflow-hidden text-left">
                  <div className="mb-1 font-bold">{metric.name}</div>
                  <div className="truncate text-sm">{metric.description}</div>
                </div>
              </ButtonCard>
            );
          })}
        {state.selectedModel && (
          <CoreMetricDetails model={state.selectedModel} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default CoreMetricsDescription;

const Slash = () => (
  <svg
    className="h-5 w-5 shrink-0 fill-current text-gray-300"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
);

const CoreMetricDetails = (props: { model: ListModelsQuery["models"][0] }) => {
  const { config, loading: configLoading } = useTemplateConfig();

  const metric = useMemo(
    () =>
      config?.metrics.find(
        (m) => m.config.templateItemId === props.model.templateItemId,
      ),
    [config?.metrics, props.model.templateItemId],
  );

  const [viewing, setViewing] = useState<"columns" | "data" | "ai">("ai");
  useEffect(() => {
    if (configLoading) return;
    if (!metric?.columns) setViewing("data");
  }, [configLoading, metric?.columns, props.model.name]);

  const dependency = useMemo(() => {
    const weldTag = props.model.dwTable?.weldTag || props.model.dwSync?.weldTag;
    if (!weldTag) return null;
    return {
      dwItemId: props.model.id,
      weldTag: weldTag,
      type:
        props.model.materializationType === MaterializationType.View
          ? DependencyType.ModelView
          : DependencyType.MaterializedTable,
    } as QueryDependencyInput;
  }, [props.model]);

  return (
    <div className="">
      <div className="mb-8 rounded bg-gray-100 p-2 text-sm dark:bg-gray-700">
        {metric?.description}
      </div>
      {configLoading && (
        <div>
          <LoadingSpinner />
        </div>
      )}
      {!configLoading && metric?.columns && (
        <div className="mb-4 flex items-center space-x-4">
          <TabButton
            onClick={() => {
              setViewing("ai");
            }}
            active={viewing === "ai"}
            underlineActive
            tabIndex={-1}
          >
            Model with AI
          </TabButton>
          <TabButton
            onClick={() => {
              setViewing("columns");
            }}
            active={viewing === "columns"}
            underlineActive
            tabIndex={-1}
          >
            Query Metrics
          </TabButton>
          <TabButton
            onClick={() => {
              setViewing("data");
            }}
            active={viewing === "data"}
            underlineActive
            tabIndex={-1}
          >
            Preview Data
          </TabButton>
        </div>
      )}
      {viewing === "ai" && dependency && metric && (
        <ModelAi dependency={dependency} metric={metric} />
      )}
      {viewing === "columns" && metric?.columns && dependency && (
        <MetricsSchema columns={metric.columns} dependency={dependency} />
      )}
      {dependency && viewing === "data" && (
        <PreviewMetricData dependency={dependency} modelId={props.model.id} />
      )}
    </div>
  );
};

const PreviewMetricData = (props: {
  dependency: QueryDependencyInput;
  modelId: string;
}) => {
  const limit = 100;
  const openModelInTab = useOpenModelInTab();

  return (
    <div className="">
      <div className="relative flex items-center rounded-t border-l border-r border-t bg-gray-100 pr-2 dark:bg-gray-900">
        <div className="flex-grow">
          <CodeDisplay
            language="sql"
            showLineNumber={false}
            code={`SELECT * FROM {{${props.dependency.weldTag}}} LIMIT ${limit}`}
          />
        </div>
        <Button
          className="flex items-center space-x-1"
          size="sm"
          variant="outline"
          colorScheme="primary"
          onClick={() => {
            openModelInTab({ modelId: props.modelId });
          }}
        >
          <EditorIcon className="h-4 w-4" />
          <div>open in editor</div>
        </Button>
      </div>
      <PreviewDWData dataReference={props.dependency} limit={limit} />
    </div>
  );
};

type Metric = NonNullable<
  ReturnType<typeof useTemplateConfig>["config"]
>["metrics"][0];

const ModelAi = (props: {
  dependency: QueryDependencyInput;
  metric: Metric;
}) => {
  const mixpanel = useMixpanel();
  const openModelInTab = useOpenModelInTab();
  const createDraftModel = useCreateDraftModel();
  const { onOpen: onOpenAssistant } = useAssistantSidebar();

  const handleQueryWithAI = async (question: string) => {
    mixpanel.track("Query Metric With AI", {
      metric: props.dependency.dwItemId,
    });
    const draft = createDraftModel("");
    openModelInTab({ modelId: draft.id, type: "draft" });

    onOpenAssistant({
      input: `Using the following model: {{${props.dependency.weldTag}}}
    
      ${question}`,
    });
  };

  return (
    <div className="">
      <div className="grid grid-cols-3 gap-4">
        {props.metric.aiInsights.map((insight) => {
          return (
            <ButtonCard
              key={insight}
              className="flex justify-start space-x-3 p-4"
              onClick={() => handleQueryWithAI(insight)}
            >
              <EdIcon className="h-5 w-5 flex-none" />
              <div className="text-xs italic">"{insight}"</div>
            </ButtonCard>
          );
        })}
      </div>
    </div>
  );
};

const MetricsSchema = (props: {
  columns: MetricColumn[];
  dependency: QueryDependencyInput;
}) => {
  const [selected, setSelected] = useState<MetricColumn[]>([]);

  const createDraftModel = useCreateDraftModel();
  const openModelInTab = useOpenModelInTab();

  const isValid = useMemo(() => {
    const hasDimension = selected.some(
      (m) => m.columnType === MetricColumnType.Dimension,
    );
    const hasMetric = selected.some(
      (m) => m.columnType !== MetricColumnType.Dimension,
    );
    return hasDimension && hasMetric;
  }, [selected]);

  const mixpanel = useMixpanel();

  const handleBuildQuery = () => {
    if (!isValid) return;

    mixpanel.track("Build Metric Query Clicked", {
      metric: props.dependency.dwItemId,
      selected: selected.map((m) => m.columnName),
    });

    const dimensionColumns = selected
      .filter((m) => m.columnType === MetricColumnType.Dimension)
      .map((m) => m.columnName);

    const metricColumns = selected
      .filter((m) => m.columnType !== MetricColumnType.Dimension)
      .map((metric) => {
        if (metric.columnType === MetricColumnType.MetricAvg)
          return `avg(${metric.columnName}) as ${metric.columnName}`;
        else return `sum(${metric.columnName}) as ${metric.columnName}`;
      });

    const sql = `SELECT
  ${dimensionColumns.join(",\n  ")},
  ${metricColumns.join(",\n  ")}
FROM
  {{${props.dependency.weldTag}}}
GROUP BY
  ${dimensionColumns.join(",\n  ")}
    `;
    const draft = createDraftModel(sql);
    openModelInTab({ modelId: draft.id, type: "draft" });
  };

  const allSelected = props.columns.length === selected.length;

  return (
    <div className="space-y-4">
      <table className="w-full rounded border text-xs">
        <thead>
          <tr>
            <th className="border-b p-2 font-medium">Column name</th>
            <th className="border-b p-2 font-medium">Column type</th>
            <th className="border-b p-2 font-medium">Description</th>
            <th className="border-b p-2">
              <Button
                size="xs"
                variant="ghost"
                className="ml-px flex items-center space-x-2"
                onClick={() => {
                  if (allSelected) {
                    setSelected([]);
                  } else {
                    setSelected([...props.columns]);
                  }
                }}
              >
                <Checkbox checked={allSelected} onChange={(e) => {}} />
                <div>select all</div>
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.columns.map((column) => {
            const isSelected = selected.some(
              (m) => m.columnName === column.columnName,
            );
            return (
              <tr
                key={column.columnName}
                className={cn(
                  "cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700",
                )}
                onClick={() => {
                  if (isSelected) {
                    setSelected(
                      selected.filter(
                        (m) => m.columnName !== column.columnName,
                      ),
                    );
                  } else {
                    setSelected([...selected, column]);
                  }
                }}
              >
                <td className="border-b p-2">{column.columnName}</td>
                <td className="border-b p-2">
                  {column.columnType === MetricColumnType.Dimension
                    ? "Dimension"
                    : "Metric"}
                </td>
                <td className="border-b p-2">{column.description}</td>
                <td className="border-b px-4 py-2">
                  <Checkbox checked={isSelected} onChange={(e) => {}} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Tooltip
        content={
          isValid
            ? "Build a SQL query in the editor with the selected metrics."
            : "Select at least one Dimension and one Metric to create a query on this Core Metric."
        }
      >
        <div>
          <Button
            className="flex w-full items-center space-x-2"
            colorScheme="primary"
            variant="outline"
            disabled={!isValid}
            onClick={() => {
              handleBuildQuery();
            }}
          >
            <EditorIcon className="h-4 w-4" />
            <div>Build query</div>
          </Button>{" "}
        </div>
      </Tooltip>
    </div>
  );
};
