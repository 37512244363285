import { useCallback } from "react";

import {
  PlanName,
  RecurrenceInterval,
  useWeldPlanLazyQuery,
  useWeldPlanQuery,
} from "@/apollo/types";
import {
  useGetPriceByLookupKey,
  usePriceByLookupKey,
} from "@/features/billing/pricing";

export function usePlanData(
  planName: PlanName,
  billingPeriod: RecurrenceInterval,
  currency: string,
) {
  const { data: planData, loading: isLoadingPlan } = useWeldPlanQuery({
    variables: {
      planName,
    },
  });

  const priceLookupKeys = planData?.weldPlan.priceLookupKeys;
  const priceLookupKey =
    billingPeriod === RecurrenceInterval.Year
      ? priceLookupKeys?.yearly
      : priceLookupKeys?.monthly;

  const { price, loading: isLoadingPrice } = usePriceByLookupKey({
    currency: currency,
    lookupKey: priceLookupKey,
  });

  if (planData && price) {
    return {
      loading: false,
      plan: planData.weldPlan,
      price,
    };
  }
  return {
    loading: isLoadingPlan || isLoadingPrice,
    plan: null,
    price: null,
  };
}

export function useGetPlanData() {
  const [fetchWeldPlan] = useWeldPlanLazyQuery();
  const fetchPrice = useGetPriceByLookupKey();

  return useCallback(
    async (
      planName: PlanName,
      billingPeriod: RecurrenceInterval,
      currency: string,
    ) => {
      const { data: planData } = await fetchWeldPlan({
        variables: {
          planName,
        },
      });

      if (!planData) {
        throw new Error("Plan not found");
      }
      if (!planData.weldPlan.priceLookupKeys) {
        throw new Error("Price lookup keys not found");
      }

      const priceLookupKeys = planData.weldPlan.priceLookupKeys;
      const priceLookupKey =
        billingPeriod === RecurrenceInterval.Year
          ? priceLookupKeys?.yearly
          : priceLookupKeys?.monthly;

      const price = await fetchPrice({
        currency: currency,
        lookupKey: priceLookupKey,
      });

      if (!price) {
        throw new Error("Price not found");
      }

      return {
        plan: planData.weldPlan,
        price,
      };
    },
    [fetchWeldPlan, fetchPrice],
  );
}
