import { LinkWithSlug } from "routes";

import {
  Notification,
  NotificationLevel,
  NotificationType,
} from "@/apollo/types";
import {
  Button,
  ButtonGroup,
  PrimaryButton,
} from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import { UpgradeNowButton } from "@/components/modules/UpgradeNowButton";
import { UsageLimitConfiguratorModal } from "@/features/billing";
import {
  useAccountNotifications,
  useRemoveNotification,
} from "@/features/notifications";
import { NotificationDetailModal } from "@/features/notifications/components/NotificationDetailModal";
import { useWeldPlan } from "@/features/subscription";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { ComponentProps } from "@tw-classed/react";

import { WorkspaceAlert, WorkspaceAlertResolver } from "../useWorkspaceAlerts";

function ResolveButton({
  notification,
  ...props
}: ComponentProps<typeof Button> & {
  notification: Notification;
}) {
  const toast = useToast();
  const mixpanel = useMixpanel();

  const [removeNotification, loading] = useRemoveNotification({
    onCompleted: () => {
      toast(
        "Success",
        "Syncs that were previously blocked by this issue will be retried",
        "success",
      );
    },
    onError: () => {
      toast(
        "Error",
        "An error occured while marking this issue as resolved. Please try again later.",
        "error",
      );
    },
  });

  return (
    <Tooltip content="Click to mark this issue as resolved. Upon doing so, any syncs that were previously blocked by this issue will be retried.">
      <PrimaryButton
        size="xs"
        onClick={() => {
          mixpanel.track("Account Level Notification Resolve Clicked", {
            location: "workspace-banner",
            notificationType: notification.type,
          });
          removeNotification(notification);
        }}
        icon={<CheckIcon />}
        isLoading={loading}
        loadingText="Resolving..."
        {...props}
      >
        Resolve
      </PrimaryButton>
    </Tooltip>
  );
}

export const useAccountLevelNotificationsResolver: WorkspaceAlertResolver =
  () => {
    const { notifications } = useAccountNotifications();

    const [removeNotification] = useRemoveNotification();

    function createAlertProps(notification: Notification): WorkspaceAlert {
      const status = (
        {
          [NotificationLevel.Info]: "info",
          [NotificationLevel.Warning]: "warning",
          [NotificationLevel.Error]: "error",
        } as Record<NotificationLevel, WorkspaceAlert["status"]>
      )[notification.notificationLevel];

      const defaultProps = {
        id: notification.id,
        type: notification.type,
        updatedAt: new Date(notification.updatedAt),
        status,
        message: notification.messageData?.title ?? notification.message,
        action: notification.isDismissable ? (
          <ResolveButton notification={notification} />
        ) : undefined,
      };

      switch (notification.type) {
        case NotificationType.BigqueryBilling: {
          return {
            ...defaultProps,
            action: (
              <ButtonGroup size="xs" className="flex gap-4">
                <Button
                  as="a"
                  target="_blank"
                  href="https://weld.app/docs/data-warehouse/google-bigquery/troubleshooting#billing-disabled"
                  variant="solid"
                  colorScheme="secondary"
                  icon={<InformationCircleIcon />}
                  className="border-black/20 dark:border-white/20"
                >
                  More info
                </Button>
                {defaultProps.action}
              </ButtonGroup>
            ),
          };
        }
        case NotificationType.FreeTrialExpired: {
          return {
            ...defaultProps,
            title: "Hey, your free trial is over.",
            message:
              "To keep using our paid features, upgrade to one of our paid plans.",
            action: (
              <UpgradeNowButton size="xs" source="trial expired banner" />
            ),
          };
        }
        case NotificationType.MaxRowCountExceeded: {
          return {
            ...defaultProps,
            title: "Usage limit reached",
            message:
              "Your account has reached the monthly usage limit you have specified. All syncs have been paused for the remainder of the month.",
            action: <MaxRowCountExceededActions />,
            isDismissable: true,
          };
        }
        case NotificationType.ManagedBqUsageExceededSoftLimit: {
          return {
            ...defaultProps,
            action: (
              <Button
                as={LinkWithSlug}
                to={`/settings/data-warehouse`}
                colorScheme="primary"
                variant="solid"
                size="xs"
              >
                Migrate Datawarehouse
              </Button>
            ),
            isDismissable: true,
          };
        }
        case NotificationType.MandatoryAccountTrialStarted: {
          return {
            ...defaultProps,
            action: (
              <MandatoryAccountTrialStartedActions
                notification={notification}
              />
            ),
            isDismissable: true,
            onDismiss: () => {
              removeNotification(notification);
            },
          };
        }
        case NotificationType.OverdueInvoiceServiceSuspension:
        case NotificationType.OverdueInvoiceReminder: {
          return {
            ...defaultProps,
            message: notification.messageData.title,
            action: (
              <Button
                as={LinkWithSlug}
                to="/settings/billing"
                hash="invoices"
                colorScheme="primary"
                variant="solid"
                size="xs"
              >
                View invoice
              </Button>
            ),
            isDismissable: defaultProps.status !== "error",
          };
        }
        default: {
          return defaultProps;
        }
      }
    }
    return notifications.map(createAlertProps);
  };

function MaxRowCountExceededActions() {
  const account = useCurrentAccount();
  const plan = useWeldPlan();
  const usageLimitDisclosure = useDisclosure();
  const mixpanel = useMixpanel();
  return (
    <>
      <Button
        onClick={() => {
          usageLimitDisclosure.onOpen();
          mixpanel.track("Usage Limit Adjust Clicked", {
            location: "workspace-banner-max-rows-exceeded",
          });
        }}
        colorScheme="primary"
        variant="solid"
        size="xs"
      >
        Adjust limit
      </Button>
      <UsageLimitConfiguratorModal
        {...usageLimitDisclosure}
        planName={plan.name}
        initialLimit={account.accountSettings.maxBillableRows ?? undefined}
      />
    </>
  );
}

function MandatoryAccountTrialStartedActions(props: {
  notification: Notification;
}) {
  const disclosure = useDisclosure();
  return (
    <>
      <ButtonGroup size="xs" className="flex gap-4">
        <Button
          onClick={() => disclosure.onOpen()}
          variant="solid"
          colorScheme="secondary"
          icon={<InformationCircleIcon />}
          className="border-black/20 dark:border-white/20"
        >
          More info
        </Button>
      </ButtonGroup>
      <NotificationDetailModal
        notification={props.notification}
        {...disclosure}
      />
    </>
  );
}
