import {
  PlanLimitsStatus,
  useCanAddConnectionLazyQuery,
  usePlanLimitsStatusLazyQuery,
} from "@/apollo/types";
import { ChangePlanDialog } from "@/features/billing";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useCallback, useRef } from "react";

function useLimitReachedUpgradeDialog(options: {
  limitSelector: (status: PlanLimitsStatus) => boolean;
  title: string;
  message: string;
}) {
  const optionsRef = useRef(options);

  const [fetchPlanLimitsStatus] = usePlanLimitsStatusLazyQuery();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const validateLimitReached = useCallback(
    async (onAllowCallback?: () => void, onDisallowCallback?: () => void) => {
      const resp = await fetchPlanLimitsStatus();
      const status = resp.data?.getPlanLimitsStatus;
      if (!status) {
        onAllowCallback?.();
        return {
          isLimitReached: false,
        };
      }
      const isLimitReacted = optionsRef.current.limitSelector(status);
      if (isLimitReacted) {
        onOpen();
        onDisallowCallback?.();
      } else {
        onAllowCallback?.();
      }
      return {
        isLimitReached: isLimitReacted,
      };
    },
    [fetchPlanLimitsStatus, onOpen],
  );

  const limitReachedDialog = useCallback(() => {
    return (
      <ChangePlanDialog
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        title={optionsRef.current.title}
        message={optionsRef.current.message}
      />
    );
  }, [isOpen, onClose]);

  return {
    validateLimitReached,
    limitReachedDialog,
  };
}

export function useConnectorsLimitReachedUpgradeDialog() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [testCanAddConnection] = useCanAddConnectionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const validateLimitReached = useCallback(
    async (
      integrationId: string,
      onAllowCallback?: () => void,
      onDisallowCallback?: () => void,
    ) => {
      const { data } = await testCanAddConnection({
        variables: {
          integrationId,
        },
      });
      if (data == null) {
        onAllowCallback?.();
        return {
          isLimitReached: false,
        };
      }
      if (data.canAddConnection.result) {
        onAllowCallback?.();
        return {
          isLimitReached: false,
        };
      } else {
        onOpen();
        onDisallowCallback?.();
        return {
          isLimitReached: true,
        };
      }
    },
    [onOpen, testCanAddConnection],
  );

  const limitReachedDialog = useCallback(() => {
    return (
      <ChangePlanDialog
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        title="Upgrade to add more connectors"
        message="You have reached the limit of connectors allowed for your current plan. Consider upgrading your plan to add more connectors."
      />
    );
  }, [isOpen, onClose]);

  return {
    validateLimitReached,
    limitReachedDialog,
  };
}

export function useUsersLimitReachedUpgradeDialog() {
  return useLimitReachedUpgradeDialog({
    limitSelector: (status) => status.isUsersLimitReached,
    title: "Upgrade to add more users",
    message:
      "You have reached the limit of users allowed for your current plan. Consider upgrading your plan to add more users.",
  });
}
