import { RadioGroup } from "@headlessui/react";
import {
  CancellationFeedback,
  useCancelWeldSubscriptionMutation,
  useCurrentSubscriptionStateLazyQuery,
  useCurrentUserWithAccountsLazyQuery,
  useCurrentWeldPlanLazyQuery,
  useStripeSubscriptionLazyQuery,
} from "@/apollo/types";
import { PrimaryButton, SecondaryButton } from "@/components/elements/Button";
import { FormatTime } from "@/components/elements/FormatTime";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import Toggle from "@/components/elements/Toggle";
import { TextMuted } from "@/components/elements/Typography";
import { TextArea } from "@/components/primitives/input/TextArea";
import FieldLabel from "@/components/primitives/InputLabel";
import { useBillingDetailsContext } from "@/features/billing/useBillingDetails";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";
import { useToast } from "@/providers/ToastProvider";
import { ComponentProps, useState } from "react";

const feedbackOptions = [
  {
    value: CancellationFeedback.TooExpensive,
    label: "It's too expensive",
  },
  {
    value: CancellationFeedback.MissingFeatures,
    label: "Lacking some essential features",
  },
  {
    value: CancellationFeedback.SwitchedService,
    label: "I found a better service",
  },
  {
    value: CancellationFeedback.Unused,
    label: "I don't use the service enough",
  },
  {
    value: CancellationFeedback.CustomerService,
    label: "Customer service was less than expected",
  },
  {
    value: CancellationFeedback.TooComplex,
    label: "Ease of use was less than expected",
  },
  {
    value: CancellationFeedback.LowQuality,
    label: "Too many technical problems",
  },
  {
    value: CancellationFeedback.Other,
    label: "Other reason",
  },
];

export function CancelSubscriptionDialog(props: ComponentProps<typeof Modal>) {
  const revalidateCache = useRevalidateSubscriptionCache();
  const toast = useToast();
  const mixpanel = useMixpanel();

  const [cancelSubscription, { called: isCancellingSubscription, reset }] =
    useCancelWeldSubscriptionMutation({
      onCompleted: async () => {
        await revalidateCache();
        toast(
          "Your subscription has been cancelled.",
          "You will be able to use Weld until the end of your current billing period.",
          "success",
        );
        props.onClose();
      },
      onError: (error) => {
        toast(
          "An error occurred while cancelling your subscription. Please try again later.",
          error.message,
          "error",
        );
        reset();
      },
    });
  const { stripeAccount, currentPeriodEndDate, isMeteredUsagePlan } =
    useBillingDetailsContext();

  const [feedback, setFeedback] = useState<CancellationFeedback | null>(null);
  const [comment, setComment] = useState<string>("");
  const [limitUsageToCurrentTier, setLimitUsageToCurrentTier] = useState(false);

  return (
    <Modal {...props}>
      <ModalHeader>Cancel subcription</ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-6">
        <div className="flex flex-col gap-2 text-sm">
          <p>
            If confirmed, your subscription will be cancelled on{" "}
            {currentPeriodEndDate && (
              <FormatTime
                date={currentPeriodEndDate}
                format="LL"
                className="font-semibold"
              />
            )}
            .
          </p>
          <p>
            We're sorry to see you go! In our continuous effort to enhance our
            services,{" "}
            <span className="font-semibold">
              could you please share more details about your decision to cancel?
            </span>{" "}
            Your valuable feedback will help us better understand your needs and
            improve our offerings for the future.
          </p>
        </div>
        <div className="">
          <RadioGroup value={feedback} onChange={setFeedback}>
            <div className="flex flex-col gap-2">
              {feedbackOptions.map((option) => (
                <RadioGroup.Option value={option.value} key={option.value}>
                  {({ checked }) => (
                    <div key={option.value} className="flex items-center gap-2">
                      <input
                        type="radio"
                        value={option.value}
                        checked={checked}
                        className="form-radio"
                        readOnly
                      />
                      <RadioGroup.Label>{option.label}</RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <div>
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Please share any feedback you have"
          />
        </div>
        {isMeteredUsagePlan && (
          <div>
            <div className="mb-2 flex items-center gap-4">
              <FieldLabel
                className="mb-0 text-sm font-medium"
                htmlFor="limit-usage-to-current-tier"
              >
                Cap usage at current pricing tier
              </FieldLabel>
              <Toggle
                checked={limitUsageToCurrentTier}
                onChange={setLimitUsageToCurrentTier}
                id="limit-usage-to-current-tier"
                className="ml-auto"
              />
            </div>
            <TextMuted as="p" className="text-sm">
              Set a cap aligned with your current pricing tier, ensuring
              predictable costs for the remainder of the billing period
            </TextMuted>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="flex-row-reverse justify-start gap-4">
        <PrimaryButton
          colorScheme="danger"
          onClick={() => {
            if (stripeAccount.stripeSubscription) {
              cancelSubscription({
                variables: {
                  input: {
                    subscriptionId:
                      stripeAccount.stripeSubscription.stripeSubscriptionDetails
                        .id,
                    cancellationDetails: {
                      comment,
                      feedback,
                    },
                    limitUsageToCurrentTier,
                  },
                },
              });
              mixpanel.track("Cancel Subscription Requested", {
                feedback,
                comment,
              });
            }
          }}
          isDisabled={feedback == null}
          isLoading={isCancellingSubscription}
          loadingText="Cancelling ..."
        >
          Cancel subscription
        </PrimaryButton>
        <SecondaryButton onClick={() => props.onClose()}>
          Cancel
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
}

function useRevalidateSubscriptionCache() {
  const account = useCurrentAccount();

  const [fetchUserWithAccounts] = useCurrentUserWithAccountsLazyQuery({
    fetchPolicy: "network-only",
    context: {
      skipXAccountIdHeader: true,
    },
  });

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    fetchPolicy: "network-only",
    variables: { slug: account.slug },
  });

  const [fetchCurrentWeldPlan] = useCurrentWeldPlanLazyQuery({
    fetchPolicy: "network-only",
  });

  const [fetchCurrentSubscriptionState] = useCurrentSubscriptionStateLazyQuery({
    fetchPolicy: "network-only",
  });

  return async () => {
    // Refetch the user data, i.e. accounts with settings, such as max billable rows etc.
    await fetchUserWithAccounts();
    // The detailed Stripe Subscription data, i.e. Stripe Customer and Subscription details
    await fetchStripeSubscription();
    // The Weld plan definitions, i.e. limits, add-ons etc.
    await fetchCurrentWeldPlan();
    // The current subscription status Freemium, Active etc.
    await fetchCurrentSubscriptionState();
  };
}
