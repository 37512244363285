import { BeakerIcon } from "@heroicons/react/24/outline";
import {
  MaterializationType,
  OrchestrationJobFragment,
  OrchestrationWorkflowQuery,
  WorkflowJobStatus,
  WorkflowRunStatus,
} from "@/apollo/types";
import { UtilityButton } from "@/components/elements/Button";
import Tooltip from "@/components/elements/Tooltip";
import dayjs from "dayjs";
import { useOrchestrationFeatureGuard } from "@/features/feature-guards";
import classNames from "@/helpers/classNames";
import { useDisplayCron } from "@/hooks/useCron";
import { getDurationDisplay } from "@/hooks/useDuration";
import useNow from "@/hooks/useNow";
import React, { useMemo } from "react";

import getJobTestRunsWithStatus from "./utility/getJobTestStatus";
import { WorkflowRunStatusDisplayText } from "./WorkflowRunStatusDisplay";
import {
  EtlJobBox,
  IconBox,
  ModelJobBox,
  ReverseEtlJobBox,
} from "./WorkflowTable/SharedComponents";
import {
  OrchestrationJob,
  isReverseEltJob,
  isSourceStreamJob,
} from "./WorkflowTable/useTableRows";

export const WorkflowStatusBar = (props: {
  currentRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["currentRun"];
  nextRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["nextRun"];
  nextRunStartingNow: boolean;
  startUpdateSchedule: () => void;
  cronExpression?: string;
}) => {
  const { currentRun } = props;

  const jobs = useMemo(() => currentRun?.jobs ?? [], [currentRun?.jobs]);
  const tableStats = useMemo(
    () =>
      getJobStats(
        jobs.filter(
          (job) => job.target?.__typename === "MaterializedTableReference",
        ),
      ),
    [jobs],
  );
  const eltStats = useMemo(
    () => getJobStats(jobs.filter(isSourceStreamJob)),
    [jobs],
  );
  const reverseEltStats = useMemo(
    () => getJobStats(jobs.filter(isReverseEltJob)),
    [jobs],
  );
  const modelTestStats = useMemo(() => getModelTestStats(jobs), [jobs]);
  const { isEnabled: showModelTestsResults } = useOrchestrationFeatureGuard();

  const { cronDisplay, cronDisplayVerbose, cronValid } = useDisplayCron(
    props.cronExpression,
  );

  return (
    <div className="rounded-sm border px-5 py-4 dark:border-gray-700">
      <div className="grid grid-cols-2 gap-4 md:grid-flow-col md:grid-cols-3 md:grid-rows-2">
        <WorkflowRunStatusDisplay
          currentRun={props.currentRun}
          nextRun={props.nextRun}
        />
        {props.cronExpression && (
          <div className="max-w-xs">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Schedule
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Tooltip content={cronDisplayVerbose}>
                <div
                  className={classNames(
                    !cronValid && "text-red-600",
                    "text-sm",
                  )}
                >
                  {cronDisplay}
                </div>
              </Tooltip>
              <UtilityButton
                onClick={() => {
                  props.startUpdateSchedule();
                }}
              >
                edit
              </UtilityButton>
            </div>
            <NextRunDueTime timestamp={props.nextRun?.dueAt} />
          </div>
        )}

        <JobTypeStatusDisplay
          title="Table"
          icon={<ModelJobBox materializationType={MaterializationType.Table} />}
          failed={tableStats.failed}
          remaining={tableStats.remaining}
          successful={tableStats.successful}
        />

        <JobTypeStatusDisplay
          title="Data source"
          icon={<EtlJobBox />}
          failed={eltStats.failed}
          remaining={eltStats.remaining}
          successful={eltStats.successful}
        />

        <JobTypeStatusDisplay
          title="Export"
          icon={<ReverseEtlJobBox />}
          failed={reverseEltStats.failed}
          remaining={reverseEltStats.remaining}
          successful={reverseEltStats.successful}
        />
        {showModelTestsResults ? (
          <JobTypeStatusDisplay
            title="Tests"
            icon={<IconBox icon={<BeakerIcon />} />}
            failed={modelTestStats.numFailed}
            remaining={modelTestStats.numRunning}
            successful={modelTestStats.numSuccessful}
          />
        ) : (
          <div className=" relative  opacity-50">
            <JobTypeStatusDisplay
              title={
                <div className="flex items-center space-x-2">
                  <span>Tests</span>
                  <div className="rounded bg-gray-500 px-1 text-xs text-white">
                    Coming soon
                  </div>
                </div>
              }
              icon={<IconBox icon={<BeakerIcon />} />}
              failed={0}
              remaining={0}
              successful={0}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function NextRunDueTime({ timestamp }: { timestamp?: dayjs.Dayjs }) {
  const updateInterval = (() => {
    if (timestamp == null) return 60_000;
    const nextRunTime = dayjs(timestamp);
    const diff = nextRunTime.diff(Date.now(), "seconds");
    if (diff < 60) {
      return 1000;
    }
    if (diff < 10 * 60) {
      return 60_000;
    }
    if (diff < 20 * 60) {
      return 2 * 60_000;
    }
    return 5 * 60_000;
  })();

  const now = useNow(updateInterval);

  const nextRunDue = useMemo(() => {
    if (!timestamp) return null;
    const nextRunTime = dayjs(timestamp);
    return nextRunTime.isBefore(now) ? "now" : nextRunTime.fromNow();
  }, [now, timestamp]);

  if (nextRunDue === null) {
    return null;
  }
  return (
    <div className="text-sm text-gray-600 dark:text-gray-400">
      Next run {nextRunDue}
    </div>
  );
}

const WorkflowRunStatusDisplay = (props: {
  currentRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["currentRun"];
  nextRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["nextRun"];
}) => {
  const nextRunStartingNow = useMemo(
    () =>
      !!props.nextRun?.dueAt && dayjs(props.nextRun?.dueAt).isBefore(dayjs()),
    [props.nextRun?.dueAt],
  );

  const displayRuntime = useMemo(() => {
    if (nextRunStartingNow) return "starting orchestration now";
    if (!props.currentRun?.startedAt) {
      if (!props.nextRun) return "";
      const dueAt = dayjs(props.nextRun.dueAt);
      return `will run in ${dueAt.fromNow()}`;
    }
    const startTime = dayjs(props.currentRun.startedAt);
    const endTime = dayjs(props.currentRun.finishedAt ?? undefined);

    const duration = dayjs.duration(endTime.diff(dayjs(startTime)));

    const isRunning = !props.currentRun.finishedAt;
    if (isRunning) {
      //if still running use humanize to get estimate of time running in words
      return `Running for ${duration.humanize()}`;
    }

    //if finished use getDurationDisplay to get an accurate run time
    const durationDisplay = getDurationDisplay(duration);
    return `Finished in ${durationDisplay}`;
  }, [nextRunStartingNow, props.currentRun, props.nextRun]);

  const displayStatus = useMemo(() => {
    if (nextRunStartingNow) return "Starting now";

    if (!props.currentRun) return "Scheduled";

    return <WorkflowRunStatusDisplayText runStatus={props.currentRun.status} />;
  }, [nextRunStartingNow, props.currentRun]);

  const overAllStatus = useMemo(() => {
    // const nextRunStartingNow =
    //   !!props.nextRun?.dueAt && dayjs(props.nextRun?.dueAt).isBefore(dayjs());
    if (props.nextRun?.status === WorkflowRunStatus.Starting)
      return WorkflowRunStatus.Starting;
    return props.currentRun?.status ?? WorkflowRunStatus.NotStarted;
  }, [props.currentRun?.status, props.nextRun?.status]);

  return (
    <div>
      <div
        className={classNames(
          overAllStatus === WorkflowRunStatus.Running && "text-blue-500",
          overAllStatus === WorkflowRunStatus.Failed &&
            "text-red-500 dark:text-red-600",
          overAllStatus === WorkflowRunStatus.Done &&
            "text-green-400 dark:text-green-600",
          (overAllStatus === WorkflowRunStatus.Starting ||
            overAllStatus === WorkflowRunStatus.NotStarted) &&
            "text-gray-500",
          "text-xl font-medium",
        )}
      >
        {displayStatus}
      </div>
      <div className="text-sm">{displayRuntime}</div>
    </div>
  );
};

function getJobStats(jobs: OrchestrationJobFragment[]) {
  return jobs.reduce<{
    successful: number;
    failed: number;
    remaining: number;
  }>(
    (acc, job) => {
      if (job.status === WorkflowJobStatus.Done) {
        acc.successful++;
      }
      if (job.status === WorkflowJobStatus.Failed) {
        acc.failed++;
      }
      if (
        [
          WorkflowJobStatus.Ready,
          WorkflowJobStatus.Running,
          WorkflowJobStatus.NotReady,
        ].includes(job.status)
      ) {
        acc.remaining++;
      }
      return acc;
    },
    {
      successful: 0,
      failed: 0,
      remaining: 0,
    },
  );
}

function getModelTestStats(jobs: OrchestrationJob[]) {
  return jobs.reduce<{
    numSuccessful: number;
    numFailed: number;
    numRunning: number;
  }>(
    (acc, job) => {
      const { status } = getJobTestRunsWithStatus(job);
      acc.numSuccessful += status.numSuccessful;
      acc.numFailed += status.numFailed;
      acc.numRunning += status.numRunning;
      return acc;
    },
    { numSuccessful: 0, numFailed: 0, numRunning: 0 },
  );
}

type JobTypeStatusDisplayProps = {
  title: React.ReactNode;
  icon: React.ReactNode;
  successful: number;
  failed: number;
  remaining: number;
};

function JobTypeStatusDisplay({
  title,
  icon,
  successful = 0,
  failed = 0,
  remaining = 0,
}: JobTypeStatusDisplayProps) {
  return (
    <div className="space-y-1 text-sm">
      <div className="text-gray-500 dark:text-gray-400">{title}</div>
      <div className="flex space-x-2">
        {icon}
        <div className="text-xs font-medium text-gray-500">
          <div className={classNames(successful > 0 && "text-green-500")}>
            {successful} successful
          </div>
          <div className={classNames(failed > 0 && "text-red-500")}>
            {failed} failed
          </div>
          {!!remaining && (
            <div className="text-gray-600 dark:text-gray-400">
              {remaining} remaining
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
