import { Tab } from "@headlessui/react";
import { motion } from "framer-motion";
import classNames from "@/helpers/classNames";
import React, { Fragment } from "react";

type ExtractProps<T> = T extends React.ComponentType<infer P> ? P : T;

type WeldTabProps = ExtractProps<typeof Tab> & {
  icon?: React.ReactNode;
  buttonSize?: "sm" | "md";
};

export function WeldTab({ icon, buttonSize = "md", ...props }: WeldTabProps) {
  return (
    <Tab as={Fragment} {...props}>
      {({ selected }) => (
        <button
          className={classNames(
            selected
              ? "cursor-default text-blue-500"
              : "cursor-pointer hover:text-blue-500 dark:text-white dark:hover:text-blue-500",
            "relative inline-flex items-center gap-1 text-sm focus-visible:outline-none",
            buttonSize === "md" ? "font-medium" : "",
          )}
        >
          {icon &&
            React.cloneElement(icon as any, {
              className: "shrink-0 w-4 h-4",
            })}
          {typeof props.children === "function"
            ? props.children({ selected })
            : props.children}

          {selected ? (
            <motion.div
              className="absolute -bottom-[2px] left-0 right-0 h-[2px] rounded-full bg-blue-500"
              layoutId="underline"
            />
          ) : null}
        </button>
      )}
    </Tab>
  );
}
