import { Menu } from "@headlessui/react";
import { ShareIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "@tanstack/react-location";
import { OrchestrationWorkflowQuery, useEltSyncsQuery } from "@/apollo/types";
import DefaultTable from "@/components/elements/DefaultTable";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import TableIcon from "@/components/icons/TableIcon";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";
import classNames from "@/helpers/classNames";
import React, { useMemo, useState } from "react";
import { CellProps, Column } from "react-table";
import { useNavigateWithSlug } from "routes";

import EditOrchestrationSchedule from "./EditOrchestrationSchedule";
import OrchestrationGraph from "./OrchestrationGraph/OrchestrationGraph";
import getJobTestRunsWithStatus from "./utility/getJobTestStatus";
import {
  WorkflowJobStatusBadge,
  WorkflowJobStatusDisplay,
} from "./WorkflowJobStatusDisplay";
import { WorkflowStatusBar } from "./WorkflowStatusBar";
import { EltSourceStreamItemColumn } from "./WorkflowTable/EltSourceStreamItemColumn";
import { EltSyncItemColumn } from "./WorkflowTable/EltSyncItemColumn";
import { ModelItemColumn } from "./WorkflowTable/ModelItemColumn";
import { ReverseEltSyncItemColumn } from "./WorkflowTable/ReverseEltSyncItemColumn";
import {
  Row,
  isEltSyncRow,
  isModelRow,
  isReverseEltSyncRow,
  useTableRows,
} from "./WorkflowTable/useTableRows";

type Props = {
  workflowId: string;
  isCurrentWorkflow: boolean;
  cronExpression?: string;
  currentRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["currentRun"];
  nextRun: NonNullable<
    OrchestrationWorkflowQuery["orchestrationWorkflow"]
  >["nextRun"];
  nextRunStartingNow: boolean;
  isHistoric?: boolean;
};

export const Workflow = (props: Props) => {
  const [updatingSchedule, setUpdatingSchedule] = useState(false);

  const [viewMode, setViewMode] = useState<"table" | "graph">("table");

  const tableRows: Row[] = useTableRows({
    workflowId: props.workflowId,
    jobs: props.currentRun?.jobs,
    isHistorical: props.isHistoric,
    groupSourceStreams: true,
  });

  return (
    <>
      <div className="relative space-y-8">
        <WorkflowStatusBar
          nextRunStartingNow={props.nextRunStartingNow}
          startUpdateSchedule={() => setUpdatingSchedule(true)}
          currentRun={props.currentRun}
          nextRun={props.nextRun}
          cronExpression={props.cronExpression}
        />

        <ViewModeButton
          disabled={!props.currentRun}
          viewMode={viewMode}
          onChange={setViewMode}
        />

        <div className="relative">
          {viewMode === "table" && (
            <OrchestrationTable
              isCurrentWorkflow={props.isCurrentWorkflow}
              tableRows={tableRows}
              workflowRunId={props.currentRun?.id}
              hasRunFirstTime={!!props.currentRun}
            />
          )}
          {viewMode === "graph" && (
            <div style={{ height: `56rem` }}>
              <OrchestrationGraph jobs={props.currentRun?.jobs ?? []} />
            </div>
          )}
          {props.nextRunStartingNow && (
            <div className="absolute inset-0 z-10 flex items-center justify-center">
              <div className="relative z-10 flex items-center space-x-2 rounded-sm border bg-gray-200 px-6 py-4 dark:border-gray-600 dark:bg-gray-700">
                <div className="mr-3 text-sm font-medium">
                  Next orchestration workflow starting
                </div>
                <LoadingSpinner className="h-6 w-6 dark:text-white" />
              </div>
              <div className="absolute inset-0 bg-gray-200 opacity-75 dark:bg-gray-700" />
            </div>
          )}
        </div>
      </div>
      {props.cronExpression && (
        <EditOrchestrationSchedule
          edting={updatingSchedule}
          onFinish={() => setUpdatingSchedule(false)}
          initialCron={props.cronExpression}
          workflowId={props.workflowId}
        />
      )}
    </>
  );
};

const OrchestrationTable = (props: {
  isCurrentWorkflow: boolean;
  workflowRunId?: string;
  tableRows: Row[];
  hasRunFirstTime: boolean;
}) => {
  const navigateWithSlug = useNavigateWithSlug();
  const navigate = useNavigate();

  const columns = React.useMemo<Column<Row>[]>(
    () => [
      {
        Header: "Job",
        Cell: (props: CellProps<Row>) => {
          if (props.row.original.type === "model")
            return <ModelItemColumn row={props.row.original} />;
          else if (props.row.original.type === "elt-sync")
            return <EltSyncItemColumn row={props.row.original} />;
          else if (props.row.original.type === "elt-source-stream")
            return <EltSourceStreamItemColumn row={props.row.original} />;
          else if (props.row.original.type === "reverse-elt-sync")
            return <ReverseEltSyncItemColumn row={props.row.original} />;
          return null;
        },
        width: "70%",
      },
      {
        Header: "Job status",
        width: 1,
        Cell: ({ row: { original } }: CellProps<Row>) => {
          return (
            <div className={"flex items-center space-x-2"}>
              {original.status ? (
                <>
                  <WorkflowJobStatusBadge jobStatus={original.status}>
                    <WorkflowJobStatusDisplay jobStatus={original.status} />
                  </WorkflowJobStatusBadge>
                </>
              ) : (
                <span className="opacity-50">-</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Tests status",
        width: 1,
        Cell: ({ row: { original } }: CellProps<Row>) => {
          const job = "job" in original ? original.job : undefined;
          const getTestStatus = () => {
            if (!job) {
              return "-";
            }
            const { runs, status } = getJobTestRunsWithStatus(job);
            if (runs.length === 0) {
              return "No tests";
            }
            const plural = runs.length > 1 ? "s" : "";
            if (status.numRunning > 0) {
              return (
                <div className="flex-1 font-medium opacity-30">
                  {status.numRunning} out of {runs.length} test{plural} running
                </div>
              );
            }
            if (status.numFailed > 0) {
              return (
                <div className="flex-1 font-medium text-red-500">
                  {status.numFailed} out of {runs.length} test{plural} failed
                </div>
              );
            }

            if (status.numSuccessful === runs.length) {
              return (
                <div className="flex-1 font-medium text-green-500">
                  All tests passed
                </div>
              );
            }
            return `${runs.length} test${plural}`;
          };

          return <div className="flex items-center">{getTestStatus()}</div>;
        },
      },
    ],
    [],
  );

  const { onOpen: viewSync } = useViewDataSourceSlideOver();

  const handleRowClick = (row: Row) => {
    if (isModelRow(row))
      navigate({
        to: props.isCurrentWorkflow
          ? `./model/${row.model.id}`
          : `../../model/${row.model.id}`,
        search: { workflowRunId: props.workflowRunId },
      });
    if (isEltSyncRow(row)) {
      viewSync({ syncId: row.sync.id });
    }
    if (isReverseEltSyncRow(row)) {
      navigateWithSlug({
        to: `/reverse-etl/${row.sync.id}`,
        search: { workflowRunId: props.workflowRunId },
      });
    }
  };
  return (
    <DefaultTable
      onRowClick={handleRowClick}
      columns={columns}
      data={props.tableRows}
      empty={
        <EmptyOrchestrationState hasRunFirstTime={props.hasRunFirstTime} />
      }
    />
  );
};

const EmptyOrchestrationState: React.FC<{
  hasRunFirstTime: boolean;
}> = (props) => {
  return (
    <div className="relative w-full space-y-4 text-center dark:text-white">
      <p className="text-lg">
        {props.hasRunFirstTime
          ? "No models found for orchestration."
          : "Orchestration has not run yet."}
      </p>
      <p className="flex flex-col text-sm">
        <span>Models added to this orchestration will appear here.</span>
      </p>
    </div>
  );
};

const ViewModeButton = (props: {
  viewMode: "table" | "graph";
  onChange: (selected: "table" | "graph") => void;
  disabled: boolean;
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        disabled={props.disabled}
        type="button"
        className={classNames(
          "flex h-10 items-center space-x-8 rounded-sm border border-gray-300 px-4 py-0.5 pl-2 pr-1 text-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300",
          props.disabled && "opacity-50",
        )}
      >
        {props.viewMode === "table" ? (
          <div className="flex flex-row items-center space-x-2">
            <TableIcon className="h-4 w-4 flex-none" />
            <span>Table view</span>
          </div>
        ) : (
          <div className="flex flex-row items-center space-x-2">
            <ShareIcon className="h-4 w-4 flex-none" />
            <span>Graph view</span>
          </div>
        )}

        <ChevronDownIcon className="w-4" />
      </Menu.Button>
      <Menu.Items className="absolute left-0 z-50 mt-2 w-56 origin-top-right overflow-hidden rounded-sm border bg-white shadow-lg focus:outline-none dark:border-gray-700 dark:bg-gray-800">
        <Menu.Item>
          {({ active }) => (
            <button
              className={classNames(
                active && "opacity-75",
                "flex w-full items-center space-x-4 px-4 py-4 dark:text-white",
                props.viewMode === "table" ? "bg-primary text-white" : "",
              )}
              onClick={() => props.onChange("table")}
            >
              <TableIcon className="h-4 w-4 flex-none" />

              <div className="">
                <p className="text-left text-sm font-semibold">Table view</p>
                <p className="w-full text-left  text-xs dark:text-gray-300">
                  View orchestration jobs in a table
                </p>
              </div>
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              className={classNames(
                active && "opacity-75",
                "flex w-full items-center space-x-4 px-4 py-4 dark:text-white",
                props.viewMode === "graph" ? "bg-primary text-white" : "",
              )}
              onClick={() => props.onChange("graph")}
            >
              <ShareIcon className="h-4 w-4 flex-none" />
              <div className="">
                <p className="text-left text-sm font-semibold">Graph view</p>
                <p className="w-full text-left  text-xs dark:text-gray-300">
                  Show orchestration jobs in a directed asyclic graph.
                </p>
              </div>
            </button>
          )}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export const useOrchestratedSyncs = (workflowId: string) => {
  const { data, loading } = useEltSyncsQuery();
  return useMemo(() => {
    if (loading || !data) {
      return [];
    }
    return data.eltSyncs.filter(
      (sync) => sync.orchestrationWorkflow?.id === workflowId,
    );
  }, [data, loading, workflowId]);
};
