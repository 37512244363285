import { keyBy } from "lodash";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  ConnectionsQuery,
  Stream,
  useGetStreamsQuery,
  useSchemasQuery,
} from "@/apollo/types";
import { UtilityButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  Input,
  InputAddonLeft,
  InputGroup,
} from "@/components/primitives/input";
import {
  SourceStreamsAction,
  SourceStreamsState,
  sourceStreamsReducer,
} from "@/features/elt/reducers/sourceStreamsReducer";
import classNames from "@/helpers/classNames";
import cn from "@/helpers/classNames";
import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useIntegration } from "@/integrations";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import StreamItem from "./StreamItem";

export const SourceStreamsStateContext = createContext<
  [SourceStreamsState, React.Dispatch<SourceStreamsAction>] | null
>(null);

export function useSourceStreamsState() {
  const context = useContext(SourceStreamsStateContext);
  if (context === null) {
    throw new Error(
      "useSourceStreamsState must be used within a SourceStreamStateProvider",
    );
  }
  return context;
}

export default function TableConfig(props: {
  sourceId: string;
  sourceSettings: Record<string, any>;
  connection: ConnectionsQuery["connections"][0];
  mode: "create" | "edit";
  initialValues?: SourceStreamsState;
  onChange: (sourceStreams: SourceStreamsState) => void;
  onError?: (error: Error) => void;
  requiredStreams?: string[];
  size?: "small" | "large";
}) {
  const [sourceStreams, dispatch] = useReducer(
    sourceStreamsReducer,
    props.initialValues ?? {},
  );

  const onChangeRef = useLatestValueRef(props.onChange);
  useEffect(() => {
    onChangeRef.current(sourceStreams);
  }, [sourceStreams, onChangeRef]);

  const sourceIntegration = useIntegration(props.connection.integrationId);

  const [search, setSearch] = useState("");
  const {
    data,
    loading: getStreamsLoading,
    error: getStreamsError,
  } = useGetStreamsQuery({
    skip: !props.connection?.id,
    variables: {
      connectionId: props.connection?.id || "",
      sourceSettings: props.sourceSettings,
    },
    onError: (error) => props.onError?.(error),
    onCompleted: (data) => {
      if (data.getStreams.length === 1) {
        dispatch({
          type: "set_source_stream",
          payload: {
            checked: true,
            stream: data.getStreams[0],
          },
        });
      }
    },
  });

  const streams = useMemo(() => data?.getStreams || [], [data]);
  const substreamCount = useMemo(
    () => streams.reduce((acc, stream) => acc + stream.substreams.length, 0),
    [streams],
  );

  const allTablesCount = streams.length + substreamCount;

  const orderedStreams = useMemo(() => {
    return [...streams].sort((a, b) => {
      const aRequired = props.requiredStreams?.includes(a.name) ?? false;
      const bRequired = props.requiredStreams?.includes(b.name) ?? false;
      if (aRequired && !bRequired) return -1;
      if (bRequired && !aRequired) return 1;
      if (a.name < b.name) return -1;
      if (a.name === b.name) return 0;
      return 1;
    });
  }, [streams, props.requiredStreams]);

  const filteredStreams = useMemo<any[]>(
    () =>
      orderedStreams.filter(
        (stream) =>
          stream.name.toLowerCase().includes(search.toLowerCase()) ||
          stream.substreams.some((s) =>
            s.destinationTableName.toLowerCase().includes(search.toLowerCase()),
          ),
      ),
    [search, orderedStreams],
  );

  const filteredSubstreamCount = useMemo(
    () =>
      filteredStreams.reduce(
        (acc, stream) => acc + stream.substreams.length,
        0,
      ),
    [filteredStreams],
  );

  const allFilteredTablesCount =
    filteredStreams.length + filteredSubstreamCount;

  const schemasQuery = useSchemasQuery({
    variables: {
      sourceStreamNames: streams.map((s) => s.name),
      sourceConnectionId: props.sourceId,
      connectionSettings: props.sourceSettings,
    },
    skip: !streams.length,
    onError: (error) => props.onError?.(error),
  });

  const mappedSchemas = data
    ? keyBy(schemasQuery.data?.getAllAvroSchemas.schemas, "sourceStreamName")
    : null;

  if (getStreamsLoading)
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner className="h-5 w-5" />
      </div>
    );

  if (!streams.length && getStreamsError)
    return (
      <div className="flex items-center justify-center">
        <div className="text-sm dark:text-gray-300">
          Error loading tables from {props.connection?.label ?? "connection"}
        </div>
      </div>
    );

  if (!streams.length) {
    return (
      <p className="text-sm dark:text-gray-300">
        There are no tables found for this integration.
      </p>
    );
  }

  if (sourceIntegration == null) {
    return null;
  }

  return (
    <SourceStreamsStateContext.Provider value={[sourceStreams, dispatch]}>
      <div>
        <div
          className={classNames(
            props.mode === "create" &&
              "sticky top-0 z-10 bg-white pt-2 dark:bg-gray-800",
          )}
        >
          {streams.length > 10 && (
            <InputGroup>
              <InputAddonLeft>
                <MagnifyingGlassIcon
                  className={cn(props.size === "small" ? "h-4 w-4" : "h-5 w-5")}
                />
              </InputAddonLeft>
              <Input
                type="text"
                placeholder="Search..."
                className={cn(props.size === "small" && "h-8 text-xs")}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </InputGroup>
          )}
          <div className="mb-4 flex items-center justify-between">
            <div className="text-xs">
              {search
                ? `${allFilteredTablesCount} result${
                    allFilteredTablesCount !== 1 ? "s" : ""
                  } of ${allTablesCount} table${
                    allTablesCount !== 1 ? "s" : ""
                  }`
                : `${allTablesCount} table${streams.length !== 1 ? "s" : ""}`}
            </div>

            {sourceIntegration?.hideSelectAllTables ? null : (
              <div>
                {Object.keys(sourceStreams).length !== streams.length ? (
                  <UtilityButton
                    onClick={() => {
                      dispatch({
                        type: "set_source_streams",
                        payload: { streams },
                      });
                    }}
                  >
                    Select all tables
                  </UtilityButton>
                ) : (
                  <UtilityButton
                    onClick={() => {
                      dispatch({
                        type: "set_source_streams",
                        payload: { streams: [] },
                      });
                    }}
                  >
                    Deselect all tables
                  </UtilityButton>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            props.mode === "edit" && "max-h-96 overflow-auto",
          )}
        >
          {filteredStreams.map((stream: Stream) => (
            <StreamItem
              key={stream.name}
              stream={stream}
              search={search}
              sourceIntegration={sourceIntegration}
              schema={mappedSchemas?.[stream.name]}
              sourceId={props.sourceId}
              sourceSettings={props.sourceSettings}
              required={props.requiredStreams?.includes(stream.name)}
            />
          ))}
        </div>
      </div>
    </SourceStreamsStateContext.Provider>
  );
}
