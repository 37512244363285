import Tooltip from "@/components/elements/Tooltip";
import { useDataWarehouse } from "@/providers/account";
import { useSetHighlightConnectors } from "../SelectedMetric";
import { useCurrentTemplate } from "./TemplatesProvider";

export const NotConfiguredView = () => {
  const template = useCurrentTemplate();

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-gray-50 p-12 dark:bg-gray-800 ">
      <div className="flex flex-col items-center justify-center">
        <div className="pt-6 text-sm">
          {`Setting up your first ${template.name.toLowerCase()} data source will generate ${template.name.toLowerCase()} metrics here.`}
        </div>
        <div className="p-6">
          <LineageGraphic />
        </div>
      </div>
    </div>
  );
};

const LineageGraphic = () => {
  const dw = useDataWarehouse();
  const highlightConnectors = useSetHighlightConnectors();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="506"
      height="49"
      fill="none"
      viewBox="0 0 506 49"
      className="scale-110"
    >
      <path
        stroke="#E7E8EB"
        strokeWidth="1.594"
        d="M148 23.978c12.812.118 28.5.022 39 .022M319 24.92c12.812.118 28.5.022 39 .022"
      ></path>
      <ellipse
        cx="187.199"
        cy="24.189"
        fill="#000"
        rx="1.199"
        ry="1.189"
      ></ellipse>
      <ellipse
        cx="358.199"
        cy="25.131"
        fill="#000"
        rx="1.199"
        ry="1.189"
      ></ellipse>

      {/* Data source: */}
      <Tooltip content="Click below to set up a data source.">
        <g
          className="cursor-pointer"
          onClick={() => {
            highlightConnectors();
          }}
        >
          <rect
            width="128.406"
            height="31.406"
            x="17.797"
            y="8.797"
            fill="#FBFBFC" //F1F2F3
            stroke="#D4D6DA"
            strokeWidth="1.594"
            rx="2.391"
          ></rect>
          <path
            fill="#E7E8EB"
            d="M18.31 11.453a2 2 0 012-2h28v30h-28a2 2 0 01-2-2v-26z"
          ></path>
          <path
            stroke="#3E3F41"
            strokeLinejoin="round"
            strokeWidth="1.333"
            d="M38.333 21.667L33 19l-5.333 2.667m10.666 0L33 24.333m5.333-2.666v6.666L33 31m0-6.667l-5.333-2.666M33 24.333V31m-5.333-9.333v6.666L33 31"
          ></path>
          <text fill="#3E3F41" fontSize="10" fontWeight="500">
            <tspan x="61" y="28.136">
              Data sources
            </tspan>
          </text>
        </g>
      </Tooltip>

      {/* Raw data: */}
      <Tooltip
        content={`Data from your data sources will be stored in your ${dw.integrationId} data warehouse`}
      >
        <g className="cursor-default">
          <rect
            width="128.406"
            height="31.406"
            x="188.797"
            y="8.797"
            fill="#FBFBFC"
            stroke="#D4D6DA"
            strokeWidth="1.594"
            rx="2.391"
          ></rect>
          <path
            fill="#E7E8EB"
            d="M189.309 11.453a2 2 0 012-2h28v30h-28a2 2 0 01-2-2v-26z"
          ></path>
          <g clipPath="url(#clip0_584_456)">
            <path
              fill="#5D5D5D"
              d="M199.177 31.986l-3.827-6.57a1.123 1.123 0 010-1.134l3.827-6.569c.204-.35.581-.567.989-.567h7.657c.407.002.782.218.985.567l3.826 6.57c.205.35.205.783 0 1.133l-3.826 6.57c-.204.35-.582.566-.99.567h-7.655a1.147 1.147 0 01-.987-.567h.001z"
            ></path>
            <path
              fill="#000"
              d="M206.382 22.735s1.064 2.529-.386 3.962c-1.45 1.434-4.091.516-4.091.516l5.372 5.338h.545c.408 0 .785-.216.989-.567l2.534-4.347-4.963-4.902z"
              opacity="0.1"
            ></path>
            <path
              fill="#fff"
              d="M207.639 27.909l-1.173-1.165a.195.195 0 00-.044-.033 3.151 3.151 0 00-.412-4.32 3.214 3.214 0 00-4.376.14 3.156 3.156 0 00-.932 2.143c-.024.806.263 1.59.802 2.194a3.218 3.218 0 004.36.398.178.178 0 00.031.04l1.176 1.166a.183.183 0 00.13.053c.048 0 .095-.019.13-.053l.308-.305a.184.184 0 00.054-.13.184.184 0 00-.054-.128zm-3.746-.754c-.476 0-.941-.14-1.336-.401a2.385 2.385 0 01-.886-1.07 2.363 2.363 0 01.521-2.598 2.414 2.414 0 012.621-.517c.44.18.815.486 1.08.879a2.37 2.37 0 01-.3 3.01 2.415 2.415 0 01-1.7.697zm-1.537-2.51v.985c.153.268.374.491.642.647v-1.638l-.642.006zm1.202-.817v2.662c.213.039.431.039.643 0v-2.662h-.643zm1.86 1.8v-.584h-.643v1.226c.268-.155.49-.378.643-.645v.003z"
            ></path>
          </g>
          <text fill="#3E3F41" fontSize="10" fontWeight="500">
            <tspan x="232" y="28.636">
              Raw data tables
            </tspan>
          </text>
        </g>
      </Tooltip>

      {/* Core metrics: */}
      <Tooltip
        content={`Core Metrics models will be automatically generated from your raw data tables`}
      >
        <g className="cursor-default">
          <rect
            width="128.406"
            height="31.406"
            x="359.797"
            y="8.797"
            fill="#FBFBFC"
            stroke="#D4D6DA"
            strokeWidth="1.594"
            rx="2.391"
          ></rect>
          <path
            fill="#6D717A"
            d="M360.309 11.453a2 2 0 012-2h28v30h-28a2 2 0 01-2-2v-26z"
          ></path>
          <path
            stroke="#F1F2F3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.797"
            d="M370.21 24.876h9.234m-9.234 0v4.302c0 .352.141.69.392.94.251.248.592.388.948.388h1.602m-2.942-5.63v-2.83m0 5.775h9.234m-9.234-5.775V19.88c0-.352.141-.69.392-.939s.592-.389.948-.389h3.742c.178 0 .348.07.474.195l3.327 3.298m-8.883 0h2.942m0 0v8.46m0-8.46h3.21m-3.21 8.46h3.21m0 0h1.888c.355 0 .696-.14.947-.389s.393-.587.393-.939v-6.366a.66.66 0 00-.197-.47l-.3-.296m-2.731 8.46v-8.46m0 0h2.731"
          ></path>
          <text fill="#3E3F41" fontSize="10" fontWeight="500">
            <tspan x="403" y="28.636">
              Core metrics
            </tspan>
          </text>
        </g>
      </Tooltip>

      <defs>
        <clipPath id="clip0_584_456">
          <path
            fill="#fff"
            d="M0 0H17.591V17.435H0z"
            transform="translate(195.196 16.132)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
