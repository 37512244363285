import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  DangerGraphicIcon,
  InfoGraphicIcon,
  SuccessGraphicIcon,
  WarningGraphicIcon,
} from "@/components/icons/ContextualGraphicIcon";
import { ToastType } from "@/providers/ToastProvider";

export default function ToastNotification({
  toast,
  onClose,
}: {
  toast: ToastType;
  onClose: Function;
}) {
  return (
    <div className="p-4">
      <div className="flex items-start">
        <div className="shrink-0">
          <ToastIcon toastType={toast.type} />
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="break-words text-sm font-medium text-gray-900 dark:text-white">
            {toast.title}
          </p>
          {toast.description && (
            <p className="mt-1 break-words text-sm  text-gray-500 dark:text-gray-200">
              {toast.description}
            </p>
          )}
        </div>
        <div className="ml-4 flex shrink-0">
          <button
            type="button"
            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-gray-700"
            onClick={() => onClose()}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

const ToastIcon = (props: { toastType: ToastType["type"] }) => {
  switch (props.toastType) {
    case "success":
      return <SuccessGraphicIcon className="h-8 w-8" aria-hidden="true" />;
    case "error":
      return <DangerGraphicIcon className="h-8 w-8" aria-hidden="true" />;
    case "warning":
      return <WarningGraphicIcon className="h-8 w-8" aria-hidden="true" />;
    case "info":
      return <InfoGraphicIcon className="h-8 w-8" aria-hidden="true" />;
    default:
      return null;
  }
};
