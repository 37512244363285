import { Portal, Transition } from "@headlessui/react";
import { PlanName, Product, WeldPlan } from "@/apollo/types";
import { Popover } from "@/components/elements/Popover";
import { ViewAddOnsButton } from "@/components/modules/UpgradeNowButton";
import { useSubscriptionStatus, useWeldPlan } from "@/features/subscription";
import React, { Fragment, useState } from "react";
import { usePopper } from "react-popper";

import { WeldPlanBadge } from "./WeldPlanBadge";

type TargetPlan = { plan: WeldPlan; addOn: Product | null };

function UnavailableCronOptionBody(props: {
  currentPlanName: PlanName;
  targetPlan: TargetPlan;
}) {
  const { plan, addOn } = props.targetPlan;
  return (
    <>
      {addOn === null ? (
        <div>
          Available on the <WeldPlanBadge name={plan.name} /> plan.
        </div>
      ) : (
        <div>
          Available as an <strong>add-on</strong> to the{" "}
          <WeldPlanBadge name={plan.name} /> plan.
        </div>
      )}
    </>
  );
}

export function UnavailableCronOptionTooltipContent(props: {
  currentPlanName: PlanName;
  targetPlan?: TargetPlan | null;
}) {
  if (props.targetPlan == null) {
    return <>Unavailable on {props.currentPlanName} plan</>;
  }
  return (
    <div className="flex flex-col gap-4">
      <UnavailableCronOptionBody {...props} targetPlan={props.targetPlan} />
    </div>
  );
}

export function UnavailableCronOptionDialogContent(props: {
  currentPlanName: PlanName;
  targetPlan?: TargetPlan | null;
}) {
  const { trial } = useSubscriptionStatus();
  const currentPlan = useWeldPlan();

  if (props.targetPlan == null) {
    return <>Unavailable on {props.currentPlanName} plan</>;
  }

  return (
    <>
      <div className="flex flex-col gap-4 px-5 py-3">
        <UnavailableCronOptionBody {...props} targetPlan={props.targetPlan} />
      </div>
      {!trial?.isActive &&
        currentPlan.name === PlanName.Essential &&
        props.targetPlan.plan.name === PlanName.Essential &&
        props.targetPlan.addOn && (
          <>
            <hr className="dark:border-gray-700" />
            <div className="flex justify-center px-4 py-2">
              {props.currentPlanName === PlanName.Essential && (
                <ViewAddOnsButton
                  colorScheme="primary"
                  variant="solid"
                  source="unavailable sync interval option popover dialog"
                  size="sm"
                >
                  View add-ons
                </ViewAddOnsButton>
              )}
            </div>
          </>
        )}
    </>
  );
}

export function UnavailableCronOptionPopover(props: {
  currentPlanName: PlanName;
  targetPlan?: TargetPlan | null;
  children: React.ReactNode;
}) {
  const popperElRef = React.useRef(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: "absolute",
    placement: "right",
    modifiers: [
      { name: "preventOverflow", enabled: true },
      { name: "offset", options: { offset: [0, 4] } },
    ],
  });

  const Trigger = React.Children.only(props.children);
  return (
    <Popover trigger="hover">
      <Popover.Button as="div">
        {React.isValidElement<any>(Trigger)
          ? React.cloneElement(Trigger, {
              ref: setReferenceElement,
              role: "button",
            })
          : null}
      </Popover.Button>
      <Portal>
        <div
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}
          className="absolute z-50"
        >
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Popover.Panel className="w-80 rounded border bg-white shadow-xl dark:border-gray-700 dark:bg-gray-600">
              <UnavailableCronOptionDialogContent
                currentPlanName={props.currentPlanName}
                targetPlan={props.targetPlan}
              />
            </Popover.Panel>
          </Transition>
        </div>
      </Portal>
    </Popover>
  );
}
